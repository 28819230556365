import React from "react";
import "./Payment.css";
import paymentImg from "../../assets/payment-solution.png";
import { useLanguage } from "../../LanguageContext";
import { useAppConfig } from "../../hooks/useAppConfig";

function Payment() {
    const { language, servicesPaymentText, images } = useLanguage();

        const config = useAppConfig();

    const dynamicStyles = {
        fontFamily: language === "ARM" && "Mardoto",
        fontWeight: language === "ARM" && 500,
    };

    return (
        <div className="payment">
            <div className="payment-container">
                <div className="payment-main">
                    <div className="payment-header">
                        <span style={dynamicStyles}>{servicesPaymentText[language][0].paymentTitle}</span>
                        <div className="payment-header_icons">
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M0.0164407 3.82196L0 12L3.99046 12L3.99046 3.99715C4.00629 3.99715 4.9794 4.00325 4.9794 4.0002L12 4.0002L12 -5.24537e-07L3.83761 -1.67747e-07C1.73064 -7.56487e-08 0.0219223 1.70984 0.0176598 3.82196"
                                    fill="#243BE2"
                                />
                            </svg>
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M8.17804 11.9836L0 12L0 8.00954L8.00285 8.00954C8.00285 7.99371 7.99675 7.0206 7.9998 7.0206L7.9998 0H12L12 8.16239C12 10.2694 10.2902 11.9781 8.17804 11.9823"
                                    fill="#ADF233"
                                />
                            </svg>
                        </div>
                    </div>
                    <div className="payment-title">
                        <h1 style={dynamicStyles}>{servicesPaymentText[language][0].paymentBigTitle}</h1>
                    </div>
                </div>
                <div className="payment-description">
                        <p style={dynamicStyles}>
                            <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="11.5" cy="11.5" r="11.5" fill="black" />
                            </svg>
                            <div style={{display: 'inline'}}  dangerouslySetInnerHTML={{ __html: servicesPaymentText[language][0].paymentText }} />
                        </p>
                    </div>
            </div>
            <div className="payment-image">
                <img src={config.data.API_STATIC_URL + images.image8} alt="Payment and Solution" />
            </div>
        </div>
    );
}

export default Payment;
