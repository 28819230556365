import React, { useEffect, useState } from "react";
import "./styles/Legal.css";
import Footer from "../components/Footer";
import Wrapper from "../components/Wrapper";
import { useLanguage } from "../LanguageContext";
import { axiosInstance } from "../helper/axiosInstance";
import { useAppConfig } from "../hooks/useAppConfig";

function Legal() {
    const { language, legalText, legalDocs } = useLanguage();
    const [documents, setDocuments] = useState([]);

    const config = useAppConfig();

    const dynamicStyles = {
        fontFamily: language === "ARM" && "Mardoto",
        fontWeight: language === "ARM" && 500,
    };

     useEffect(() => {
        const lang = language === "ARM" ? "hy" : language === "ENG" ? "en" : "ru";

        axiosInstance.get("/documents/lang?lang=" + lang).then((response) =>
            setDocuments(response.data)
        );
    }, [language]);
    

    return (
        <Wrapper>
            <div className="legal">
                <div className="legal-header">
                    <span style={dynamicStyles}>{legalText[language].header}</span>
                    <div className="legal-header_icons">
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M0.0164407 3.82196L0 12L3.99046 12L3.99046 3.99715C4.00629 3.99715 4.9794 4.00325 4.9794 4.0002L12 4.0002L12 -5.24537e-07L3.83761 -1.67747e-07C1.73064 -7.56487e-08 0.0219223 1.70984 0.0176598 3.82196"
                                fill="#243BE2"
                            />
                        </svg>
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M8.17804 11.9836L0 12L0 8.00954L8.00285 8.00954C8.00285 7.99371 7.99675 7.0206 7.9998 7.0206L7.9998 0H12L12 8.16239C12 10.2694 10.2902 11.9781 8.17804 11.9823"
                                fill="#ADF233"
                            />
                        </svg>
                    </div>
                </div>
                <div className="legal-title">
                    <h1 style={dynamicStyles}>{legalText[language].title}</h1>
                </div>
                <div className="legal-description">
                    <p style={dynamicStyles}>{legalText[language].description}</p>
                </div>
                <div className="legal-table">
                    {documents.map((doc, index) => (
                        <div key={index} className="legal-row">
                            <span className="legal-doc-name" style={dynamicStyles}>
                                {doc.name}
                            </span>
                            <div className="legal-actions">
                                {/* <a href={doc.openLink} className="legal-action-link" style={dynamicStyles}>
                                    Open document
                                </a> */}
                                <a
                                    href={config.data.API_STATIC_URL + doc.file}
                                    download={doc.name + "." + doc.file.split(".").pop()}
                                    className="legal-action-link"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    style={dynamicStyles}
                                >
                                    {legalText[language].download}
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_1696_1616)">
                                            <path
                                                d="M8 12L12 16M12 16L16 12M12 16L12 8"
                                                stroke="black"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </g>
                                        <rect x="23.5" y="0.5" width="23" height="23" rx="11.5" transform="rotate(90 23.5 0.5)" stroke="black" />
                                        <defs>
                                            <clipPath id="clip0_1696_1616">
                                                <rect x="24" width="24" height="24" rx="12" transform="rotate(90 24 0)" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </a>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <Footer />
        </Wrapper>
    );
}

export default Legal;
