import React, {useEffect, useState} from "react";
import "./styles/Reports.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import Footer from "../components/Footer";
import Wrapper from "../components/Wrapper";
import { useLanguage } from "../LanguageContext";
import { axiosInstance } from "../helper/axiosInstance";
import { useAppConfig } from "../hooks/useAppConfig";

//image examples
// import reportsImage from "../assets/reports_3.png";

function Reports() {
    const { language, reportsText, financialReports } = useLanguage();
    const [cards, setCards] = useState([]);
    
    const config = useAppConfig();

    const dynamicStyles = {
        fontFamily: language === "ARM" && "Mardoto",
        fontWeight: language === "ARM" && 500,
    };

    // const cards = [
    //     {
    //         date: "2024",
    //         title: "Arca - Authorized capital ",
    //         image: reportsImage,
    //         content: "Data on the current authorized capital of the company, including changes that have occurred in recent years.",
    //     },
    //     {
    //         date: "2024",
    //         title: "Arca - Authorized capital ",
    //         image: reportsImage,
    //         content: "Data on the current authorized capital of the company, including changes that have occurred in recent years.",
    //     },
    //     {
    //         date: "2024",
    //         title: "Arca - Authorized capital ",
    //         image: reportsImage,
    //         content: "Data on the current authorized capital of the company, including changes that have occurred in recent years.",
    //     },
    //     {
    //         date: "2024",
    //         title: "Arca - Authorized capital ",
    //         image: reportsImage,
    //         content: "Data on the current authorized capital of the company, including changes that have occurred in recent years.",
    //     },
    //     {
    //         date: "2024",
    //         title: "Arca - Authorized capital ",
    //         image: reportsImage,
    //         content: "Data on the current authorized capital of the company, including changes that have occurred in recent years.",
    //     },
    //     {
    //         date: "2024",
    //         title: "Arca - Authorized capital ",
    //         image: reportsImage,
    //         content: "Data on the current authorized capital of the company, including changes that have occurred in recent years.",
    //     },
    //     {
    //         date: "2024",
    //         title: "Arca - Authorized capital ",
    //         image: reportsImage,
    //         content: "Data on the current authorized capital of the company, including changes that have occurred in recent years.",
    //     },
    //     {
    //         date: "2024",
    //         title: "Arca - Authorized capital ",
    //         image: reportsImage,
    //         content: "Data on the current authorized capital of the company, including changes that have occurred in recent years.",
    //     },
    // ];

    // const cards = financialReports[language];

    useEffect(() => {
        const lang = language === "ARM" ? "hy" : language === "ENG" ? "en" : "ru";
    
        axiosInstance.get("/reports/lang?lang=" + lang).then((response) =>
            setCards(response.data)
        );
    }, [language]);

    return (
        <Wrapper>
            <div className="reports">
                <div className="reports-header">
                    <span style={dynamicStyles}>{reportsText[language].header}</span>
                    <div className="reports-header_icons">
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M0.0164407 3.82196L0 12L3.99046 12L3.99046 3.99715C4.00629 3.99715 4.9794 4.00325 4.9794 4.0002L12 4.0002L12 -5.24537e-07L3.83761 -1.67747e-07C1.73064 -7.56487e-08 0.0219223 1.70984 0.0176598 3.82196"
                                fill="#243BE2"
                            />
                        </svg>
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M8.17804 11.9836L0 12L0 8.00954L8.00285 8.00954C8.00285 7.99371 7.99675 7.0206 7.9998 7.0206L7.9998 0H12L12 8.16239C12 10.2694 10.2902 11.9781 8.17804 11.9823"
                                fill="#ADF233"
                            />
                        </svg>
                    </div>
                </div>
                <div className="reports-title">
                    <h1 style={dynamicStyles}>{reportsText[language].title}</h1>
                </div>

                {/* Swiper Slider */}
                <div className="reports-slider">
                    <div className="reports-navigation_container">
                        <button className="reports-arrow reports-left-arrow">
                            <div className="svg-wrapper">
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9 17L1 9M1 9L9 1M1 9L17 9" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </div>
                            <div className="svg-wrapper">
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9 17L1 9M1 9L9 1M1 9L17 9" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </div>
                        </button>
                        <button className="reports-arrow reports-right-arrow">
                            <div className="svg-wrapper">
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9 17L17 9M17 9L9 1M17 9L0.999999 9" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </div>
                            <div className="svg-wrapper">
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9 17L17 9M17 9L9 1M17 9L0.999999 9" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </div>
                        </button>
                    </div>
                    <Swiper
                        modules={[Navigation]}
                        spaceBetween={0}
                        slidesPerView={4.5}
                        navigation={{
                            prevEl: ".reports-left-arrow",
                            nextEl: ".reports-right-arrow",
                        }}
                        breakpoints={{
                            1560: {
                                slidesPerView: 4.5,
                                spaceBetween: 20,
                            },
                            1200: {
                                slidesPerView: 4.5,
                                spaceBetween: 15,
                            },
                            577: {
                                slidesPerView: 4.5,
                                spaceBetween: 10,
                            },
                            0: {
                                slidesPerView: 1.1,
                                spaceBetween: 20,
                            },
                        }}
                        className="reportsSlider"
                    >
                        {cards.map((card, index) => (
                            <SwiperSlide key={index}>
                                <div className="reports-card">
                                    <span className="reports-card_date">{card.date_year}</span>
                                    <p className="reports-card_title" style={dynamicStyles}>{card.title}</p>
                                    <div className="reports-card_image">
                                        <img src={card.image && config.data.API_STATIC_URL + card.image} alt={card.title} />
                                    </div>
                                    <p className="reports-card_content" style={dynamicStyles}>{card.content}</p>
                                    <a
                                        style={dynamicStyles}
                                        className="reports-card_pdf"
                                        href={config.data.API_STATIC_URL + card.file}
                                        download={card.title + "." + card.file.split(".").pop()} 
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {reportsText[language].download}
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_1696_1616)">
                                                <path
                                                    d="M8 12L12 16M12 16L16 12M12 16L12 8"
                                                    stroke="black"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                            </g>
                                            <rect x="23.5" y="0.5" width="23" height="23" rx="11.5" transform="rotate(90 23.5 0.5)" stroke="black" />
                                            <defs>
                                                <clipPath id="clip0_1696_1616">
                                                    <rect x="24" width="24" height="24" rx="12" transform="rotate(90 24 0)" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </a>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
            <Footer />
        </Wrapper>
    );
}

export default Reports;
