import React from "react";
import { useLanguage } from "../LanguageContext";
import MainBlock from "../components/MainBlock";
import Footer from "../components/Footer";
import Wrapper from "../components/Wrapper";
import Brief from "../components/about/Brief";
import Share from "../components/about/Share";
import Mission from "../components/about/Mission";
import Team from "../components/about/Team";
import bgGif from "../assets/main-background_mobile.gif";
import Timeline from "../components/about/Timeline";
import LinkCard from "../components/LinkCard";
//link card images
import linkBg1 from "../assets/link-card_bg1.png";
import linkBg2 from "../assets/link-card_bg2.png";
import linkBg3 from "../assets/link-card_bg3.png";

// const FormattedMessage = (key) => {
//     const { language, messages } = useLanguage();
//     return messages[language][key];
// }

const About = () => {
    const { language, heroText, linkCardsContent } = useLanguage();

    return (
        <>
            <MainBlock title={heroText[language].about} gifSrc={bgGif} />
            {/*<MainBlock title={<FormattedMessage key="abourt"/>} gifSrc={bgGif} />*/}
            <Wrapper>
                <Brief />
                <Timeline />
                <Mission />
                <Share />
                <Team />
                <div className="linkcards-container">
                    <LinkCard
                        linkPath="/services"
                        title={linkCardsContent[language].services[0].name}
                        titleText={linkCardsContent[language].services[1].title}
                        description={linkCardsContent[language].services[2].desc}
                        bgImg={linkBg1}
                    />
                    <LinkCard
                        linkPath="/careers"
                        title={linkCardsContent[language].career[0].name}
                        titleText={linkCardsContent[language].career[1].title}
                        description={linkCardsContent[language].career[2].desc}
                        bgImg={linkBg2}
                    />
                    <LinkCard
                        linkPath="/news"
                        title={linkCardsContent[language].news[0].name}
                        titleText={linkCardsContent[language].news[1].title}
                        description={linkCardsContent[language].news[2].desc}
                        bgImg={linkBg3}
                    />
                </div>
                <Footer />
            </Wrapper>
        </>
    );
};

export default About;
