import React, { useState, useEffect } from "react";
import { useLanguage } from "../LanguageContext";
import MainBlock from "../components/MainBlock";
import ServicesBlock from "../components/home/ServicesBlock";
import Footer from "../components/Footer";
import News from "../components/home/News";
import Wrapper from "../components/Wrapper";
import Circle from "../components/home/Circle";
import bgGif from "../assets/main-background_mobile.gif";
import Numbers from "../components/home/Numbers";
import { Helmet } from "react-helmet-async";

const Home = () => {
    const { language, textContent, homeNewsText } = useLanguage();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 576);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 576);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const paymentTitle = isMobile ? (
        <>
            {textContent[language].paymentInnovationsMobile.split(" ").map((word, index) => (
                <React.Fragment key={index}>
                    {word}
                    <br />
                </React.Fragment>
            ))}
        </>
    ) : (
        textContent[language].paymentInnovations
    );

    return (
        <>
            <Helmet>
                <title>Arca</title>
                <meta name="keywords" content="home, services, company, arca, banking, armenian card, card, armenian" />
            </Helmet>
            <MainBlock title={paymentTitle} gifSrc={bgGif} />
            <Wrapper>
                <Numbers />
                <Circle />
                <ServicesBlock />
                <News title={homeNewsText[language].header} subtitle={homeNewsText[language].subheader} />
                <Footer />
            </Wrapper>
        </>
    );
};

export default Home;
