import React, { useState, useEffect } from "react";
import "./MainBlock.css";
import { useLanguage } from "../LanguageContext";
import finalMov from "../assets/main-background.mp4";
import { useAppConfig } from "../hooks/useAppConfig";

const MainBlock = ({ title, gifSrc }) => {
    const { language, images } = useLanguage();
    const [isMobile, setIsMobile] = useState(false);
    const isArmenian = language === "ARM";

    const config = useAppConfig();

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 576);
        };

        handleResize();

        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const dynamicStyles = {
        fontFamily: language === "ARM" && "Mardoto",
        fontWeight: language === "ARM" && 500,
    };

    return (
        <div className={`main-block ${isArmenian && "arm-language"}`}>
            <div className="main-block-title" style={isMobile ? { backgroundImage: `url(${config.data.API_STATIC_URL + images.image2})` } : {}}>
                {isMobile ? <></> : <video className="background-video" src={config.data.API_STATIC_URL + images.image1} autoPlay loop muted playsInline />}
                <h1 style={dynamicStyles}>{title}</h1>
            </div>
            {/* <div className="main-block-scroll">
                <div className="scroll-controls">
                    <span>
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M0.167969 8.63672V5.90723H5.27148V0.765625H8.10254V5.90723H13.168V8.63672H8.10254V13.7656H5.27148V8.63672H0.167969Z"
                                fill="black"
                            />
                        </svg>
                    </span>
                    <span>
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M0.167969 8.63672V5.90723H5.27148V0.765625H8.10254V5.90723H13.168V8.63672H8.10254V13.7656H5.27148V8.63672H0.167969Z"
                                fill="black"
                            />
                        </svg>
                    </span>
                </div>
                <p>Scroll</p>
                <div className="scroll-controls">
                    <span>
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M0.167969 8.63672V5.90723H5.27148V0.765625H8.10254V5.90723H13.168V8.63672H8.10254V13.7656H5.27148V8.63672H0.167969Z"
                                fill="black"
                            />
                        </svg>
                    </span>
                    <span>
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M0.167969 8.63672V5.90723H5.27148V0.765625H8.10254V5.90723H13.168V8.63672H8.10254V13.7656H5.27148V8.63672H0.167969Z"
                                fill="black"
                            />
                        </svg>
                    </span>
                </div>
            </div> */}
        </div>
    );
};

export default MainBlock;
