import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "./styles/NewsDetailPage.css";
import Wrapper from "../components/Wrapper";
import News from "../components/home/News";
import Footer from "../components/Footer";
import { axiosInstance } from "../helper/axiosInstance";
import { useLanguage } from "../LanguageContext";
import moment from "moment";
import "moment/locale/ru";
import "moment/locale/hy-am";
import { useAppConfig } from "../hooks/useAppConfig";

// console.log(Config.API_URL);
// console.log(Config.LANGUAGE);

function MaterialsDetailPage() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [newsItem, setMaterialsItem] = useState({});
    const { language, newsDetailsText } = useLanguage();

    const config = useAppConfig();

    const dynamicStyles = {
        fontFamily: language === "ARM" && "Mardoto",
        fontWeight: language === "ARM" && 500,
    };

    if (!newsItem) {
        return (
            <div className="news-notFound">
                <span className="news-notFound-green">News</span>&nbsp;<span className="news-notFound-blue">not found</span>
            </div>
        );
    }

    useEffect(() => {
        const lang = language === "ARM" ? "hy" : language === "ENG" ? "en" : "ru";
        const momentLang = language === "ARM" ? "hy-am" : language === "ENG" ? "en" : "ru";
        moment.locale(momentLang);
        axiosInstance.get("/materials/lang?lang=" + lang + "&id=" + id).then((res) => setMaterialsItem(res.data));
    }, [language, id]);

    return (
        <Wrapper>
            <div className="newsDetailPage">
                <div className="backButton-container">
                    <button className="backButton" onClick={() => navigate(-1)}>
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7 13L1 7M1 7L7 1M1 7L13 7" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        {newsDetailsText[language].goBack}
                    </button>
                </div>
                <div className="newsDetail-content">
                    <span style={dynamicStyles}>{moment(newsItem.date).format("LL")}</span>
                    <h1 style={dynamicStyles}>{newsItem.title}</h1>
                    <img src={config.data.API_STATIC_URL + newsItem.image} alt={newsItem.title} className="newsDetailPage-image" />
                    <div className="newsDetailPage-description_container">
                        <div className="newsDetailPage-description" dangerouslySetInnerHTML={{ __html: newsItem.description }} />
                    </div>
                </div>
            </div>
            <News title={newsDetailsText[language].header} subtitle={newsDetailsText[language].subheader} />
            <Footer />
        </Wrapper>
    );
}

export default MaterialsDetailPage;
