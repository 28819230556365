import React from "react";
import { useLanguage } from "../LanguageContext";
import Footer from "../components/Footer";
import contactsImage from "../assets/contacts.png";
import "./styles/Contacts.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { useAppConfig } from "../hooks/useAppConfig";

const Contacts = () => {
    const { language, contactsText, images } = useLanguage();
    const config = useAppConfig();
    const dynamicStyles = {
        fontFamily: language === "ARM" && "Mardoto",
        fontWeight: language === "ARM" && 500,
    };

    return (
        <>
            <div className="contacts">
                <div className="contacts-image">
                    <div className="contacts-content_head contacts-content_head-mobile">
                        <div className="contacts-content_header contacts-content_header-mobile">
                            <span style={dynamicStyles}>{contactsText[language].title}</span>
                            <div className=" contacts-content_header-icons contacts-content_header-icons-mobile">
                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M0.0164407 3.82196L0 12L3.99046 12L3.99046 3.99715C4.00629 3.99715 4.9794 4.00325 4.9794 4.0002L12 4.0002L12 -5.24537e-07L3.83761 -1.67747e-07C1.73064 -7.56487e-08 0.0219223 1.70984 0.0176598 3.82196"
                                        fill="#243BE2"
                                    />
                                </svg>
                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M8.17804 11.9836L0 12L0 8.00954L8.00285 8.00954C8.00285 7.99371 7.99675 7.0206 7.9998 7.0206L7.9998 0H12L12 8.16239C12 10.2694 10.2902 11.9781 8.17804 11.9823"
                                        fill="#ADF233"
                                    />
                                </svg>
                            </div>
                        </div>

                        <div className="contacts-content_title contacts-content_title-mobile">
                            <h1 style={dynamicStyles}>{contactsText[language].question}</h1>
                        </div>
                    </div>

                    <div className="contacts_image-container">
                        <img src={config.data.API_STATIC_URL + images.image9} alt="Contacts" />
                    </div>

                    {/* Custom Navigation Buttons */}
                    {/* <div className="custom-navigation">
                        <div className="custom-control">
                            <button className="custom-prev">
                                <div className="svg-wrapper">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9 17L1 9M1 9L9 1M1 9L17 9" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                                <div className="svg-wrapper">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9 17L1 9M1 9L9 1M1 9L17 9" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                            </button>
                            <button className="custom-next">
                                <div className="svg-wrapper">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9 17L17 9M17 9L9 1M17 9L1 9" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                                <div className="svg-wrapper">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9 17L17 9M17 9L9 1M17 9L1 9" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                            </button>
                        </div>
                    </div> */}

                    <Swiper
                        navigation={{
                            prevEl: ".custom-prev",
                            nextEl: ".custom-next",
                        }}
                        loop={true}
                        modules={[Navigation]}
                        className="mySwiperContacts"
                    >
                        <SwiperSlide>
                            <div className="contacts-info contacts-info-mobile">
                                <div className="contacts-info_row contacts-info_row-mobile">
                                    <p style={dynamicStyles}>{contactsText[language].office1}</p>
                                    <a style={dynamicStyles} href="https://maps.app.goo.gl/MH7H6xAQmq12Dn7SA">{contactsText[language].address1}</a>
                                </div>
                                <div className="contacts-info_row contacts-info_row-mobile">
                                    <p style={dynamicStyles}>{contactsText[language].office2}</p>
                                    <a style={dynamicStyles} href="https://maps.app.goo.gl/CXLuxpgaULLNv4Cc6">{contactsText[language].address2}</a>
                                </div>
                                <div className="contacts-info_row contacts-info_row-mobile">
                                    <p style={dynamicStyles}>{contactsText[language].phone}</p>
                                    <a style={dynamicStyles} href="tel:+37410592211">{contactsText[language].number}</a>
                                </div>
                                {/* <div className="contacts-info_row contacts-info_row-mobile">
                                <p>Administrative part</p>
                                <a href="tel:+37410592200">+374-10-592200</a>
                            </div> */}
                                <div className="contacts-info_row contacts-info_row-mobile">
                                    <p style={dynamicStyles}>{contactsText[language].mailText}</p>
                                    <a style={dynamicStyles} href="mailto:info@arca.am">{contactsText[language].mail}</a>
                                </div>
                                <div className="contacts-info_row contacts-info_row-mobile">
                                    <p style={dynamicStyles}>{contactsText[language].workingText}</p>
                                    <a style={dynamicStyles}>{contactsText[language].workingHours}</a>
                                </div>
                            </div>
                        </SwiperSlide>
                        {/* <SwiperSlide>
                            <div className="contacts-info contacts-info-mobile">
                                <div className="contacts-info_row contacts-info_row-mobile">
                                    <p>Office (Shengavit)</p>
                                    <a href="https://maps.app.goo.gl/CXLuxpgaULLNv4Cc6">Garegin Nzhdeh 32/1, Yerevan 0026, Republic Armenia</a>
                                </div>
                                <div className="contacts-info_row contacts-info_row-mobile">
                                    <p>Remote service center</p>
                                    <a href="tel:+37410440380">+374-10-440380</a>
                                </div>
                                <div className="contacts-info_row contacts-info_row-mobile">
                                    <p>Email</p>
                                    <a href="mailto:info@arca.am">info@arca.am</a>
                                </div>
                                <div className="contacts-info_row contacts-info_row-mobile">
                                    <p>Working hours</p>
                                    <a>Mon - Fri 09:00-18:00</a>
                                </div>
                            </div>
                        </SwiperSlide> */}
                    </Swiper>

                    <div className="contacts-image_footer">
                        <div className="contacts-image_icons">
                            {/* <span>
                                {" "}
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M0.179688 8.63672V5.90723H5.2832V0.765625H8.11426V5.90723H13.1797V8.63672H8.11426V13.7656H5.2832V8.63672H0.179688Z"
                                        fill="black"
                                    />
                                </svg>
                            </span>
                            <span>
                                {" "}
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M0.179688 8.63672V5.90723H5.2832V0.765625H8.11426V5.90723H13.1797V8.63672H8.11426V13.7656H5.2832V8.63672H0.179688Z"
                                        fill="black"
                                    />
                                </svg>
                            </span> */}
                        </div>
                        <div className="contacts-image_links">
                            <a target="_blank" href="https://www.facebook.com/armeniancard">
                                Facebook
                            </a>
                            <a target="_blank" href="https://www.instagram.com/arca.armenia">
                                Instagram
                            </a>
                            <a target="_blank" href="https://www.linkedin.com/company/armenian-card-arca/">
                                LinkedIn
                            </a>
                        </div>
                        {/* <div className="contacts-image_icons contacts-image_icons-mobile">
                            <span>
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M0.179688 8.63672V5.90723H5.2832V0.765625H8.11426V5.90723H13.1797V8.63672H8.11426V13.7656H5.2832V8.63672H0.179688Z"
                                        fill="black"
                                    />
                                </svg>
                            </span>
                        </div> */}
                    </div>
                </div>
                <div className="contacts-main">
                    <div className="contacts-content">
                        <div className="contacts-content_head">
                            <div className="contacts-content_header">
                                <span style={dynamicStyles}>{contactsText[language].title}</span>
                                <div className="contacts-content_header-icons">
                                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M0.0164407 3.82196L0 12L3.99046 12L3.99046 3.99715C4.00629 3.99715 4.9794 4.00325 4.9794 4.0002L12 4.0002L12 -5.24537e-07L3.83761 -1.67747e-07C1.73064 -7.56487e-08 0.0219223 1.70984 0.0176598 3.82196"
                                            fill="#243BE2"
                                        />
                                    </svg>
                                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M8.17804 11.9836L0 12L0 8.00954L8.00285 8.00954C8.00285 7.99371 7.99675 7.0206 7.9998 7.0206L7.9998 0H12L12 8.16239C12 10.2694 10.2902 11.9781 8.17804 11.9823"
                                            fill="#ADF233"
                                        />
                                    </svg>
                                </div>
                            </div>

                            <div className="contacts-content_title">
                                <h1 style={dynamicStyles}>{contactsText[language].question}</h1>
                            </div>
                        </div>

                        <div className="contacts-info">
                            <div className="contacts-info_row">
                                <p style={dynamicStyles}>{contactsText[language].office1}</p>
                                <a style={dynamicStyles} href="https://maps.app.goo.gl/MH7H6xAQmq12Dn7SA">{contactsText[language].address1}</a>
                            </div>
                            <div className="contacts-info_row">
                                <p style={dynamicStyles}>{contactsText[language].office2}</p>
                                <a style={dynamicStyles} href="https://maps.app.goo.gl/CXLuxpgaULLNv4Cc6">{contactsText[language].address2}</a>
                            </div>
                            <div className="contacts-info_row">
                                <p style={dynamicStyles}>{contactsText[language].phone}</p>
                                <a style={dynamicStyles} href="tel:+37410592211">{contactsText[language].number}</a>
                            </div>
                            {/* <div className="contacts-info_row">
                                <p>Administrative part</p>
                                <a href="tel:+37410592200">+374-10-592200</a>
                            </div> */}
                            <div className="contacts-info_row">
                                <p style={dynamicStyles}>{contactsText[language].mailText}</p>
                                <a style={dynamicStyles} href="mailto:info@arca.am">{contactsText[language].mail}</a>
                            </div>
                            <div className="contacts-info_row">
                                <p style={dynamicStyles}>{contactsText[language].workingText}</p>
                                <a style={dynamicStyles}>{contactsText[language].workingHours}</a>
                            </div>
                        </div>
                    </div>
                    <div className="contatcs-container_footer">
                        <span>
                            {" "}
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M0.171875 8.63672V5.90723H5.27539V0.765625H8.10645V5.90723H13.1719V8.63672H8.10645V13.7656H5.27539V8.63672H0.171875Z"
                                    fill="white"
                                />
                            </svg>
                        </span>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Contacts;
