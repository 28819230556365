import React, { useEffect, useRef, useState } from "react";
import "./ServicesBlock.css";
import { useLanguage } from "../../LanguageContext";
import { Link } from "react-router-dom";
import { axiosInstance } from "../../helper/axiosInstance";

const ServicesBlock = () => {
    const [expanded, setExpanded] = useState(1);
    const contentRefs = useRef({});
    const { language, comprehensiveText } = useLanguage();
    const isArmenian = language === "ARM";

    const [services, setServices] = useState([]);

    useEffect(() => {
        const lang = language === "ARM" ? "hy" : language === "ENG" ? "en" : "ru";
                
        axiosInstance.get("/our-services/lang?lang=" + lang).then((response) => setServices(response.data));
    }, []);

    const toggleExpand = (id) => {
        setExpanded((prev) => (prev === id ? null : id));
    };

    const setContentRef = (id, ref) => {
        if (ref) {
            contentRefs.current[id] = ref;
        }
    };

    useEffect(() => {
        if (expanded !== null && contentRefs.current[expanded]) {
            const ref = contentRefs.current[expanded];
            ref.style.maxHeight = `${400}px`; // Set max height to 200px if larger
        }
    }, [expanded]);

    const dynamicStyles = {
        fontFamily: language === "ARM" && "Mardoto",
        fontWeight: language === "ARM" && 500,
    };

    return (
        <div className={`services-container  ${isArmenian ? "arm-language" : ""}`}>
            <div className="services-header">
                <span style={dynamicStyles}>{comprehensiveText[language][0].comprehensiveText}</span>
                <div className="services-header_icons">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M0.0164407 3.82196L0 12L3.99046 12L3.99046 3.99715C4.00629 3.99715 4.9794 4.00325 4.9794 4.0002L12 4.0002L12 -5.24537e-07L3.83761 -1.67747e-07C1.73064 -7.56487e-08 0.0219223 1.70984 0.0176598 3.82196"
                            fill="#243BE2"
                        />
                    </svg>
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M8.17804 11.9836L0 12L0 8.00954L8.00285 8.00954C8.00285 7.99371 7.99675 7.0206 7.9998 7.0206L7.9998 0H12L12 8.16239C12 10.2694 10.2902 11.9781 8.17804 11.9823"
                            fill="#ADF233"
                        />
                    </svg>
                </div>
            </div>
            <div className="services-title">
                <h1 style={dynamicStyles}>{comprehensiveText[language][0].comprehensiveTitle}</h1>
            </div>
            <div className="services-main">
                <div className="services-block">
                    <h2 style={dynamicStyles}>{comprehensiveText[language][1].serviceTitle}</h2>
                    <div className="services-list">
                        {services.map((service) => (
                            <div key={service.id} className="service-item">
                                <div className="service-header" onClick={() => toggleExpand(service.id)}>
                                    <h3 style={dynamicStyles}>{service.title}</h3>
                                    <span className={expanded === service.id ? "rotate-icon" : ""}>
                                        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect x="19" y="12" width="1" height="13" transform="rotate(90 19 12)" fill="#D9D9D9" />
                                            <rect className="rotatable-rect" x="12" y="6" width="1" height="13" fill="#D9D9D9" />
                                            <circle cx="12.5" cy="12.5" r="12" stroke="#595959" />
                                        </svg>
                                    </span>
                                </div>
                                <div
                                    ref={(ref) => setContentRef(service.id, ref)}
                                    className="service-description-wrapper"
                                    style={{
                                        maxHeight: expanded === service.id ? "200px" : "0",
                                        opacity: expanded === service.id ? 1 : 0,
                                        overflow: "hidden", // Prevent overflow beyond 200px
                                    }}
                                >
                                    <div className="service-description">
                                        <p style={dynamicStyles}>{service.description}</p>
                                        <div className="service-desc_links">
                                            <Link to={`/services#service${service.id}`} style={dynamicStyles} className="read-more primary">
                                                {comprehensiveText[language][1].readMore}
                                                <svg width="14" height="7" viewBox="0 0 14 7">
                                                    <path
                                                        d="M10.0339 6.32872L12.0664 4.76088L3.39849e-06 4.76088L3.8147e-06 -1.22392e-06L0.989054 -1.13745e-06L0.989053 3.8087L12.148 3.80871L10.0339 2.1647L10.7337 1.49103L14 4.24671L10.7337 7L10.0339 6.32872Z"
                                                        fill="#ADF233"
                                                    />
                                                </svg>
                                            </Link>
                                            <Link to={`/services#service${service.id}`} style={dynamicStyles} className="read-more secondary">
                                                {comprehensiveText[language][1].readMore}
                                                <svg width="14" height="7" viewBox="0 0 14 7">
                                                    <path
                                                        d="M10.0339 6.32872L12.0664 4.76088L3.39849e-06 4.76088L3.8147e-06 -1.22392e-06L0.989054 -1.13745e-06L0.989053 3.8087L12.148 3.80871L10.0339 2.1647L10.7337 1.49103L14 4.24671L10.7337 7L10.0339 6.32872Z"
                                                        fill="#ADF233"
                                                    />
                                                </svg>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="graphic-element_container">
                    <div className="graphic-element">
                        <svg width="199" height="199" viewBox="0 0 199 199" fill="transparent" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M1.27002 63.61L1 197.58H66.53L66.53 66.48C66.79 66.48 82.77 66.5801 82.77 66.5301H198.06V1.00006H64.02C29.42 1.00006 1.36004 29.01 1.29004 63.61H1.27002Z"
                                stroke="#ADF233"
                                strokeMiterlimit="10"
                            />
                        </svg>
                        <svg
                            className={expanded === 1 ? "glow-effect active" : "glow-effect"}
                            width="199"
                            height="199"
                            viewBox="0 0 199 199"
                            fill="transparent"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M1.27002 63.61L1 197.58H66.53L66.53 66.48C66.79 66.48 82.77 66.5801 82.77 66.5301H198.06V1.00006H64.02C29.42 1.00006 1.36004 29.01 1.29004 63.61H1.27002Z"
                                stroke="#ADF233"
                                strokeMiterlimit="10"
                            />
                        </svg>

                        <svg
                            className={expanded === 2 ? "glow-effect active" : "glow-effect"}
                            width="199"
                            height="199"
                            viewBox="0 0 199 199"
                            fill="transparent"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M1.27002 63.61L1 197.58H66.53L66.53 66.48C66.79 66.48 82.77 66.5801 82.77 66.5301H198.06V1.00006H64.02C29.42 1.00006 1.36004 29.01 1.29004 63.61H1.27002Z"
                                stroke="#ADF233"
                                strokeMiterlimit="10"
                            />
                        </svg>
                        <svg
                            className={expanded === 3 ? "glow-effect active" : "glow-effect"}
                            width="199"
                            height="199"
                            viewBox="0 0 199 199"
                            fill="transparent"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M1.27002 63.61L1 197.58H66.53L66.53 66.48C66.79 66.48 82.77 66.5801 82.77 66.5301H198.06V1.00006H64.02C29.42 1.00006 1.36004 29.01 1.29004 63.61H1.27002Z"
                                stroke="#ADF233"
                                strokeMiterlimit="10"
                            />
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ServicesBlock;
