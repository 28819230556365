import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./News.css";
import { useLanguage } from "../../LanguageContext";
import moment from "moment";
import { axiosInstance } from "../../helper/axiosInstance";
import { useAppConfig } from "../../hooks/useAppConfig";

function NewsCard({ id, date, title, description, imageUrl }) {
    const { language, newsDetailsText } = useLanguage();

    const dynamicStyles = {
        fontFamily: language === "ARM" && "Mardoto",
        fontWeight: language === "ARM" && 500,
    };

    return (
        <Link to={`/news/${id}`} className="news-card">
            <img src={imageUrl} alt={title} className="news-card_image" />
            <div className="news-card_content">
                <p style={dynamicStyles} className="news-card_date">
                    {date}
                </p>
                <span style={dynamicStyles} className="news-card_title">
                    {title}
                </span>
                <p style={dynamicStyles} className="news-card_description" dangerouslySetInnerHTML={{ __html: description }}></p>
            </div>
        </Link>
    );
}

function News({ title, subtitle }) {
    const { language, newsDetailsText } = useLanguage();
    const [newsData, setNewsData] = useState([]);
    const config = useAppConfig();

    useEffect(() => {
        const lang = language === "ARM" ? "hy" : language === "ENG" ? "en" : "ru";
        const momentLang = language === "ARM" ? "hy-am" : language === "ENG" ? "en" : "ru";
        moment.locale(momentLang);

        axiosInstance.get("/news/lang?lang=" + lang + "&limit=4").then((response) =>
            setNewsData(
                response.data.map((item) => ({
                    ...item,
                    image: config.data.API_STATIC_URL + item.image,
                    pub_date: moment(item.pub_date).format("LL"),
                }))
            )
        );
    }, [language]);

    const isArmenian = language === "ARM";

    const dynamicStyles = {
        fontFamily: language === "ARM" && "Mardoto",
        fontWeight: language === "ARM" && 500,
    };

    return (
        <div className="news">
            <div className="news-header">
                <span style={dynamicStyles}>{title}</span>
                <div className="news-header_icons">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M0.0164407 3.82196L0 12L3.99046 12L3.99046 3.99715C4.00629 3.99715 4.9794 4.00325 4.9794 4.0002L12 4.0002L12 -5.24537e-07L3.83761 -1.67747e-07C1.73064 -7.56487e-08 0.0219223 1.70984 0.0176598 3.82196"
                            fill="#243BE2"
                        />
                    </svg>
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M8.17804 11.9836L0 12L0 8.00954L8.00285 8.00954C8.00285 7.99371 7.99675 7.0206 7.9998 7.0206L7.9998 0H12L12 8.16239C12 10.2694 10.2902 11.9781 8.17804 11.9823"
                            fill="#ADF233"
                        />
                    </svg>
                </div>
            </div>
            <div className="news-title">
                <h1 style={dynamicStyles}>{subtitle}</h1>
            </div>

            <div className="news-cards">
                {newsData.map((item, index) => (
                    <NewsCard key={index} id={item.id} date={item.pub_date} title={item.title} description={item.description} imageUrl={item.image} />
                ))}
            </div>
            <div className="news-footer">
                <Link to="/news" className="news-readmore">
                    <span style={dynamicStyles}> {newsDetailsText[language].readMore}</span>
                </Link>
            </div>
        </div>
    );
}

export default News;
