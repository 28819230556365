import React, { useState, useEffect, useRef } from "react";
import "./Timeline.css";
import { useLanguage } from "../../LanguageContext";
import { axiosInstance } from "../../helper/axiosInstance";

const Timeline = () => {
    const { language, timelineText } = useLanguage();
    const [sectionsData, setSectionsData] = useState([]);

    const [activeSection, setActiveSection] = useState(0);
    const [rotationAngle, setRotationAngle] = useState(0);
    const isArmenian = language === "ARM";

    const sections = timelineText[language].sections;
    // scrolls
    const [scrollCount, setScrollCount] = useState(0);
    const maxScrollCount = sectionsData.length - 1; // Max times to intercept scroll

    const isScrollingBlocked = useRef(false);
    const timelineRef = useRef(null);
    const observerRef = useRef(null);
    const isInView = useRef(false);

    // const handleSectionClick = (index) => {
    //     const sectionsCount = sections.length;
    //     const forwardSteps = (index - activeSection + sectionsCount) % sectionsCount;
    //     const backwardSteps = (activeSection - index + sectionsCount) % sectionsCount;

    //     const shortestSteps = forwardSteps <= backwardSteps ? forwardSteps : -backwardSteps;
    //     const angleChange = shortestSteps * 90;

    //     setActiveSection(index);
    //     setRotationAngle((prevAngle) => prevAngle + angleChange);
    // };

    // const handleNext = () => {
    //     const nextIndex = (activeSection + 1) % sections.length;
    //     handleSectionClick(nextIndex);
    // };

    // const handlePrevious = () => {
    //     const prevIndex = (activeSection - 1 + sections.length) % sections.length;
    //     handleSectionClick(prevIndex);
    // };

    useEffect(() => {
        const lang = language === "ARM" ? "hy" : language === "ENG" ? "en" : "ru";

        axiosInstance.get("/achievements/lang?lang=" + lang).then((response) => setSectionsData(response.data));
    }, [language]);

    const getOpacity = (index) => {
        const distance = (index - activeSection + sections.length) % sections.length;
        if (distance === 0) return 1; // Active section
        if (distance === 1) return 0.7; // Next
        if (distance === 2) return 0.3; // Second Next
        return 0; // Rest
    };

    const handleSectionClick = (index) => {
        const sectionsCount = sectionsData.length;
        // const forwardSteps = (index - activeSection + sectionsCount) % sectionsCount;
        // const backwardSteps = (activeSection - index + sectionsCount) % sectionsCount;

        // const shortestSteps = forwardSteps <= backwardSteps ? forwardSteps : -backwardSteps;
        // const angleChange = shortestSteps * 90;

        setActiveSection(index);
        // setRotationAngle((prevAngle) => prevAngle + angleChange);
    };

    const handleNext = () => {
        const nextIndex = (activeSection + 1) % sectionsData.length;
        handleSectionClick(nextIndex);
    };

    const handlePrevious = () => {
        const prevIndex = (activeSection - 1 + sectionsData.length) % sectionsData.length;
        handleSectionClick(prevIndex);
    };

    const handleNextForScroll = () => {
        setActiveSection((prevIndex) => {
            const nextIndex = (prevIndex + 1) % sectionsData.length;
            return nextIndex;
        });
    };

    const handlePreviousForScroll = () => {
        setActiveSection((prevIndex) => {
            const prevIndexNew = (prevIndex - 1 + sectionsData.length) % sectionsData.length;
            return prevIndexNew;
        });
    };

    //scroll animation

    useEffect(() => {
        if (sectionsData.length === 0) return;

        const handleResize = () => {
            if (window.innerWidth > 1024) {
                if (timelineRef.current) observerRef.current.observe(timelineRef.current);
            } else {
                if (observerRef.current) observerRef.current.disconnect();
                window.removeEventListener("wheel", handleScroll);
                allowPageScroll();
            }
        };

        observerRef.current = new IntersectionObserver(
            ([entry]) => {
                if (true) return; // remove this line so scroll animation can work

                if (window.innerWidth <= 1024) return; // Disable scrolling on smaller screens ( starting from tablets)

                isInView.current = entry.isIntersecting;

                if (isInView.current) {
                    preventPageScroll();
                    smoothScrollToElement(timelineRef.current);
                    window.addEventListener("wheel", handleScroll, { passive: false });
                } else {
                    setScrollCount(0);
                    window.removeEventListener("wheel", handleScroll);
                }
            },
            { threshold: 0.75 }
        );

        if (window.innerWidth > 1024 && timelineRef.current) {
            observerRef.current.observe(timelineRef.current);
        }

        window.addEventListener("resize", handleResize); // Detect screen size changes

        return () => {
            if (observerRef.current) observerRef.current.disconnect();
            window.removeEventListener("wheel", handleScroll);
            window.removeEventListener("resize", handleResize);
            allowPageScroll();
        };
    }, [sectionsData]);

    const handleScroll = (event) => {
        if (!isInView.current) return;

        event.preventDefault();
        event.stopPropagation();

        if (!isScrollingBlocked.current) {
            isScrollingBlocked.current = true;

            requestAnimationFrame(() => {
                setScrollCount((prev) => {
                    if (event.deltaY > 0) {
                        if (prev < maxScrollCount) {
                            handleNextForScroll();
                            return prev + 1;
                        } else {
                            window.removeEventListener("wheel", handleScroll);
                            allowPageScroll();
                            return maxScrollCount;
                        }
                    } else {
                        if (prev > 0) {
                            handlePreviousForScroll();
                            return prev - 1;
                        } else {
                            window.removeEventListener("wheel", handleScroll);
                            allowPageScroll();
                            return 0;
                        }
                    }
                });

                smoothScrollToElement(timelineRef.current);

                setTimeout(() => {
                    isScrollingBlocked.current = false;
                }, 1000);
            });
        }
    };

    const smoothScrollToElement = (element) => {
        if (!element) return;

        const targetY = element.getBoundingClientRect().top + window.scrollY - (window.innerHeight / 2 - element.offsetHeight / 2);

        window.scrollTo({
            top: targetY,
            behavior: "smooth",
        });
    };

    const preventPageScroll = () => {
        document.body.style.overflow = "hidden";
    };

    const allowPageScroll = () => {
        document.body.style.overflow = "auto";
    };

    //dynamic styles for armenian language

    const dynamicStyles = {
        fontFamily: language === "ARM" && "Mardoto",
        fontWeight: language === "ARM" && 500,
    };

    return (
        <div className={`timeline-system ${isArmenian && "arm-language"}`}>
            <div className="timeline-system-cards">
                {/* <div className="timeline-system-catds_description">
                    <p style={dynamicStyles}>{timelineText[language].beforeText}</p>
                </div> */}
                <div className="timeline-system-cards-header">
                    <span style={dynamicStyles}>{timelineText[language].smallText}</span>
                    <div className="timeline-system-cards-header_icons">
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M0.0164407 3.82196L0 12L3.99046 12L3.99046 3.99715C4.00629 3.99715 4.9794 4.00325 4.9794 4.0002L12 4.0002L12 -5.24537e-07L3.83761 -1.67747e-07C1.73064 -7.56487e-08 0.0219223 1.70984 0.0176598 3.82196"
                                fill="#243BE2"
                            />
                        </svg>

                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M8.17804 11.9836L0 12L0 8.00954L8.00285 8.00954C8.00285 7.99371 7.99675 7.0206 7.9998 7.0206L7.9998 0H12L12 8.16239C12 10.2694 10.2902 11.9781 8.17804 11.9823"
                                fill="#ADF233"
                            />
                        </svg>
                    </div>
                </div>
                <div className="timeline-system-cards-title">
                    <h1 style={dynamicStyles}>{timelineText[language].timelineTitle}</h1>
                </div>
            </div>
            {/* main block */}
            <div ref={timelineRef} className="timeline-circle-wrapper">
                <div className="timeline-content">
                    <div className="text-wrapper-name">
                        {sectionsData.map((section, index) => (
                            <span key={index} style={dynamicStyles} className={activeSection === index ? "fade-in" : "fade-out"}>
                                {section.interval_year}
                            </span>
                        ))}
                    </div>

                    <div className="text-wrapper">
                        {sectionsData.map((section, index) => (
                            <span key={index} style={dynamicStyles} className={activeSection === index ? "fade-in" : "fade-out"}>
                                {section.description}
                            </span>
                        ))}
                    </div>

                    <div className="timeline-circle-container timeline-circle-container-mobile">
                        <div className="timeline-circle-content">
                            <div
                                className="timeline-circle-rotation-wrapper"
                                style={{
                                    transform: `rotate(${rotationAngle}deg)`,
                                    background: `linear-gradient(180deg, #243BE2 0%, #020201 100%)`,
                                }}
                            >
                                <div className="timeline-circle-border">
                                    <div
                                        className="section-names"
                                        style={{
                                            transform: `translateY(-${activeSection * (window.innerWidth <= 576 ? 27 : 90)}px)`,
                                            transition: "transform 0.5s ease",
                                        }}
                                    >
                                        {sectionsData.map((section, index) => {
                                            const distance = Math.abs(index - activeSection);
                                            let color;

                                            if (activeSection === 0 && index === 2) {
                                                color = "#141414"; // Special case for third section when first is active
                                            } else if (distance === 0) {
                                                color = "white"; // Active section
                                            } else if (distance === 1) {
                                                color = "#303030"; // Nearest sections
                                            } else {
                                                color = "transparent"; // All other sections
                                            }

                                            return (
                                                <div
                                                    key={index}
                                                    className="section-name"
                                                    style={{
                                                        color,
                                                        opacity: color === "transparent" ? 0 : 1,
                                                        transition: "color 0.5s ease, opacity 0.5s ease",
                                                        // ...dynamicStyles,
                                                    }}
                                                    onClick={() => handleSectionClick(index)}
                                                >
                                                    {section.interval_year}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="timeline-content_desc">
                        {sectionsData.map((section, index) => (
                            <div key={index} className={`timeline-content_item ${activeSection === index ? "fade-in" : "fade-out"}`}>
                                <h1 style={dynamicStyles}>{section.title}</h1>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="timeline-circle-container timeline-circle-container-desktop">
                    <div className="timeline-circle-content">
                        <div
                            className="timeline-circle-rotation-wrapper"
                            style={{
                                transform: `rotate(${rotationAngle}deg)`,
                                background: `linear-gradient(180deg, #243BE2 0%, #020201 100%)`,
                            }}
                        >
                            <div className="timeline-circle-border">
                                <div
                                    className="section-names"
                                    style={{
                                        transform: `translateY(-${activeSection * (window.innerWidth <= 576 ? 27 : 90)}px)`,
                                        transition: "transform 0.5s ease",
                                    }}
                                >
                                    {sectionsData.map((section, index) => {
                                        const distance = Math.abs(index - activeSection);
                                        let color;

                                        if (activeSection === 0 && index === 2) {
                                            color = "#141414"; // Special case for third section when first is active
                                        } else if (distance === 0) {
                                            color = "white"; // Active section
                                        } else if (distance === 1) {
                                            color = "#303030"; // Nearest sections
                                        } else {
                                            color = "transparent"; // All other sections
                                        }

                                        return (
                                            <div
                                                key={index}
                                                className="section-name"
                                                style={{
                                                    color,
                                                    opacity: color === "transparent" ? 0 : 1,
                                                    transition: "color 0.5s ease, opacity 0.5s ease",
                                                    // ...dynamicStyles,
                                                }}
                                                onClick={() => handleSectionClick(index)}
                                            >
                                                {section.interval_year}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="arrow-controls">
                    <button className="arrow left-arrow" onClick={handlePrevious}>
                        <div className="svg-wrapper">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9 17L1 9M1 9L9 1M1 9L17 9" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                        <div className="svg-wrapper">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9 17L1 9M1 9L9 1M1 9L17 9" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </button>
                    <button className="arrow right-arrow" onClick={handleNext}>
                        <div className="svg-wrapper">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9 17L17 9M17 9L9 1M17 9L1 9" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                        <div className="svg-wrapper">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9 17L17 9M17 9L9 1M17 9L1 9" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </button>
                </div>
            </div>

            {/* footer */}

            <div className="payment-system_footer">
                <span style={dynamicStyles}> {timelineText[language].afterText}</span>
            </div>
        </div>
    );
};

export default Timeline;
