import React from "react";
import { useLanguage } from "../LanguageContext";
import Wrapper from "../components/Wrapper";
import Footer from "../components/Footer";
import LinkCard from "../components/LinkCard";
import BriefCareers from "../components/careers/BriefCareers";
import Vacancies from "../components/careers/Vacancies";
//link card images
import linkBg1 from "../assets/link-card_bg1.png";
import linkBg2 from "../assets/link-card_bg2.png";
import linkBg3 from "../assets/link-card_bg3.png";

const Careers = () => {
    const { language, linkCardsContent } = useLanguage();

    return (
        <Wrapper>
            <Vacancies />
            <BriefCareers />
            <div className="linkcards-container">
                <LinkCard
                    linkPath="/services"
                    title={linkCardsContent[language].services[0].name}
                    titleText={linkCardsContent[language].services[1].title}
                    description={linkCardsContent[language].services[2].desc}
                    bgImg={linkBg1}
                />
                <LinkCard
                    linkPath="/about"
                    title={linkCardsContent[language].aboutus[0].name}
                    titleText={linkCardsContent[language].aboutus[1].title}
                    description={linkCardsContent[language].aboutus[2].desc}
                    bgImg={linkBg2}
                />
                <LinkCard
                    linkPath="/news"
                    title={linkCardsContent[language].news[0].name}
                    titleText={linkCardsContent[language].news[1].title}
                    description={linkCardsContent[language].news[2].desc}
                    bgImg={linkBg3}
                />
            </div>

            <Footer />
        </Wrapper>
    );
};

export default Careers;
