import React, { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Navbar from "./components/Navbar";
import { LanguageProvider } from "./LanguageContext";
import CustomScrollbar from "./components/CustomScrollbar";
import Loader from "./components/Loader";
import AppRouter from "./AppRouter";
import { ConfigProvider } from "./config";
// import AdminRouter from "./admin/AdminRouter";
import {axiosInstance} from "./helper/axiosInstance";

function App() {
    const [isLoading, setIsLoading] = useState(true);
    const [texts, setTexts] = useState({});

    useEffect(() => {
        axiosInstance.get("/translate").then((response) => {
            setTexts(response.data[0].data);
            axiosInstance.get("/blocks").then((response) => {
                setTexts((prev => ({
                 ...prev,
                 images: response.data[0]
                })));
             })
        })

        

        const timer = setTimeout(() => setIsLoading(false), 3000);
        return () => clearTimeout(timer);
    }, []);

    //

    // const isAdminRoute = window.location.pathname.startsWith("/admin");

    /*{{!isAdminRoute && <Navbar />}
                {!isAdminRoute && <CustomScrollbar />}
                {isAdminRoute ? <AdminRouter /> : <AppRouter />}}*/

    if (isLoading) {
        return <Loader />;
    }

    return (
        <LanguageProvider data={texts}>
            <ConfigProvider>
                <Router basename="/">
                    <Navbar />
                    <CustomScrollbar />
                    <AppRouter />
                </Router>
            </ConfigProvider>
        </LanguageProvider>
    );
}

export default App;
