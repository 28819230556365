import React, { useState, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "./ServiceCard.css";
import { useLanguage } from "../../LanguageContext";

function ServiceCard({ header, title1, title2, titleText, description, image, bgImage, id }) {
    // State for the current index
    const [currentIndex, setCurrentIndex] = useState(0);

    const { language } = useLanguage();

    const isArmenian = language === "ARM";

    const dynamicStyles = {
        fontFamily: language === "ARM" && "Mardoto",
        fontWeight: language === "ARM" && 500,
        lineHeight: language === "ARM" && 1.135,
    };

    // Refs for Swipers
    // const swiper1Ref = useRef(null);
    // const swiper2Ref = useRef(null);
    // const swiper3Ref = useRef(null);

    // // Click handler for pagination
    // const handlePaginationClick = (index) => {
    //     setCurrentIndex(index);
    //     // Slide all Swipers to the same index
    //     if (swiper1Ref.current) swiper1Ref.current.slideTo(index);
    //     if (swiper2Ref.current) swiper2Ref.current.slideTo(index);
    //     if (swiper3Ref.current) swiper3Ref.current.slideTo(index);
    // };

    // // Slide change handler for Swipers
    // const handleSlideChange = (swiper) => {
    //     setCurrentIndex(swiper.activeIndex);
    // };

    // Refs for Swipers
    const swiperRefs = useRef([]);

    // Click handler for pagination
    const handlePaginationClick = (index) => {
        setCurrentIndex(index);
        swiperRefs.current.forEach((swiper) => {
            if (swiper) swiper.slideTo(index);
        });
    };

    // Slide change handler for Swipers
    // const handleSlideChange = (swiper) => {
    //     const newIndex = swiper.activeIndex;
    //     setCurrentIndex(newIndex);
    //     swiperRefs.current.forEach((ref) => {
    //         if (ref && ref !== swiper) ref.slideTo(newIndex);
    //     });
    // };

    const handleSlideChange = (swiper) => {
        const newIndex = swiper.activeIndex;
        const prevIndex = currentIndex;

        let correctedIndex = prevIndex + (newIndex > prevIndex ? 1 : -1);

        correctedIndex = Math.max(0, Math.min(title1.length - 1, correctedIndex));

        setCurrentIndex(correctedIndex);

        swiperRefs.current.forEach((ref) => {
            if (ref) ref.slideTo(correctedIndex, 300, false);
        });
    };
    
    return (
        <div className={`service-card ${isArmenian && "arm-language"}`} id={`service${id}`}>
            <div
                className="service-card-content"
                style={{
                    backgroundImage: bgImage ? `url(${bgImage})` : "none",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                }}
            >
                <div className="service-card-left">
                    <div className="service-card_header">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M12.2671 0.0246587L0 0L0 5.98569L12.0043 5.98569C12.0043 6.00944 11.9951 7.46909 11.9997 7.46909V18L18 18L18 5.75642C18 2.59596 15.4352 0.0328814 12.2671 0.0264874"
                                fill="white"
                            />
                        </svg>
                        <span>{header}</span>
                    </div>
                    <div className="text-slider">
                        <Swiper
                            pagination={true}
                            direction="vertical"
                            className="titleSwiper"
                            onSlideChange={handleSlideChange}
                            onSwiper={(swiper) => (swiperRefs.current[0] = swiper)}
                        >
                            {title1.map((text, index) => (
                                <SwiperSlide key={index}>
                                    <span style={dynamicStyles} className="slide">
                                        {text}
                                    </span>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                        <Swiper
                            pagination={true}
                            direction="vertical"
                            className="titleSwiper"
                            onSlideChange={handleSlideChange}
                            onSwiper={(swiper) => (swiperRefs.current[1] = swiper)}
                        >
                            {title2.map((text, index) => (
                                <SwiperSlide key={index}>
                                    <span style={dynamicStyles} className="slide">
                                        {text}
                                    </span>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                        <Swiper
                            pagination={true}
                            direction="vertical"
                            className="descriptionSwiper"
                            onSlideChange={handleSlideChange}
                            onSwiper={(swiper) => (swiperRefs.current[2] = swiper)}
                        >
                            {titleText.map((text, index) => (
                                <SwiperSlide key={index}>
                                    <span style={dynamicStyles} className="slide-description">
                                        {text}
                                    </span>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                    {/* Pagination */}
                    <div className="pagination">
                        {title1.map((_, index) => (
                            <div
                                key={index}
                                className={`pagination-bullet ${index === currentIndex ? "active" : ""}`}
                                onClick={() => handlePaginationClick(index)}
                            ></div>
                        ))}
                    </div>
                </div>
                {image && (
                    <div className={`service-card-right service-card-right-${id}`}>
                        <img src={image} alt="Image" className="card-gif" />
                    </div>
                )}
            </div>
            <div className="service-card-footer">
                <span style={dynamicStyles}>{description}</span>
            </div>
        </div>
    );
}

export default ServiceCard;
