import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import "./styles/NewsPage.css";
import Wrapper from "../components/Wrapper";
import Footer from "../components/Footer";
import { useLanguage } from "../LanguageContext";
import { axiosInstance } from "../helper/axiosInstance";
import moment from "moment";
import "moment/locale/ru";
import "moment/locale/hy-am";
import { useAppConfig } from "../hooks/useAppConfig";

function NewsCard({ id, date, title, description, imageUrl }) {
    const { language } = useLanguage();

    const dynamicStyles = {
        fontFamily: language === "ARM" && "Mardoto",
        fontWeight: language === "ARM" && 500,
    };

    return (
        <Link to={`/news/${id}`} className="newsPage-card">
            <img src={imageUrl} alt={title} className="newsPage-card_image" />
            <div className="newsPage-card_content">
                <p style={dynamicStyles} className="newsPage-card_date">
                    {date}
                </p>
                <span style={dynamicStyles} className="newsPage-card_title">
                    {title}
                </span>
                <p style={dynamicStyles} className="newsPage-card_description" dangerouslySetInnerHTML={{ __html: description }}></p>
            </div>
        </Link>
    );
}

function NewsPage() {
    const [newsData, setNewsData] = useState([]);
    const [showAll, setShowAll] = useState(false);
    const [maxHeight, setMaxHeight] = useState("0px");
    const contentRef = useRef(null);
    const { language, newsText } = useLanguage();

    const config = useAppConfig();

    useEffect(() => {
        if (showAll) {
            setMaxHeight(`${contentRef.current.scrollHeight}px`);
        } else {
            setMaxHeight("0px");
        }
    }, [showAll]);

    useEffect(() => {
        const lang = language === "ARM" ? "hy" : language === "ENG" ? "en" : "ru";
        const momentLang = language === "ARM" ? "hy-am" : language === "ENG" ? "en" : "ru";
        moment.locale(momentLang);

        axiosInstance.get("/news/lang?lang=" + lang).then((response) =>
            setNewsData(
                response.data.map((item) => ({
                    ...item,
                    image: config.data.API_STATIC_URL + item.image,
                    pub_date: moment(item.pub_date).format("LL"),
                }))
            )
        );
    }, [language]);

    const toggleShowAll = () => {
        setShowAll((prev) => !prev);
    };

    const isArmenian = language === "ARM";

    const dynamicStyles = {
        fontFamily: language === "ARM" && "Mardoto",
        fontWeight: language === "ARM" && 500,
    };

    return (
        <Wrapper>
            <div className={`newsPage ${isArmenian && "arm-language"}`}>
                <div className="newsPage-header">
                    <span style={dynamicStyles}>{newsText[language].header}</span>
                    <div className="newsPage-header_icons">
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M0.0164407 3.82196L0 12L3.99046 12L3.99046 3.99715C4.00629 3.99715 4.9794 4.00325 4.9794 4.0002L12 4.0002L12 -5.24537e-07L3.83761 -1.67747e-07C1.73064 -7.56487e-08 0.0219223 1.70984 0.0176598 3.82196"
                                fill="#243BE2"
                            />
                        </svg>
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M8.17804 11.9836L0 12L0 8.00954L8.00285 8.00954C8.00285 7.99371 7.99675 7.0206 7.9998 7.0206L7.9998 0H12L12 8.16239C12 10.2694 10.2902 11.9781 8.17804 11.9823"
                                fill="#ADF233"
                            />
                        </svg>
                    </div>
                </div>
                <div className="newsPage-title">
                    <h1 style={dynamicStyles}>{newsText[language].subheader}</h1>
                </div>

                <div className="newsPage-cards">
                    {newsData.slice(0, 9).map((item, index) => (
                        <NewsCard
                            key={index}
                            id={item.id}
                            date={item.pub_date}
                            title={item.title}
                            description={item.description}
                            imageUrl={item.image}
                        />
                    ))}
                </div>

                <div
                    ref={contentRef}
                    style={{
                        overflow: "hidden",
                        transition: "max-height 0.5s ease-in-out",
                        maxHeight,
                    }}
                >
                    <div className="newsPage-cards">
                        {newsData.slice(9).map((item, index) => (
                            <NewsCard
                                key={index + 9}
                                id={item.id}
                                date={item.pub_date}
                                title={item.title}
                                description={item.description}
                                imageUrl={item.image}
                            />
                        ))}
                    </div>
                </div>

                <div className="newsPage-footer_container">
                    <div className="newsPage-footer" onClick={toggleShowAll} style={{ cursor: "pointer" }}>
                        {/* <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 7.87109V5.1416H5.10352V0H7.93457V5.1416H13V7.87109H7.93457V13H5.10352V7.87109H0Z" fill="black" />
                        </svg> */}
                        <span style={dynamicStyles}>{showAll ? newsText[language].less : newsText[language].more}</span>
                        {/* <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 7.87109V5.1416H5.10352V0H7.93457V5.1416H13V7.87109H7.93457V13H5.10352V7.87109H0Z" fill="black" />
                        </svg> */}
                    </div>
                </div>
            </div>
            <Footer />
        </Wrapper>
    );
}

export default NewsPage;
