// AppRouter.js
import { Routes, Route } from 'react-router-dom';
import Home from "./pages/Home";
import About from "./pages/About";
import Contacts from "./pages/Contacts";
import Services from "./pages/Services";
import NewsPage from "./pages/NewsPage";
import Careers from "./pages/Careers";
import Reports from "./pages/Reports";
import Legal from "./pages/Legal";
import Tenders from "./pages/Tenders";
import Materials from "./pages/Materials";
import Structure from "./pages/Structure";
import NewsDetailPage from "./pages/NewsDetailPage";
import MaterialsDetailPage from "./pages/MaterialsDetailPage";
import UnderCunstruction from "./pages/UnderCunstruction";

const AppRouter = () => {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/services" element={<Services />} />
            <Route path="/news" element={<NewsPage />} />
            <Route path="/careers" element={<Careers />} />
            <Route path="/contacts" element={<Contacts />} />
            <Route path="/reports" element={<Reports />} />
            <Route path="/documents" element={<Legal />} />
            <Route path="/tenders" element={<Tenders />} />
            <Route path="/materials" element={<Materials />} />
            <Route path="/materials/:id" element={<MaterialsDetailPage />} />
            {/* <Route path="/materials" element={<UnderCunstruction />} /> */}
            <Route path="/structure" element={<Structure />} />
            <Route path="/news/:id" element={<NewsDetailPage />} />
            <Route path="*" element={<Home />} />
        </Routes>
    );
};

export default AppRouter;