import React from "react";
import { useLanguage } from "../LanguageContext";
import Footer from "../components/Footer";
import Wrapper from "../components/Wrapper";
import "./styles/Structure.css";
import armPdf from "../documents/structure.pdf";
import armPng from "../assets/structure.png";

import { useAppConfig } from "../hooks/useAppConfig";

function Structure() {
    const { language, structureText, images } = useLanguage();
    
    const config = useAppConfig();
    const dynamicStyles = {
        fontFamily: language === "ARM" && "Mardoto",
        fontWeight: language === "ARM" && 500,
    };

    return (
        <Wrapper>
            <div className="structure">
                <div className="structure-header">
                    <span style={dynamicStyles}>{structureText[language].header}</span>
                    <div className="structure-header_icons">
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M0.0164407 3.82196L0 12L3.99046 12L3.99046 3.99715C4.00629 3.99715 4.9794 4.00325 4.9794 4.0002L12 4.0002L12 -5.24537e-07L3.83761 -1.67747e-07C1.73064 -7.56487e-08 0.0219223 1.70984 0.0176598 3.82196"
                                fill="#243BE2"
                            />
                        </svg>
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M8.17804 11.9836L0 12L0 8.00954L8.00285 8.00954C8.00285 7.99371 7.99675 7.0206 7.9998 7.0206L7.9998 0H12L12 8.16239C12 10.2694 10.2902 11.9781 8.17804 11.9823"
                                fill="#ADF233"
                            />
                        </svg>
                    </div>
                </div>
                <div className="structure-title">
                    <h1 style={dynamicStyles}>{structureText[language].subheader}</h1>
                </div>

                <div className="structure-preview">
                    <img src={config.data.API_STATIC_URL + images.image4} alt="Organizational structure" />
                </div>

                <div className="structure-download">
                    <a href={language === "ARM" ? config.data.API_STATIC_URL + images.image7 : language === "ENG" ? config.data.API_STATIC_URL + images.image5 : config.data.API_STATIC_URL + images.image6} rel="noopener noreferrer" target="_blank" download="Organizational structure.pdf" style={dynamicStyles}>
                        {structureText[language].download}
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_1285_13901)">
                                <path d="M5 9L9 13M9 13L13 9M9 13L9 5" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                            </g>
                            <rect x="17.5" y="0.5" width="17" height="17" rx="8.5" transform="rotate(90 17.5 0.5)" stroke="black" />
                            <defs>
                                <clipPath id="clip0_1285_13901">
                                    <rect x="18" width="18" height="18" rx="9" transform="rotate(90 18 0)" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    </a>
                </div>
            </div>
            <Footer />
        </Wrapper>
    );
}

export default Structure;
