import React, { useState, useEffect } from "react";
import "./Vacancies.css";
import { useLanguage } from "../../LanguageContext";
import VacancyCard from "./VacancyCard";
import VacancyModal from "./VacancyModal";
import { axiosInstance } from "../../helper/axiosInstance";

// const vacanciesData = [
//     {
//         title: "Information security specialist",
//         description: "Armenian Card is looking for an experienced and responsible employee to work in the Information Security Department.",
//         modalDescription: "Armenian Card is looking for an experienced and responsible employee to work in the Information Security Department.",
//         experience: "5+ years of experience",
//         type: "Full time",
//         location: "Yerevan",
//         responsibilities: [
//             "Maintaining the company's existing security systems",
//             "Identifying/analyzing IT incidents/problems, preventing them and providing solutions",
//             "Managing risks related to the security features of information systems",
//             "Creating security incident reports Implementing/managing security tools",
//             "Managing antivirus protection servers, monitoring client status, removing viruses, protecting",
//             "Searching for vulnerabilities using specialized software and eliminating them",
//             "Monitoring the release of operating system, software and network equipment updates",
//             "Periodic analysis of system logs",
//         ],
//         requiredSkills: [
//             "Strong analytical skills",
//             "Experience with cybersecurity tools",
//             "Knowledge of risk management",
//             "Excellent problem-solving abilities",
//         ],
//     },
//     {
//         title: "Systems analysis and integration specialist",
//         description: "The position involves creating technical documentation, applying API methods, administering and supporting systems.",
//         modalDescription: "Armenian Card is looking for an experienced and responsible employee to work in the Information Security Department.",
//         experience: "5+ years of experience",
//         type: "Full time",
//         location: "Yerevan",
//         responsibilities: [
//             "Maintaining the company's existing security systems",
//             "Identifying/analyzing IT incidents/problems, preventing them and providing solutions",
//             "Managing risks related to the security features of information systems",
//             "Creating security incident reports Implementing/managing security tools",
//             "Managing antivirus protection servers, monitoring client status, removing viruses, protecting",
//             "Searching for vulnerabilities using specialized software and eliminating them",
//             "Monitoring the release of operating system, software and network equipment updates",
//             "Periodic analysis of system logs",
//         ],
//         requiredSkills: [
//             "Strong analytical skills",
//             "Experience with cybersecurity tools",
//             "Knowledge of risk management",
//             "Excellent problem-solving abilities",
//         ],
//     },
//     {
//         title: "Security key management specialist",
//         description: "Armenian Card Company is looking for an experienced and responsible employee to work in the Security Key Management.",
//         modalDescription: "Armenian Card is looking for an experienced and responsible employee to work in the Information Security Department.",
//         experience: "5+ years of experience",
//         type: "Full time",
//         location: "Yerevan",
//         responsibilities: [
//             "Maintaining the company's existing security systems",
//             "Identifying/analyzing IT incidents/problems, preventing them and providing solutions",
//             "Managing risks related to the security features of information systems",
//             "Creating security incident reports Implementing/managing security tools",
//             "Managing antivirus protection servers, monitoring client status, removing viruses, protecting",
//             "Searching for vulnerabilities using specialized software and eliminating them",
//             "Monitoring the release of operating system, software and network equipment updates",
//             "Periodic analysis of system logs",
//         ],
//         requiredSkills: [
//             "Strong analytical skills",
//             "Experience with cybersecurity tools",
//             "Knowledge of risk management",
//             "Excellent problem-solving abilities",
//         ],
//     },
//     {
//         title: "Support and incident analysis center specialist",
//         description: "Armenian Card CJSC is looking for a Support and Incident Analysis Center Specialist.",
//         modalDescription: "Armenian Card is looking for an experienced and responsible employee to work in the Information Security Department.",
//         experience: "5+ years of experience",
//         type: "Full time",
//         location: "Yerevan",
//         responsibilities: [
//             "Maintaining the company's existing security systems",
//             "Identifying/analyzing IT incidents/problems, preventing them and providing solutions",
//             "Managing risks related to the security features of information systems",
//             "Creating security incident reports Implementing/managing security tools",
//             "Managing antivirus protection servers, monitoring client status, removing viruses, protecting",
//             "Searching for vulnerabilities using specialized software and eliminating them",
//             "Monitoring the release of operating system, software and network equipment updates",
//             "Periodic analysis of system logs",
//         ],
//         requiredSkills: [
//             "Strong analytical skills",
//             "Experience with cybersecurity tools",
//             "Knowledge of risk management",
//             "Excellent problem-solving abilities",
//         ],
//     },
//     {
//         title: "Head of reporting data coordination department",
//         description: "Armenian Card company is looking for an experienced and responsible employee.",
//         modalDescription: "Armenian Card is looking for an experienced and responsible employee to work in the Information Security Department.",
//         experience: "5+ years of experience",
//         type: "Full time",
//         location: "Yerevan",
//         responsibilities: [
//             "Maintaining the company's existing security systems",
//             "Identifying/analyzing IT incidents/problems, preventing them and providing solutions",
//             "Managing risks related to the security features of information systems",
//             "Creating security incident reports Implementing/managing security tools",
//             "Managing antivirus protection servers, monitoring client status, removing viruses, protecting",
//             "Searching for vulnerabilities using specialized software and eliminating them",
//             "Monitoring the release of operating system, software and network equipment updates",
//             "Periodic analysis of system logs",
//         ],
//         requiredSkills: [
//             "Strong analytical skills",
//             "Experience with cybersecurity tools",
//             "Knowledge of risk management",
//             "Excellent problem-solving abilities",
//         ],
//     },
// ];

function Vacancies() {
    const { language, vacanciesText } = useLanguage();
    const [vacancies, setVacancies] = useState([]);

    const dynamicStyles = {
        fontFamily: language === "ARM" && "Mardoto",
        fontWeight: language === "ARM" && 500,
    };

    const [selectedVacancy, setSelectedVacancy] = useState(null);

    useEffect(() => {
        const lang = language === "ARM" ? "hy" : language === 'ENG' ? 'en' : 'ru';
        axiosInstance.get("/careers/lang?lang=" + lang)
        .then((res) => 
            setVacancies(res.data.map(item => ({...item, responsibilities: item.responsibilities ? item.responsibilities.split('\n') : [], requiredSkills: item.requiredSkills ? item.requiredSkills.split('\n') : []}))));
    }, [language]);

    const handleCardClick = (vacancy) => {
        const queryParams = new URLSearchParams(window.location.search);
        queryParams.set("id", vacancy.id);
        window.history.replaceState({}, "", `${window.location.pathname}?${queryParams.toString()}`);
        setSelectedVacancy(vacancy);
    };

    const handleCloseModal = () => {
        setSelectedVacancy(null);
    };

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const id = queryParams.get("id");
        if (id) {
            setSelectedVacancy(vacancies.find((vacancy) => vacancy.id == id));
        }
    }, [vacancies]);
    
    return (
        <div className="vacancies">
            <div className="vacancies-header">
                <span style={dynamicStyles}>{vacanciesText[language].header}</span>
                <div className="vacancies-header_icons">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M0.0164407 3.82196L0 12L3.99046 12L3.99046 3.99715C4.00629 3.99715 4.9794 4.00325 4.9794 4.0002L12 4.0002L12 -5.24537e-07L3.83761 -1.67747e-07C1.73064 -7.56487e-08 0.0219223 1.70984 0.0176598 3.82196"
                            fill="#243BE2"
                        />
                    </svg>
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M8.17804 11.9836L0 12L0 8.00954L8.00285 8.00954C8.00285 7.99371 7.99675 7.0206 7.9998 7.0206L7.9998 0H12L12 8.16239C12 10.2694 10.2902 11.9781 8.17804 11.9823"
                            fill="#ADF233"
                        />
                    </svg>
                </div>
            </div>
            <div className="vacancies-title">
                <h1 style={dynamicStyles}>{vacanciesText[language].subheader}</h1>
            </div>

            <div className="vacancies-list">
                {vacancies.map((vacancy, index) => (
                    <VacancyCard
                        key={index}
                        id={vacancy.id}
                        title={vacancy.title}
                        description={vacancy.description}
                        experience={vacancy.experience}
                        type={vacancy.type}
                        location={vacancy.location}
                        onClick={() => handleCardClick(vacancy)}
                    />
                ))}
            </div>

            {/* Modal */}
            <VacancyModal vacancy={selectedVacancy} onClose={handleCloseModal} />
        </div>
    );
}

export default Vacancies;
