import React, { useState, useEffect } from "react";
import "./styles/Tenders.css";
import Footer from "../components/Footer";
import Wrapper from "../components/Wrapper";
import { useLanguage } from "../LanguageContext";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import TendersModal from "../components/tenders/TendersModal";
import { axiosInstance } from "../helper/axiosInstance";

function Tenders() {
    const { language, reportsText, tendersText } = useLanguage();
    const [tendersData, setTendersData] = useState([]);
    const [status, setStatus] = useState(1);

    const isArmenian = language === "ARM";

    const dynamicStyles = {
        fontFamily: language === "ARM" && "Mardoto",
        fontWeight: language === "ARM" && 500,
    };

    const [selectedTender, setSelectedTender] = useState(null);

    const handleCardClick = (tender) => {
        const queryParams = new URLSearchParams(window.location.search);
        queryParams.set("id", tender.id);
        window.history.replaceState({}, "", `${window.location.pathname}?${queryParams.toString()}`);
        setSelectedTender(tender);
    };

    const handleCloseModal = () => {
        setSelectedTender(null);
    };

    useEffect(() => {
        const lang = language === "ARM" ? "hy" : language === "ENG" ? "en" : "ru";
        axiosInstance.get("/tenders/lang?lang=" + lang + "&status=" + status).then((res) => setTendersData(res.data));
    }, [language]);

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const id = queryParams.get("id");
        if (id) {
            setSelectedTender(tendersData.find((tender) => tender.id == id));
        }
    }, [tendersData]);

    // const tendersData = [
    //     {
    //         title: "Civil Works for Construction of Arca new office",
    //         description:
    //             "Armenian Card is organizing a tender to conclude cooperation agreements with movable and immovable property valuation companies.",
    //         modalDescription:
    //             "Armenian Card is organizing a tender to conclude cooperation agreements with movable and immovable property valuation companies. Open competitive tendering without prequalification One-Stage One-Envelope is open to all eligible Tenderers as described in the Tender Dossier.",
    //         contractNumber: "HQ-ACW-15",
    //         deliverTo: "Movses Khorenatsi 15 Str, 'Elite Plaza' Business Center, Yerevan 0010, Republic Armenia",
    //         deadline: "15.01.2024 in 18:00 (Yerevan time)",
    //         price: "USD 600,000 (six hundred thousand US dollars)",
    //         tags: ["Until 15 January", "5+ years of experience", "Yerevan"],
    //         image: "path/to/your/image.jpg",
    //         details: {
    //             sourcesOfFinance: "Private funds of Armenian Card, the main owner of Arca bank",
    //             location: "Yerevan, RA, Admiral Isakov Ave",
    //             siteArea: "2.6 ha",
    //             totalSurface: ">10,000 sq.m",
    //             floors: 3,
    //             estimatedAwardDate: "01.03.2025",
    //             completionTime: "24 months",
    //             tenderConsultant: "Mr. Karen Sarkisyan, Senior Partner Arca, ksarkisyan@arcabank.am",
    //             qualificationCriteria: [
    //                 "Minimum Average Annual Construction Turnover of $15,000,000 (fifteen million US dollars) calculated as total certified payments received for contracts in progress or completed within the last 5 years.",
    //                 "Participation as a contractor, Joint Venture partner, or Subcontractor in at least 2 contracts that have been successfully or substantially completed within the last 5 years, similar to the proposed works, where the value of the Tenderer's participation exceeds $25,000,000 (twenty-five million US dollars).",
    //                 "Participation as a contractor in at least 1 contract containing construction of an Atrium or Metal Erections of technics similar to Atrium, completed within the last 7 years. If the Tenderer is a Joint Venture, at least one member must meet this requirement.",
    //             ],
    //             additionalInfo: [
    //                 "Tenders will be opened immediately after the deadline for tender submission in the presence of Tenderer’s representatives who choose to attend.",
    //                 "To obtain Tender Dossier (Tender/Bidding documents), interested parties shall contact Employer’s Tender Consultant at the address indicated herein. If the Tender Dossier is obtained from other sources, participants bear the risk of not being informed on any addendum to the Tender Dossier.",
    //             ],
    //         },
    //     },
    //     {
    //         title: "Civil Works for Construction of Arca new office",
    //         description:
    //             "Armenian Card is organizing a tender to conclude cooperation agreements with movable and immovable property valuation companies.",
    //         modalDescription:
    //             "Armenian Card is organizing a tender to conclude cooperation agreements with movable and immovable property valuation companies. Open competitive tendering without prequalification One-Stage One-Envelope is open to all eligible Tenderers as described in the Tender Dossier.",
    //         contractNumber: "HQ-ACW-15",
    //         deliverTo: "Movses Khorenatsi 15 Str, 'Elite Plaza' Business Center, Yerevan 0010, Republic Armenia",
    //         deadline: "15.01.2024 in 18:00 (Yerevan time)",
    //         price: "USD 600,000 (six hundred thousand US dollars)",
    //         tags: ["Until 15 January", "5+ years of experience", "Yerevan"],
    //         image: "path/to/your/image.jpg",
    //         details: {
    //             sourcesOfFinance: "Private funds of Armenian Card, the main owner of Arca bank",
    //             location: "Yerevan, RA, Admiral Isakov Ave",
    //             siteArea: "2.6 ha",
    //             totalSurface: ">10,000 sq.m",
    //             floors: 3,
    //             estimatedAwardDate: "01.03.2025",
    //             completionTime: "24 months",
    //             tenderConsultant: "Mr. Karen Sarkisyan, Senior Partner Arca, ksarkisyan@arcabank.am",
    //             qualificationCriteria: [
    //                 "Minimum Average Annual Construction Turnover of $15,000,000 (fifteen million US dollars) calculated as total certified payments received for contracts in progress or completed within the last 5 years.",
    //                 "Participation as a contractor, Joint Venture partner, or Subcontractor in at least 2 contracts that have been successfully or substantially completed within the last 5 years, similar to the proposed works, where the value of the Tenderer's participation exceeds $25,000,000 (twenty-five million US dollars).",
    //                 "Participation as a contractor in at least 1 contract containing construction of an Atrium or Metal Erections of technics similar to Atrium, completed within the last 7 years. If the Tenderer is a Joint Venture, at least one member must meet this requirement.",
    //             ],
    //             additionalInfo: [
    //                 "Tenders will be opened immediately after the deadline for tender submission in the presence of Tenderer’s representatives who choose to attend.",
    //                 "To obtain Tender Dossier (Tender/Bidding documents), interested parties shall contact Employer’s Tender Consultant at the address indicated herein. If the Tender Dossier is obtained from other sources, participants bear the risk of not being informed on any addendum to the Tender Dossier.",
    //             ],
    //         },
    //     },
    //     {
    //         title: "Civil Works for Construction of Arca new office",
    //         description:
    //             "Armenian Card is organizing a tender to conclude cooperation agreements with movable and immovable property valuation companies.",
    //         modalDescription:
    //             "Armenian Card is organizing a tender to conclude cooperation agreements with movable and immovable property valuation companies. Open competitive tendering without prequalification One-Stage One-Envelope is open to all eligible Tenderers as described in the Tender Dossier.",
    //         contractNumber: "HQ-ACW-15",
    //         deliverTo: "Movses Khorenatsi 15 Str, 'Elite Plaza' Business Center, Yerevan 0010, Republic Armenia",
    //         deadline: "15.01.2024 in 18:00 (Yerevan time)",
    //         price: "USD 600,000 (six hundred thousand US dollars)",
    //         tags: ["Until 15 January", "5+ years of experience", "Yerevan"],
    //         image: "path/to/your/image.jpg",
    //         details: {
    //             sourcesOfFinance: "Private funds of Armenian Card, the main owner of Arca bank",
    //             location: "Yerevan, RA, Admiral Isakov Ave",
    //             siteArea: "2.6 ha",
    //             totalSurface: ">10,000 sq.m",
    //             floors: 3,
    //             estimatedAwardDate: "01.03.2025",
    //             completionTime: "24 months",
    //             tenderConsultant: "Mr. Karen Sarkisyan, Senior Partner Arca, ksarkisyan@arcabank.am",
    //             qualificationCriteria: [
    //                 "Minimum Average Annual Construction Turnover of $15,000,000 (fifteen million US dollars) calculated as total certified payments received for contracts in progress or completed within the last 5 years.",
    //                 "Participation as a contractor, Joint Venture partner, or Subcontractor in at least 2 contracts that have been successfully or substantially completed within the last 5 years, similar to the proposed works, where the value of the Tenderer's participation exceeds $25,000,000 (twenty-five million US dollars).",
    //                 "Participation as a contractor in at least 1 contract containing construction of an Atrium or Metal Erections of technics similar to Atrium, completed within the last 7 years. If the Tenderer is a Joint Venture, at least one member must meet this requirement.",
    //             ],
    //             additionalInfo: [
    //                 "Tenders will be opened immediately after the deadline for tender submission in the presence of Tenderer’s representatives who choose to attend.",
    //                 "To obtain Tender Dossier (Tender/Bidding documents), interested parties shall contact Employer’s Tender Consultant at the address indicated herein. If the Tender Dossier is obtained from other sources, participants bear the risk of not being informed on any addendum to the Tender Dossier.",
    //             ],
    //         },
    //     },
    // ];

    return (
        <Wrapper>
            <div className={`tenders ${isArmenian && "arm-language"}`}>
                <div className="tenders-header">
                    <span style={dynamicStyles}>{tendersText[language].header}</span>
                    <div className="tenders-header_icons">
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M0.0164407 3.82196L0 12L3.99046 12L3.99046 3.99715C4.00629 3.99715 4.9794 4.00325 4.9794 4.0002L12 4.0002L12 -5.24537e-07L3.83761 -1.67747e-07C1.73064 -7.56487e-08 0.0219223 1.70984 0.0176598 3.82196"
                                fill="#243BE2"
                            />
                        </svg>
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M8.17804 11.9836L0 12L0 8.00954L8.00285 8.00954C8.00285 7.99371 7.99675 7.0206 7.9998 7.0206L7.9998 0H12L12 8.16239C12 10.2694 10.2902 11.9781 8.17804 11.9823"
                                fill="#ADF233"
                            />
                        </svg>
                    </div>
                </div>
                <div className="tenders-title">
                    <h1 style={dynamicStyles}>{tendersText[language].subheader}</h1>
                </div>

                {/* tenders Navigation Buttons */}
                <div className="tenders-navigation">
                    <button className="tenders-prev" style={dynamicStyles} onClick={() => setStatus(1)}>
                        {tendersText[language].open}
                    </button>
                    <button className="tenders-next" style={dynamicStyles} onClick={() => setStatus(2)}>
                        {tendersText[language].close}
                    </button>
                </div>

                <Swiper
                    navigation={{
                        prevEl: ".tenders-prev",
                        nextEl: ".tenders-next",
                    }}
                    modules={[Navigation]}
                    className="tendersSwiper"
                >
                    <SwiperSlide>
                        <div id="tenders-pagination_container"></div>
                        <div className="tenders-slider">
                            <Swiper
                                breakpoints={{
                                    0: {
                                        slidesPerView: 1.06,
                                        slidesPerGroup: 1,
                                        spaceBetween: 20,
                                    },
                                    576: {
                                        slidesPerView: 3,
                                        slidesPerGroup: 3,
                                        spaceBetween: 10,
                                    },
                                    1000: {
                                        slidesPerView: 3,
                                        slidesPerGroup: 3,
                                        spaceBetween: 15,
                                    },
                                    1500: {
                                        slidesPerView: 3,
                                        slidesPerGroup: 3,
                                        spaceBetween: 25,
                                    },
                                }}
                                spaceBetween={25}
                                slidesPerView={3}
                                slidesPerGroup={3}
                                pagination={{
                                    clickable: true,
                                    el: "#tenders-pagination_container",
                                }}
                                navigation={{
                                    prevEl: ".tenders-first-prev",
                                    nextEl: ".tenders-first-next",
                                }}
                                modules={[Pagination, Navigation]}
                                className="sliderTenders"
                            >
                                {tendersData
                                    .filter((item) => item.status === 1)
                                    .map((item, index) => (
                                        <SwiperSlide key={index} onClick={() => handleCardClick(item)}>
                                            <div className="tenders-card">
                                                <h3 style={dynamicStyles}>{item.title}</h3>
                                                <div className="tenders-card_description">
                                                    <span style={dynamicStyles}>{tendersText[language].description}</span>
                                                    <p style={dynamicStyles}>{item.description}</p>
                                                </div>
                                                <div className="tenders-card_info-container">
                                                    {/*<p className="tenders-card_info">
                                                    <span>Contract number:&nbsp;</span> {item.contractNumber}
                                                </p>
                                                <div className="tenders-card_deliver">
                                                    <span>Tenders deliver to:</span>
                                                    <p>{item.deliverTo}</p>
                                                </div>
                                                <div className="tenders-card_deliver">
                                                    <span>Deadline:</span>
                                                    <p>{item.deadline}</p>
                                                </div>
                                                <div className="tenders-card_deliver">
                                                    <span>Price:</span>
                                                    <p>{item.price}</p>
                                                </div>*/}
                                                    {/* <p className="tenders-card_info">
                                                    <span>Deadline:&nbsp;</span> {item.deadline}
                                                </p>
                                                <p className="tenders-card_info">
                                                    <span>Price:&nbsp;</span> {item.price}
                                                </p> */}
                                                </div>
                                                <div className="tenders-tags_container">
                                                    {/*<div className="tenders-tags">
                                                    {item.tags.map((tag, idx) => (
                                                        <span key={idx} className="tenders-tag">
                                                            {tag}
                                                        </span>
                                                    ))}
                                                </div>*/}
                                                    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <circle cx="21" cy="21" r="21" fill="white" />
                                                        <rect x="20.5" y="13" width="1" height="16" fill="black" />
                                                        <rect x="29" y="20.5" width="1" height="16" transform="rotate(90 29 20.5)" fill="black" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    ))}
                            </Swiper>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div id="tenders-pagination_container_second"></div>
                        <div className="tenders-slider">
                            <Swiper
                                breakpoints={{
                                    0: {
                                        slidesPerView: 1.06,
                                        slidesPerGroup: 1,
                                        spaceBetween: 20,
                                    },
                                    576: {
                                        slidesPerView: 3,
                                        slidesPerGroup: 3,
                                        spaceBetween: 10,
                                    },
                                    1000: {
                                        slidesPerView: 3,
                                        slidesPerGroup: 3,
                                        spaceBetween: 15,
                                    },
                                    1500: {
                                        slidesPerView: 3,
                                        slidesPerGroup: 3,
                                        spaceBetween: 25,
                                    },
                                }}
                                spaceBetween={25}
                                slidesPerView={3}
                                slidesPerGroup={3}
                                pagination={{
                                    clickable: true,
                                    el: "#tenders-pagination_container_second",
                                }}
                                navigation={{
                                    prevEl: ".tenders-second-prev",
                                    nextEl: ".tenders-second-next",
                                }}
                                modules={[Pagination, Navigation]}
                                className="sliderTenders"
                            >
                                {tendersData
                                    .filter((item) => item.status === 2)
                                    .map((item, index) => (
                                        <SwiperSlide key={index} onClick={() => handleCardClick(item)}>
                                            <div className="tenders-card">
                                                <h3>{item.title}</h3>
                                                <div className="tenders-card_description">
                                                    <span>Description</span>
                                                    <p>{item.description}</p>
                                                </div>
                                                <div className="tenders-card_info-container">
                                                    {/* <p className="tenders-card_info">
                                                    <span>Contract number:&nbsp;</span> {item.contractNumber}
                                                </p>
                                                <div className="tenders-card_deliver">
                                                    <span>Tenders deliver to:</span>
                                                    <p>{item.deliverTo}</p>
                                                </div>
                                                <div className="tenders-card_deliver">
                                                    <span>Deadline:</span>
                                                    <p>{item.deadline}</p>
                                                </div>
                                                <div className="tenders-card_deliver">
                                                    <span>Price:</span>
                                                    <p>{item.price}</p>
                                                </div> */}
                                                    {/* <p className="tenders-card_info">
                                                    <span>Deadline:&nbsp;</span> {item.deadline}
                                                </p>
                                                <p className="tenders-card_info">
                                                    <span>Price:&nbsp;</span> {item.price}
                                                </p> */}
                                                </div>
                                                <div className="tenders-tags_container">
                                                    {/* <div className="tenders-tags">
                                                    {item.tags.map((tag, idx) => (
                                                        <span key={idx} className="tenders-tag">
                                                            {tag}
                                                        </span>
                                                    ))}
                                                </div> */}
                                                    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <circle cx="21" cy="21" r="21" fill="white" />
                                                        <rect x="20.5" y="13" width="1" height="16" fill="black" />
                                                        <rect x="29" y="20.5" width="1" height="16" transform="rotate(90 29 20.5)" fill="black" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    ))}
                            </Swiper>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>

            {/* Modal */}
            <TendersModal tender={selectedTender} onClose={handleCloseModal} />

            <Footer />
        </Wrapper>
    );
}

export default Tenders;
