import React from "react";
import "./VacancyModal.css";
import { useLanguage } from "../../LanguageContext";

function VacancyModal({ vacancy, onClose }) {
    if (!vacancy) return null;

    const { language, vacancyCardText } = useLanguage();
    const dynamicStyles = {
        fontFamily: language === "ARM" && "Mardoto",
        fontWeight: language === "ARM" && 500,
    };

    return (
        <div className="vacancy-modal-overlay" onClick={onClose}>
            <div className="vacancy-modal" onClick={(e) => e.stopPropagation()} style={dynamicStyles}>
                <div className="empty_div">
                    <button className="vacancy-modal-close" onClick={onClose}>
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.2 32L0 28.8L12.8 16L0 3.2L3.2 0L16 12.8L28.8 0L32 3.2L19.2 16L32 28.8L28.8 32L16 19.2L3.2 32Z" fill="black" />
                        </svg>
                    </button>
                </div>
                <div className="vacancy_modal-content">
                    <div className="vacancy-modal-header">
                        {vacancy.experience && <span>{vacancy.experience}</span>}
                        {vacancy.type && <span>{vacancy.type}</span>}
                        {vacancy.location && <span>{vacancy.location}</span>}
                    </div>
                    <div className="vacancy-modal__section">
                        <h2>{vacancy.title}</h2>
                        <p>{vacancy.description}</p>
                    </div>
                    {/* Modal Description */}
                    {/* <div className="vacancy-modal__section">
                        <h3>{vacancyCardText[language].description}</h3>
                        <p>{vacancy.modalDescription}</p>
                    </div> */}
                    {/* Job Responsibilities */}
                    <div dangerouslySetInnerHTML={{ __html: vacancy.positionDetails }} />
                    {/*<h3>Job Responsibilities</h3>*/}
                    {/*<ul>*/}
                    {/*    {vacancy.responsibilities?.map((item, index) => (*/}
                    {/*        <li key={index}>{item}</li>*/}
                    {/*    ))}*/}
                    {/*</ul>*/}

                    {/* Required Skills */}

                    {/*<h3>Required Skills</h3>*/}
                    {/*<ul>*/}
                    {/*    {vacancy.requiredSkills?.map((skill, index) => (*/}
                    {/*        <li key={index}>{skill}</li>*/}
                    {/*    ))}*/}
                    {/*</ul>*/}

                    <span className="vacancy-modal_footer">
                        {vacancyCardText[language].link} <a href="mailto:hr@arca.am">hr@arca.am</a>
                    </span>
                </div>
                <div className="empty_div-footer"></div>
            </div>
        </div>
    );
}

export default VacancyModal;
