import {ConfigContext} from "./contexts/config";
import { useState } from "react";

export const ConfigProvider = ({children}) => {
    const [data, setData] = useState({
        // API_URL: 'http://192.168.1.185:5001',
        // API_STATIC_URL: 'http://192.168.1.185:5001/static',
        API_URL: 'https://www.arca.am/backend',
        API_STATIC_URL: 'https://www.arca.am/backend/static',
        lang: 'en',
    });

    return (
        <ConfigContext.Provider value={{data, setData}}>
            {children}
        </ConfigContext.Provider>
    );
}   
