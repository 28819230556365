import React from "react";
import "./TendersModal.css";
import {useLanguage} from "../../LanguageContext";
// import termsPdf from "../documents/terms.pdf";
// import descriptionPdf from "../documents/description_new.pdf";
import {useAppConfig} from "../../hooks/useAppConfig";

function TendersModal({ tender, onClose }) {
    if (!tender) return null;

    const config = useAppConfig();

    const { language, reportsText, tendersText } = useLanguage();

    const isArmenian = language === "ARM";

    const dynamicStyles = {
        fontFamily: language === "ARM" && "Mardoto",
        fontWeight: language === "ARM" && 500,
    };

    return (
        <div className="tender-modal-overlay" onClick={onClose}>
            <div className="tender-modal" onClick={(e) => e.stopPropagation()}>
                <div className="empty_div">
                    <button className="tender-modal-close" onClick={onClose}>
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.2 32L0 28.8L12.8 16L0 3.2L3.2 0L16 12.8L28.8 0L32 3.2L19.2 16L32 28.8L28.8 32L16 19.2L3.2 32Z" fill="black" />
                        </svg>
                    </button>
                </div>
                <div className="tender_modal-content">
                    {/*<div className="tender-modal-header">
                        {tender.tags.map((tag, idx) => (
                            <span key={idx}>{tag}</span>
                        ))}
                    </div>*/}
                    <div className="tender-modal__section">
                        <h2 style={dynamicStyles}>{tender.title}</h2>
                        <p style={dynamicStyles}>{tender.description}</p>
                    </div>

                    {/*<div className="tender-modal__info">
                        <span>Contract number:</span>
                        <p>{tender.contractNumber}</p>
                    </div>
                    <div className="tender-modal__info">
                        <span>Sources of finance:</span>
                        <p>{tender.details.sourcesOfFinance}</p>
                    </div>
                    <div className="tender-modal__info">
                        <span>Location:</span>
                        <p>{tender.details.location}</p>
                    </div>
                    <div className="tender-modal__info">
                        <span>Site Area:</span>
                        <p>{tender.details.siteArea}</p>
                    </div>
                    <div className="tender-modal__info">
                        <span>Total surface to be constructed:</span>
                        <p>{tender.details.totalSurface}</p>
                    </div>
                    <div className="tender-modal__info">
                        <span>Floors:</span>
                        <p>{tender.details.floors}</p>
                    </div>
                    <div className="tender-modal__info">
                        <span>Estimated contract award date:</span>
                        <p>{tender.details.estimatedAwardDate}</p>
                    </div>
                    <div className="tender-modal__info">
                        <span>Time for Completion:</span>
                        <p>{tender.details.completionTime}</p>
                    </div>
                    <div className="tender-modal__info">
                        <span>Estimated budget:</span>
                        <p>{tender.price}</p>
                    </div>
                    <div className="tender-modal__info">
                        <span>Tender Consultant:</span>
                        <p>{tender.details.tenderConsultant}</p>
                    </div>
                    <div className="tender-modal__info">
                        <span>Tenders deliver to:</span>
                        <p>{tender.deliverTo}</p>
                    </div>

                    <div className="tender-modal__section">
                        <h3>Minimum Qualification Criteria</h3>
                        <ul>
                            {tender.details.qualificationCriteria.map((criterion, idx) => (
                                <li key={idx}>{criterion}</li>
                            ))}
                        </ul>
                    </div>*/}

                    {/*<div className="tender-modal__section">
                        <h3>Additional Information</h3>
                        <ul>
                            {tender.details.additionalInfo.map((info, idx) => (
                                <li key={idx}>{info}</li>
                            ))}
                        </ul>
                    </div>*/}

                    {/*<span className="tender-modal_footer">
                        Interested candidates can send their resumes by email: <a href="mailto:info@arca.am">info@arca.am</a>
                    </span>*/}

                    <div className="tenders-docs">
                        {tender.descriptionFile && (
                            <a
                                className="reports-card_pdf"
                                href={config.data.API_STATIC_URL + tender.descriptionFile}
                                rel="noopener noreferrer"
                                target="_blank"
                                download="ՆԿԱՐԱԳՐՈՒԹՐՈՒՆ.pdf"
                                style={dynamicStyles}
                            >
                                ՆԿԱՐԱԳՐՈՒԹՐՈՒՆ
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_1696_1616)">
                                        <path d="M8 12L12 16M12 16L16 12M12 16L12 8" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                    </g>
                                    <rect x="23.5" y="0.5" width="23" height="23" rx="11.5" transform="rotate(90 23.5 0.5)" stroke="black" />
                                    <defs>
                                        <clipPath id="clip0_1696_1616">
                                            <rect x="24" width="24" height="24" rx="12" transform="rotate(90 24 0)" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </a>
                        )}
                        {tender.privacyFile && (
                            <a
                                className="reports-card_pdf"
                                href={config.data.API_STATIC_URL + tender.privacyFile}
                                rel="noopener noreferrer"
                                target="_blank"
                                download="ԳԱՂՏՆԻՈՒԹՅԱՆ_ՊԱՀՊԱՆՄԱՆ_ՊԱՅՄԱՆԱԳԻՐ.pdf"
                                style={dynamicStyles}
                            >
                                ԳԱՂՏՆԻՈՒԹՅԱՆ ՊԱՀՊԱՆՄԱՆ ՊԱՅՄԱՆԱԳԻՐ
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_1696_1616)">
                                        <path d="M8 12L12 16M12 16L16 12M12 16L12 8" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                    </g>
                                    <rect x="23.5" y="0.5" width="23" height="23" rx="11.5" transform="rotate(90 23.5 0.5)" stroke="black" />
                                    <defs>
                                        <clipPath id="clip0_1696_1616">
                                            <rect x="24" width="24" height="24" rx="12" transform="rotate(90 24 0)" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </a>
                        )}
                    </div>
                </div>
                <div className="empty_div-footer"></div>
            </div>
        </div>
    );
}

export default TendersModal;
