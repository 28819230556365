import React, { useState, useRef, useEffect } from "react";
import { useLanguage } from "../LanguageContext";
import "./styles/Materials.css";
import Wrapper from "../components/Wrapper";
import Footer from "../components/Footer";
import { axiosInstance } from "../helper/axiosInstance";
import { useAppConfig } from "../hooks/useAppConfig";
import { Link } from "react-router-dom";

function AccordionItem({ faq, isOpen, toggleAccordion }) {
    const { language } = useLanguage();
    const contentRef = useRef(null);
    const [contentHeight, setContentHeight] = useState(0);

    const dynamicStyles = {
        fontFamily: language === "ARM" && "Mardoto",
        fontWeight: language === "ARM" && 500,
    };

    useEffect(() => {
        if (contentRef.current) {
            setContentHeight(contentRef.current.scrollHeight);
        }
    }, [isOpen]);

    const screenWidth = window.innerWidth;
    const dynamicHeight =
        screenWidth < 1920
            ? screenWidth > 576
                ? `${(contentHeight / 1920) * 100 + 2}vw`
                : `${(contentHeight / 412) * 100 + 2}vw`
            : `${contentHeight}px`;

    return (
        <div className="materials_faq-accordion-item">
            <div className="materials_faq-accordion-header" onClick={toggleAccordion}>
                <span style={dynamicStyles}>{faq.question}</span>
                <svg className="materials_faq-accordion-icon" width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0" y="9" width="20" height="2" fill="black" />
                    <rect className={`materials_faq-accordion-rect ${isOpen ? "rotate" : ""}`} x="9" y="0" width="2" height="20" fill="black" />
                </svg>
            </div>
            <div
                className="materials_faq-accordion-content"
                ref={contentRef}
                style={{
                    maxHeight: isOpen ? dynamicHeight : "0",
                    marginBottom: isOpen ? "30px" : "0",
                    overflow: "hidden",
                    transition: "max-height 0.3s ease, margin-bottom 0.3s ease",
                }}
            >
                <p style={dynamicStyles}>{faq.answer}</p>
            </div>
        </div>
    );
}
function Materials() {
    const { language, tipsData, faqData } = useLanguage();
    const config = useAppConfig();

    const dynamicStyles = {
        fontFamily: language === "ARM" && "Mardoto",
        fontWeight: language === "ARM" && 500,
    };

    const [openIndexes, setOpenIndexes] = useState([]);
    const [materialsData, setMaterialsData] = useState([]);

    const toggleAccordion = (index) => {
        setOpenIndexes(
            (prevIndexes) =>
                prevIndexes.includes(index)
                    ? prevIndexes.filter((i) => i !== index) // Close if already open
                    : [...prevIndexes, index] // Open if not already open
        );
    };

    useEffect(() => {
        const lang = language === "ARM" ? "hy" : language === "ENG" ? "en" : "ru";

        axiosInstance.get("/materials/lang?lang=" + lang).then((response) =>
            setMaterialsData(
                response.data.map((item) => ({
                    ...item,
                    image: config.data.API_STATIC_URL + item.image,
                }))
            )
        );
    }, [language]);

    // const tipsData = [
    //     {
    //         id: 1,
    //         title: "MONITOR TRANSACTIONS",
    //         description:
    //             "Always monitor your transaction history for unauthorized activity. Regularly reviewing your transactions helps you quickly identify any unusual or suspicious charges. Prompt action can prevent potential financial losses and ensure your account remains secure.",
    //         tipNumber: "Tip 1",
    //     },
    //     {
    //         id: 2,
    //         title: "USE SECURE PLATFORMS",
    //         description:
    //             "Use secure and trusted payment platforms for online purchases. Ensure the website has proper security measures, such as SSL encryption, indicated by 'https' in the URL. Always verify the platform’s reputation and reviews before entering your payment details.",
    //         tipNumber: "Tip 2",
    //     },
    //     {
    //         id: 3,
    //         title: "CONTROL SPENDING",
    //         description:
    //             "Set spending limits and alerts to stay in control of your finances. This helps you monitor budget and avoid overspending by receiving instant notifications for any transactions. Adjust limits as needed to match your financial goals and ensure you’re always aware of spending habits.",
    //         tipNumber: "Tip 3",
    //     },
    // ];

    // const faqData = [
    //     {
    //         id: 1,
    //         question: "How do I activate my Arca card?",
    //         answer: "You can activate your Arca card by calling our customer support hotline or using your bank's mobile application. Follow the instructions provided, and your card will be ready for use in minutes.",
    //     },
    //     {
    //         id: 2,
    //         question: "What should I do if my card is lost or stolen?",
    //         answer: "If your card is lost or stolen, contact our support team immediately to block the card and prevent unauthorized transactions. You can also request a replacement card through your issuing bank.",
    //     },
    //     {
    //         id: 3,
    //         question: "Why was my transaction declined?",
    //         answer: "Transactions may be declined for several reasons, including insufficient funds, exceeding your daily transaction limit, or suspected fraudulent activity. Check your card balance and transaction settings, or contact support for assistance.",
    //     },
    //     {
    //         id: 4,
    //         question: "Can I use my card for international transactions?",
    //         answer: "Yes, Arca cards are accepted globally wherever the associated payment network is supported. Be sure to enable international transactions through your bank's app or contact them before traveling.",
    //     },
    //     {
    //         id: 5,
    //         question: "How do I dispute an unauthorized transaction?",
    //         answer: "If you notice an unauthorized transaction, report it to your bank immediately to ensure quick action. They will guide you through the dispute process and thoroughly investigate the issue.",
    //     },
    //     {
    //         id: 6,
    //         question: "How do I update my contact information linked to my card?",
    //         answer: "To update your contact information, log in to your bank's online portal or visit a branch office. Keeping your details up to date ensures you receive important notifications about your card.",
    //     },
    // ];

    const oddFaqData = faqData[language].filter((faq, index) => index % 2 !== 0);
    const evenFaqData = faqData[language].filter((faq, index) => index % 2 === 0);

    return (
        <Wrapper>
            <div className="materials">
                {/* cards */}
                <div className="materials_cards">
                    <div className="materials_cards-header">
                        <span style={dynamicStyles}>Recommendations for common issues </span>
                        <div className="materials_cards-header_icons">
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M0.0164407 3.82196L0 12L3.99046 12L3.99046 3.99715C4.00629 3.99715 4.9794 4.00325 4.9794 4.0002L12 4.0002L12 -5.24537e-07L3.83761 -1.67747e-07C1.73064 -7.56487e-08 0.0219223 1.70984 0.0176598 3.82196"
                                    fill="#243BE2"
                                />
                            </svg>
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M8.17804 11.9836L0 12L0 8.00954L8.00285 8.00954C8.00285 7.99371 7.99675 7.0206 7.9998 7.0206L7.9998 0H12L12 8.16239C12 10.2694 10.2902 11.9781 8.17804 11.9823"
                                    fill="#ADF233"
                                />
                            </svg>
                        </div>
                    </div>
                    <div className="materials_cards-title">
                        <h1 style={dynamicStyles}>Useful materials</h1>
                    </div>

                    <div className="materials_cards-container">
                        {materialsData.map((material) => (
                            <Link to={`/materials/${material.id}`} className="newsPage-card">
                                <div key={material.id} className="materials_card">
                                    <img src={material.image} alt={material.title} />
                                    <div className="materials_card-description">
                                        <h3 style={dynamicStyles}>{material.title}</h3>
                                        <p style={dynamicStyles} dangerouslySetInnerHTML={{ __html: material.description }}></p>
                                    </div>
                                </div>
                            </Link>
                        ))}
                    </div>
                </div>

                {/* tips */}
                <div className="materials_tips">
                    <div className="materials_cards-header">
                        <span style={dynamicStyles}>Essential advice for safe card use </span>
                        <div className="materials_cards-header_icons">
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M0.0164407 3.82196L0 12L3.99046 12L3.99046 3.99715C4.00629 3.99715 4.9794 4.00325 4.9794 4.0002L12 4.0002L12 -5.24537e-07L3.83761 -1.67747e-07C1.73064 -7.56487e-08 0.0219223 1.70984 0.0176598 3.82196"
                                    fill="#243BE2"
                                />
                            </svg>
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M8.17804 11.9836L0 12L0 8.00954L8.00285 8.00954C8.00285 7.99371 7.99675 7.0206 7.9998 7.0206L7.9998 0H12L12 8.16239C12 10.2694 10.2902 11.9781 8.17804 11.9823"
                                    fill="#ADF233"
                                />
                            </svg>
                        </div>
                    </div>
                    <div className="materials_cards-title">
                        <h1 style={dynamicStyles}>Tips for cardholders</h1>
                    </div>

                    <div className="materials_tips-container">
                        {tipsData[language].map((tip) => (
                            <div key={tip.id} className="materials_tip-card">
                                <div className="materials_tip-card-head">
                                    <h3 style={dynamicStyles}>{tip.title}</h3>
                                    <span style={dynamicStyles} className="materials_tip-number">
                                        {tip.tipNumber}
                                    </span>
                                </div>
                                <p style={dynamicStyles}>{tip.description}</p>
                            </div>
                        ))}
                    </div>
                </div>

                {/* faq */}
                <div className="materials_faq">
                    <div className="materials_cards-header">
                        <span style={dynamicStyles}>FAQs</span>
                        <div className="materials_cards-header_icons">
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M0.0164407 3.82196L0 12L3.99046 12L3.99046 3.99715C4.00629 3.99715 4.9794 4.00325 4.9794 4.0002L12 4.0002L12 -5.24537e-07L3.83761 -1.67747e-07C1.73064 -7.56487e-08 0.0219223 1.70984 0.0176598 3.82196"
                                    fill="#243BE2"
                                />
                            </svg>
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M8.17804 11.9836L0 12L0 8.00954L8.00285 8.00954C8.00285 7.99371 7.99675 7.0206 7.9998 7.0206L7.9998 0H12L12 8.16239C12 10.2694 10.2902 11.9781 8.17804 11.9823"
                                    fill="#ADF233"
                                />
                            </svg>
                        </div>
                    </div>
                    <div className="materials_cards-title">
                        <h1 style={dynamicStyles}>Frequently asked questions</h1>
                    </div>

                    <div className="materials_faq-container">
                        {/* Odd FAQ Accordion */}
                        <div className="materials_faq-accordion">
                            {oddFaqData.map((faq, index) => (
                                <AccordionItem
                                    key={faq.id}
                                    faq={faq}
                                    isOpen={openIndexes.includes(`odd-${index}`)}
                                    toggleAccordion={() => toggleAccordion(`odd-${index}`)}
                                />
                            ))}
                        </div>

                        {/* Even FAQ Accordion */}
                        <div className="materials_faq-accordion">
                            {evenFaqData.map((faq, index) => (
                                <AccordionItem
                                    key={faq.id}
                                    faq={faq}
                                    isOpen={openIndexes.includes(`even-${index}`)}
                                    toggleAccordion={() => toggleAccordion(`even-${index}`)}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </Wrapper>
    );
}

export default Materials;
