import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { useLanguage } from "../LanguageContext";
import "./Navbar.css";

const Navbar = () => {
    const { language, setLanguage, textContent } = useLanguage();
    const [isLangOpen, setIsLangOpen] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [showLogo, setShowLogo] = useState(true);

    const languages = ["ENG", "ARM"].filter((lang) => lang !== language);

    const handleLanguageChange = (lang) => {
        setLanguage(lang); // Update language in context
        setIsLangOpen(false); // Close dropdown
    };

    const dynamicStyles = {
        fontFamily: language == "ARM" && "Mardoto",
        fontWeight: language == "ARM" && 500,
    };

    const dynamicStylesDropdownOnly = {
        fontFamily: "Mardoto",
        fontWeight: 500,
    };

    // useEffect(() => {
    //     let lastScrollY = window.scrollY;

    //     const handleScroll = () => {
    //         if (window.scrollY > lastScrollY + 10) {
    //             setShowLogo(false); // Hide logo on scroll down
    //         } else if (window.scrollY < lastScrollY - 10) {
    //             setShowLogo(true); // Show logo on scroll up
    //         }
    //         lastScrollY = window.scrollY;
    //     };

    //     window.addEventListener("scroll", handleScroll);

    //     return () => {
    //         window.removeEventListener("scroll", handleScroll);
    //     };
    // }, []);

    return (
        <nav className="navbar">
            {/* Logo */}
            <div className={`navbar-logo ${showLogo ? "visible" : "hidden"}`}>
                <Link to="/" onClick={() => setIsMenuOpen(false)}>
                    <svg width="119" height="30" viewBox="0 0 119 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M0.956505 0.0987031V9.74558H18.7238C15.5803 10.0658 14.6703 10.2579 11.927 10.9224C11.6868 10.9811 9.7948 11.5309 9.5653 11.6029C-0.61261 14.8719 0.0171841 20.7214 0.0038413 22.0023C-0.00683297 23.1231 1.16735 29.8105 8.7274 30C12.909 29.8372 14.8784 27.3261 15.8231 25.7169C12.9891 27.2674 10.2832 25.4207 9.50393 23.8223C7.79338 20.3211 10.3259 16.7452 13.6269 14.6371C17.4723 12.1766 20.2182 12.1393 20.2449 12.1393V29.1114H29.8918V9.37731C29.8918 4.28569 25.7688 0.152084 20.6745 0.141409L0.951195 0.101374L0.956505 0.0987031Z"
                            fill="#243BE2"
                        />
                        <path
                            d="M89.2114 0.0987031V9.74558H106.979C103.835 10.0658 102.925 10.2579 100.182 10.9224C99.9417 10.9811 98.0497 11.5309 97.8202 11.6029C87.6423 14.8719 88.2721 20.7214 88.2614 22.0023C88.2507 23.1231 89.4249 29.8105 96.985 30C101.167 29.8372 103.136 27.3261 104.081 25.7169C101.249 27.2674 98.5407 25.4207 97.7615 23.8223C96.0509 20.3211 98.5834 16.7452 101.884 14.6371C105.73 12.1766 108.476 12.1393 108.502 12.1393V29.1114H118.149V9.37731C118.149 4.28569 114.026 0.152084 108.932 0.141409L89.2088 0.101374L89.2114 0.0987031Z"
                            fill="#ADF233"
                        />
                        <path
                            d="M47.4136 2.93806C45.9939 4.83274 45.132 7.73347 44.8304 11.6429H44.5075V0.0987031H34.842V28.9779H44.5075V17.2576C44.5075 12.681 46.7438 9.65484 51.219 9.65484H59.0886V0.0987031H53.1537C50.744 0.0987031 48.8306 1.04605 47.4109 2.93806"
                            fill="#243BE2"
                        />
                        <path
                            d="M70.4513 14.5197C70.4513 17.2043 72.6288 19.3818 75.3134 19.3818H84.8749V29.0367H75.0759C67.1903 29.0367 60.7964 22.6428 60.7964 14.7572V14.2795C60.7964 6.39389 67.1903 0 75.0759 0H84.8749V9.65487H75.3134C72.6288 9.65487 70.4513 11.8324 70.4513 14.517"
                            fill="#243BE2"
                        />
                    </svg>
                </Link>
            </div>

            {/* Navigation Links */}
            <div className="navbar-links desktop-navbar">
                <NavLink to="/about" style={dynamicStyles} className={({ isActive }) => (isActive ? "nav-link active-link" : "nav-link")}>
                    {textContent[language].about}
                </NavLink>
                <NavLink to="/services" style={dynamicStyles} className={({ isActive }) => (isActive ? "nav-link active-link" : "nav-link")}>
                    {textContent[language].services}
                </NavLink>
                <NavLink to="/news" style={dynamicStyles} className={({ isActive }) => (isActive ? "nav-link active-link" : "nav-link")}>
                    {textContent[language].news}
                </NavLink>
                {/* <NavLink to="/reports" style={dynamicStyles} className={({ isActive }) => (isActive ? "nav-link active-link" : "nav-link")}>
                    {textContent[language].reports}
                </NavLink>
                <NavLink to="/careers" style={dynamicStyles} className={({ isActive }) => (isActive ? "nav-link active-link" : "nav-link")}>
                    {textContent[language].careers}
                </NavLink> */}
                <NavLink to="/contacts" style={dynamicStyles} className={({ isActive }) => (isActive ? "nav-link active-link" : "nav-link")}>
                    {textContent[language].contacts}
                </NavLink>
            </div>

            {/* Language Selector */}
            <div className="navbar-lang desktop-navbar">
                <div className={`lang-button ${isLangOpen ? "open" : ""}`} onClick={() => setIsLangOpen((prev) => !prev)}>
                    <span style={dynamicStyles}>{language == "ARM" ? "ՀԱՅ" : language}</span>
                </div>
                <div className={`lang-dropdown ${isLangOpen ? "open" : ""}`} style={{ maxHeight: isLangOpen ? `${languages.length * 50}px` : "0" }}>
                    {languages.map((lang) => (
                        <div key={lang} className="lang-option" onClick={() => handleLanguageChange(lang)}>
                            <span style={lang === "ARM" ? dynamicStylesDropdownOnly : {}}>{lang == "ARM" ? "ՀԱՅ" : lang}</span>
                        </div>
                    ))}
                </div>
            </div>

            {/* Hamburger Menu */}
            <button className="menu-toggle" onClick={() => setIsMenuOpen(!isMenuOpen)}>
                <svg
                    width="25"
                    height="19"
                    viewBox="0 0 25 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={`menu-icon ${isMenuOpen ? "open" : ""}`} // Add dynamic class for open state
                >
                    <rect className="line line1" y="4" width="25" height="1" fill="black" />
                    <rect className="line line2" y="15" width="25" height="1" fill="black" />
                </svg>
            </button>

            {/* Mobile Menu */}
            <div className={`mobile-menu ${isMenuOpen ? "open" : ""}`}>
                <div className="mobile-menu_container">
                    {/* Navigation Links */}
                    <div className="navbar-links_mobile">
                        <NavLink
                            to="/about"
                            className={({ isActive }) => (isActive ? "nav-link-mobile active-link-mobile" : "nav-link-mobile")}
                            onClick={() => setIsMenuOpen(false)}
                            style={dynamicStyles}
                        >
                            {textContent[language].about}{" "}
                            <span>
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M7 14L14 7M14 7L7 -3.0598e-07M14 7L-3.0598e-07 7"
                                        stroke="white"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </span>
                        </NavLink>
                        <NavLink
                            to="/services"
                            className={({ isActive }) => (isActive ? "nav-link-mobile active-link-mobile" : "nav-link-mobile")}
                            onClick={() => setIsMenuOpen(false)}
                            style={dynamicStyles}
                        >
                            {textContent[language].services}{" "}
                            <span>
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M7 14L14 7M14 7L7 -3.0598e-07M14 7L-3.0598e-07 7"
                                        stroke="white"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </span>
                        </NavLink>
                        <NavLink
                            to="/news"
                            className={({ isActive }) => (isActive ? "nav-link-mobile active-link-mobile" : "nav-link-mobile")}
                            onClick={() => setIsMenuOpen(false)}
                            style={dynamicStyles}
                        >
                            {textContent[language].news}{" "}
                            <span>
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M7 14L14 7M14 7L7 -3.0598e-07M14 7L-3.0598e-07 7"
                                        stroke="white"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </span>
                        </NavLink>
                        {/* <NavLink
                            to="/reports"
                            className={({ isActive }) => (isActive ? "nav-link-mobile active-link-mobile" : "nav-link-mobile")}
                            onClick={() => setIsMenuOpen(false)}
                            style={dynamicStyles}
                        >
                            {textContent[language].reports}{" "}
                            <span>
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M7 14L14 7M14 7L7 -3.0598e-07M14 7L-3.0598e-07 7"
                                        stroke="white"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </span>
                        </NavLink>
                        <NavLink
                            to="/careers"
                            className={({ isActive }) => (isActive ? "nav-link-mobile active-link-mobile" : "nav-link-mobile")}
                            onClick={() => setIsMenuOpen(false)}
                            style={dynamicStyles}
                        >
                            {textContent[language].careers}{" "}
                            <span>
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M7 14L14 7M14 7L7 -3.0598e-07M14 7L-3.0598e-07 7"
                                        stroke="white"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </span>
                        </NavLink> */}
                        <NavLink
                            to="/contacts"
                            className={({ isActive }) => (isActive ? "nav-link-mobile active-link-mobile" : "nav-link-mobile")}
                            onClick={() => setIsMenuOpen(false)}
                            style={dynamicStyles}
                        >
                            {textContent[language].contacts}{" "}
                            <span>
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M7 14L14 7M14 7L7 -3.0598e-07M14 7L-3.0598e-07 7"
                                        stroke="white"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </span>
                        </NavLink>
                        {/* <NavLink to="/legal-acts" className={({ isActive }) => (isActive ? "nav-link-mobile active-link-mobile" : "nav-link-mobile")} onClick={() => setIsMenuOpen(false)}>
                            {textContent[language].legalActs}{" "}
                            <span>
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M7 14L14 7M14 7L7 -3.0598e-07M14 7L-3.0598e-07 7"
                                        stroke="white"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </span>
                        </NavLink> */}
                    </div>
                    {/* mobile footer */}
                    <div className="navbar-footer">
                        {/* Language Selector */}
                        <div className="navbar-lang-mobile">
                            <div className={`lang-button-mobile ${isLangOpen ? "open" : ""}`} onClick={() => setIsLangOpen((prev) => !prev)}>
                                <span style={dynamicStyles}>{language == "ARM" ? "ՀԱՅ" : language}</span>
                            </div>
                            <div style={dynamicStyles} className={`lang-dropdown-mobile ${isLangOpen ? "open" : ""}`}>
                                {languages.map((lang) => (
                                    <span
                                        key={lang}
                                        className="lang-option-mobile"
                                        onClick={() => handleLanguageChange(lang)}
                                        style={lang === "ARM" ? dynamicStylesDropdownOnly : {}}
                                    >
                                        {lang == "ARM" ? "ՀԱՅ" : lang}
                                    </span>
                                ))}
                            </div>
                        </div>

                        {/* Contact Info */}
                        <a className="navbar-footer_contactinfo" href="mailto:info@arca.am">
                            info@arca.am
                        </a>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
