import React from "react";
import { Link } from "react-router-dom";
import "./LinkCard.css";
import { useLanguage } from "../LanguageContext";

const LinkCard = ({ linkPath, title, titleText, description, bgImg }) => {
    const { language } = useLanguage();

    const dynamicStyles = {
        fontFamily: language === "ARM" && "Mardoto",
        fontWeight: language === "ARM" && 500,
    };

    return (
        <Link className="link-card" to={linkPath} style={{ backgroundImage: `url(${bgImg})` }}>
            <div className="link-card-content">
                <h3 style={dynamicStyles} className="link-card-title">
                    {title}
                </h3>
                <p style={dynamicStyles} className="link-card-text">
                    {titleText}
                </p>
                <div className="link-card_footer">
                    <p style={dynamicStyles} className="link-card-description">
                        {description}
                    </p>
                    <Link to={linkPath} className="link-card-link">
                        <button className="link-card-arrow">
                            <div className="link-card-svg_wrapper">
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9 17L17 9M17 9L9 1M17 9L0.999999 9" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                            <div className="link-card-svg_wrapper">
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9 17L17 9M17 9L9 1M17 9L0.999999 9" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                        </button>
                    </Link>
                </div>
            </div>
        </Link>
    );
};

export default LinkCard;
