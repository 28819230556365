import React, { createContext, useContext, useEffect, useState } from "react";
import { axiosInstance } from "./helper/axiosInstance";

// Create the Language Context
const LanguageContext = createContext();

// Custom hook to use the language context
export const useLanguage = () => useContext(LanguageContext);

export const LanguageProvider = ({ children, data }, ) => {
    // const [language, setLanguage] = useState("ENG");

    const [language, setLanguage] = useState(() => {
        const savedLanguage = localStorage.getItem("selectedLanguage");
        return savedLanguage ? savedLanguage : "ARM";
    });

    const {textContent, numberHeader, numberText, footerText, paymentText, 
        comprehensiveText, heroText, overviewText, timelineText, purposeText, 
        shareholderText, teamDescription, servicesPaymentText, specializedText, 
        linkCardsContent, missionVissionNumbers, contactsText, reportsText, financialReports, 
        legalText, legalDocs, briefCareersText, vacanciesText, newsText, tendersText, newsDetailsText, 
        vacancyCardText, homeNewsText, structureText, images, tipsData, faqData} = data

    const dynamicStyles = {
        fontFamily: language === "ARM" && "Mardoto",
        fontWeight: language === "ARM" && 500,
    };

    
    
    // Text content for each language
    // const textContent = {
    //     ENG: {
    //         about: "About",
    //         services: "Services",
    //         news: "News",
    //         careers: "Careers",
    //         contacts: "Contacts",
    //         home: "Home",
    //         documents: "Documents",
    //         reports: "Reports",
    //         tenders: "Tenders",
    //         materials: "Useful materials",
    //         links: "Quick links",
    //         paymentInnovations: <>National Payment System</>,
    //         paymentInnovationsMobile: (
    //             <>
    //                 National <br /> Payment <br /> System
    //             </>
    //         ),
    //     },
    //     ARM: {
    //         about: "Մեր մասին",
    //         services: "Ծառայություններ",
    //         news: "Նորություններ",
    //         careers: "Կարիերա",
    //         contacts: "Կապ",
    //         home: "Գլխավոր",
    //         reports: "Հաշվետվություններ",
    //         documents: "Փաստաթղթեր",
    //         tenders: "Մրցույթներ",
    //         materials: "Օգտակար նյութեր",
    //         links: "Արագ հղումներ",
    //         paymentInnovations: <>Ազգային Վճարային Համակարգ</>,
    //         paymentInnovationsMobile: (
    //             <>
    //                 Ազգային <br /> Վճարային <br /> Համակարգ
    //             </>
    //         ),
    //     },
    //     RUS: {
    //         about: "О нас",
    //         services: "Услуги",
    //         news: "Новости",
    //         careers: "Карьера",
    //         contacts: "Контакты",
    //         home: "Главная",
    //         reports: "Reports",
    //         documents: "Documents",
    //         tenders: "Tenders",
    //         materials: "Useful materials",
    //         links: "Quick links",
    //         paymentInnovations: <>Национальная Платежная Система</>,
    //         paymentInnovationsMobile: (
    //             <>
    //                 Национальная <br /> Платежная <br /> Система
    //             </>
    //         ),
    //     },
    // };

    // const numberHeader = {
    //     ENG: {
    //         title: "Arca provides payment solutions to financial institutions, businesses, and cardholders",
    //     },
    //     ARM: {
    //         title: "Arca-ն տրամադրում է վճարային լուծումներ ֆինանսական կազմակերպություններին, տնտեսվարողներին և քարտապաններին",
    //     },
    //     RUS: { title: "Arca обеспечивает быстрые и безопасные транзакции, предлагая финансовые решения для предприятий и пользователей" },
    // };

    // const numberText = {
    //     ENG: [
    //         { number: "100", text: "Businesses use service provided by Arca" },
    //         { number: "4+", text: "Transactions with over 4 trillion AMD are processed annually" },
    //         { number: "3+", text: "Over 3 million cards are serviced by Arca daily" },
    //     ],
    //     ARM: [
    //         { number: "100", text: "100 հազար տնտեսվարող օգտվում է Arca-ի ծառայություններից" },
    //         { number: "4+", text: "4+ տրիլիոն ՀՀ դրամ գործարք մշակվում է տարեկան" },
    //         { number: "3+", text: "3+ միլիոն քարտ ամեն օր սպասարկվում է Arca-ի կողմից" },
    //     ],
    //     RUS: [
    //         { number: "100", text: "Банкоматы по всей Армении для клиентов Arca." },
    //         { number: "4+", text: "Миллион транзакций, обработанных ежегодно." },
    //         { number: "3+", text: "Миллион карт, выпущенных Arca." },
    //     ],
    // };

    // const paymentText = {
    //     ENG: [
    //         {
    //             paymentTitle: "Independent payment",
    //             paymentText: "Arca: fast, secure, national payment system",
    //             paymentFooter:
    //                 "The company offers a service package that enables participating financial institutions to issue and service cards of the Arca national payment system, as well as international payment systems such as MasterCard, Visa, American Express, DCI, UPI, and JCB, utilizing the company's modern technical, technological, and software capabilities, as well as professional support.",
    //         },
    //         {
    //             about: "About us",
    //             aboutText: "Armenian Card CJSC was established in March 2000 by Central Bank of Republic of Armenia and Armenian commercial banks",
    //             mission: "Mission",
    //             missionText: "Ensuring interoperability in the payment market of Armenia",
    //             achivement: "Vision",
    //             achivementText: "An innovative and reliable national payment system",
    //             benefit: "Values",
    //             benefitText: "We build trust by offering innovative, secure solutions focused on partner needs",
    //         },
    //     ],
    //     ARM: [
    //         {
    //             paymentTitle: "Ազգային Վճարային Համակարգ",
    //             paymentText: "Arca՝ արագ, անվտանգ, ազգային վճարային համակարգ",
    //             paymentFooter:
    //                 "Arca-ն հնարավորություն է տալիս ֆինանսական կազմակերպություններին թողարկել և սպասարկել Arca ազգային վճարային համակարգի և միջազգային վճարային MasterCard, Visa, American Express, DCI, UPI և JCB համակարգերի քարտեր՝ օգտագործելով ժամանակակից տեխնոլոգիաներ և մասնագիտական աջակցություն",
    //         },
    //         {
    //             about: "Մեր մասին",
    //             aboutText: "Արմենիան Քարդը ստեղծվել է 2000 թ․-ին ՀՀ կենտրոնական բանկի և տասը առևտրային բանկերի կողմից",
    //             mission: "Տեսլական ",
    //             missionText: "Նորարար և հուսալի ազգային վճարային համակարգ",
    //             achivement: "Առաքելություն",
    //             achivementText: "Հայաստանի վճարային շուկայում փոխգործելիության ապահովում",
    //             benefit: "Արժեքներ",
    //             benefitText: "Մենք կառուցում ենք վստահություն՝ առաջարկելով նորարար ու անվտանգ լուծումներ՝ կենտրոնանալով գործընկերների կարիքների վրա",
    //         },
    //     ],
    //     RUS: [
    //         {
    //             paymentTitle: "Independent payment",
    //             paymentText: "Arca: fast, secure, national payment system",
    //             paymentFooter:
    //                 "The company offers a service package that enables participating financial institutions to issue and service cards of the Arca national payment system, as well as international payment systems such as MasterCard, Visa, American Express, DCI, UPI, and JCB, utilizing the company's modern technical, technological, and software capabilities, as well as professional support.",
    //         },
    //         {
    //             about: "About us",
    //             aboutText: "Armenian Card CJSC was established in March 2000 by Central Bank of Republic of Armenia and Armenian commercial banks",
    //             mission: "Mission",
    //             missonText:
    //                 "established in March 2000 by Central Bank of Republic of Armenia and Armenian commercial banks.Our technical and software solutions allow commercial banks to produce and provide Arca National payments system cards.From card payments to mobile wallets, Arca offers diverse, innovative payment methods to suit various needs.Develop and implement in the banking market of Armenia all benefits that modern payment and development technologies can offer.",
    //             achivement: "Achivements",
    //             achivementText:
    //                 "Armenian Card CJSC was established in March 2000 by Central Bank of Republic of Armenia and Armenian commercial banks.Our technical and software solutions allow commercial banks to produce and provide Arca National payments system cards.From card payments to mobile wallets, Arca offers diverse, innovative payment methods to suit various needs.",
    //             benefit: "Benefits",
    //             benefitText:
    //                 "Armenian Card CJSC was established in March 2000 by Central Bank of Republic of Armenia and Armenian commercial banks.Our technical and software solutions allow commercial banks to produce and provide Arca National payments system cards.From card payments to mobile wallets, Arca offers diverse, innovative payment methods to suit various needs.Develop and implement in the banking market of Armenia all benefits that modern payment and development technologies can offer.",
    //         },
    //     ],
    // };

    // const comprehensiveText = {
    //     ENG: [
    //         { comprehensiveText: "Comprehensive", comprehensiveTitle: "Arca services – at all stages of your payment infrastructure" },
    //         {
    //             serviceTitle: "Our services",
    //             arcaPay: "National payment system",
    //             arcaPayText: "We provide member banks with the opportunity to issue and service local Arca cards using contactless technology.",
    //             transaction: "Processing Center",
    //             transactionText:
    //                 "We offer a wide range of services, including transaction authorization, processing, and settlement. We also provide ATM, POS, and VPOS servicing, fraud monitoring and prevention, 3DS authentication, and card personalization.",
    //             monitoring: "National SWITCH",
    //             monitoringText: "We ensure interoperability between Armenian banks through host-to-host technology.",
    //             readMore: "read more",
    //         },
    //     ],
    //     ARM: [
    //         { comprehensiveText: "Բազմակողմանի", comprehensiveTitle: "Arca ծառայություններ՝ ձեր վճարային ենթակառուցվածքի բոլոր փուլերում" },
    //         {
    //             serviceTitle: "Մեր ծառայությունները",
    //             arcaPay: "Ազգային վճարային համակարգ",
    //             arcaPayText:
    //                 "Համակարգի անդամ բանկերին հնարավորություն ենք տալիս թողարկել և սպասարկել տեղական Arca քարտերը՝ օգտագործելով անհպում տեխնոլոգիաները։ ",
    //             transaction: "Պրոցեսինգային կենտրոն",
    //             transactionText:
    //                 "Առաջարկում ենք ծառայությունների լայն շրջանակ, որը ներառում է գործարքների հավաստագրում, պրոցեսինգ և փոխհաշվարկ։ Իրականացնում ենք նաև ATM, POS, VPOS սպասարկում, զեղծարարությունների մշտադիտարկում և կանխարգելում, 3DS նույնականացում, քարտերի անհատականացում։",
    //             monitoring: "Ազգային SWITCH",
    //             monitoringText: "Host-to-host տեխնոլոգիայի միջոցով ապահովում ենք փոխգործելիություն հայկական բանկերի միջև։",
    //             readMore: "կարդալ ավելին",
    //         },
    //     ],
    //     RUS: [
    //         { comprehensiveText: "Comprehensive", comprehensiveTitle: "Arca services – at all stages of your payment infrastructure" },
    //         {
    //             serviceTitle: "Our services",
    //             arcaPay: "National payment system",
    //             arcaPayText: "We provide member banks with the opportunity to issue and service local Arca cards using contactless technology",
    //             transaction: "Processing Center",
    //             transactionText:
    //                 "We offer a wide range of services, including transaction authorization, processing, and settlement. We also provide ATM, POS, and VPOS servicing, fraud monitoring and prevention, 3DS authentication, and card personalization.",
    //             monitoring: "National SWITCH",
    //             monitoringText: "We ensure interoperability between Armenian banks through host-to-host technology",
    //             readMore: "read more",
    //         },
    //     ],
    // };

    // // ABOUT PAGE'S TEXT

    // const heroText = {
    //     ENG: {
    //         about: "About",
    //         aboutMobile: "About",
    //         services: "Services",
    //         servicesMobile: "Services",
    //     },
    //     ARM: {
    //         about: "Մեր մասին",
    //         aboutMobile: (
    //             <>
    //                 Մեր <br /> մասին
    //             </>
    //         ),
    //         services: "Ծառայություններ",
    //         servicesMobile: "Ծառայություններ",
    //     },

    //     RUS: {
    //         about: "About",
    //         aboutMobile: "About",
    //         services: "Services",
    //         servicesMobile: "Services",
    //     },
    // };

    // const overviewText = {
    //     ENG: [
    //         {
    //             overviewTitle: "Brief overview",
    //             overviewText: "Arca: Key facts about the success",
    //             card1Title: "Clients",
    //             card1Number: "3 mln",
    //             card1Text: "Providing safe and reliable payment services to over 3 million cardholders",
    //             card2Title: "100K businesses",
    //             card2Number: "",
    //             card2Text: "Trusted by over 100,000 businesses for seamless financial operations",
    //             card3Title: "2.2 billion processed inquiries",
    //             card3Number: "",
    //             card3Text: "Processing 2.2 billion payment inquiries with accuracy and efficiency",
    //             card4Title: "Successful transaction",
    //             card4Number: "4T",
    //             card4Text: "Facilitating transactions of unprecedented scale, exceeding 4 trillion AMD annually",
    //         },
    //     ],
    //     ARM: [
    //         {
    //             overviewTitle: "Համառոտ նկարագրություն",
    //             overviewText: "Arca-ն հաջողության մասին խոսուն թվերով",
    //             card1Title: "Քարտապաններ",
    //             card1Number: "3 մլն․",
    //             card1Text: "Ապահովելով ավելի քան 3 միլիոն քարտապանների անվտանգ և հուսալի վճարային ծառայություններով",
    //             card2Title: "100 հազար տնտեսվարող",
    //             card2Text: "Վստահված ավելի քան 100,000 բիզնեսների կողմից՝ անխափան ֆինանսական գործողությունների համար",
    //             card3Title: "2.2 միլիարդ մշակված հարցում",
    //             card3Text: "Մշակելով 2.2 միլիարդ վճարային հարցումներ՝ ճշգրտությամբ և արդյունավետությամբ",
    //             card4Title: "Գործարքներ",
    //             card4Number: "4 տրլն.",
    //             card4Text: "Նպաստելով աննախադեպ մասշտաբների գործարքների՝ գերազանցելով տարեկան 4 տրիլիոն ՀՀ դրամը",
    //         },
    //     ],
    //     RUS: [
    //         {
    //             overviewTitle: "Brief overview",
    //             overviewText: "Arca: Key facts about the success",
    //             card1Title: "Clients",
    //             card1Number: "1 mln",
    //             card1Text:
    //                 "Arca has built a strong reputation and earned the trust of over 1 mln clients, ranging from small businesses to large enterprises.",
    //             card2Title: "Empowering Innovation",
    //             card2Number: "",
    //             card2Text: "Arca's team has grown to over employees in Armenia. We continue to push the boundaries in every project we undertake.",
    //             card3Title: "Top-tier security standards",
    //             card3Number: "",
    //             card3Text:
    //                 "Arca prioritizes security at every level. We are certified with the highest security standards, PCI DSS and other regulations.",
    //             card4Title: "Payments processed",
    //             card4Number: "500 mln",
    //             card4Text: "Arca has become a trusted choice for seamless transactions, processing over 500 million payments every year.",
    //         },
    //     ],
    // };

    // const timelineText = {
    //     ENG: {
    //         timelineTitle: "From the first transaction to innovation",
    //         smallText: "Our achievements",
    //         afterText:
    //             "Arca began in 2000 with the national payment system and card issuance. Key milestones included international certification, internet banking, and the introduction of contactless cards and digital wallets (Apple Pay, Google Pay). In 2024, Arca launched Arca Pay, enabling instant transfers between customer accounts, setting new financial standards in Armenia.",
    //         beforeText:
    //             '"Armenian Card" CJSC (the Company) was established in March 2000 by the Central Bank of the Republic of Armenia and ten commercial banks with the aim of implementing and developing a modern payment system in the banking sector of the Republic of Armenia.',
    //     },
    //     ARM: {
    //         timelineTitle: "Առաջին գործարքից մինչև նորարարություն",
    //         smallText: "Մեր նվաճումները",
    //         afterText:
    //             "Arca-ն սկսել է 2000 թվականին՝ ազգային վճարային համակարգով և քարտերի թողարկումով: Հիմնական իրադարձությունները ներառում էին միջազգային սերտիֆիկացում, ինտերնետ բանկինգ և անհպում քարտերի և թվային դրամապանակների (Apple Pay, Google Pay) ներդրումը: 2024 թվականին Arca-ն գործարկել է Arca Pay-ը՝ հնարավորություն տալով կատարել ակնթարթային փոխանցումներ:",
    //         beforeText:
    //             '"Armenian Card" CJSC (the Company) was established in March 2000 by the Central Bank of the Republic of Armenia and ten commercial banks with the aim of implementing and developing a modern payment system in the banking sector of the Republic of Armenia.',
    //     },
    //     RUS: {
    //         timelineTitle: "Key milestones in the company over the years",
    //         smallText: "Important moments",
    //         afterText:
    //             "Arca started its journey in 2000 with the creation of the national payment system and card issuance. The company then went through key stages, including international certification, the launch of internet banking, and the introduction of contactless payment cards and digital wallets (Apple Pay, Google Pay). In 2024, Arca introduced the Arca Pay system, setting new standards in the financial services sector in Armenia, enabling the first instant transfers between customer accounts of different financial institutions.",
    //         beforeText:
    //             '"Armenian Card" CJSC (the Company) was established in March 2000 by the Central Bank of the Republic of Armenia and ten commercial banks with the aim of implementing and developing a modern payment system in the banking sector of the Republic of Armenia.',
    //         },
    // };

    // const purposeText = {
    //     ENG: [
    //         {
    //             purposeHeadText1: "Objectives and principles",
    //             purposeHeadText2: "Our mission, vision and values",
    //             purposeMiniTitle1: "Innovative, Reliable, National",
    //             purposeTitle1: "Vision",
    //             purposeText1: "Innovative and reliable payment solutions that support all participants in the national financial ecosystem.",
    //             purposeMiniTitle2: "Interoperability, Sovereignty, National value",
    //             purposeTitle2: "Mission",
    //             purposeText2: "Ensuring interoperability and sovereignty of Armenia’s payment market.",
    //             purposeMiniTitle3: "Innovation, Reliability, Security",
    //             purposeTitle3: "VALUES",
    //             purposeText3:
    //                 "We are committed to building trust with our partners and customers by offering innovative and secure solutions, always focused on meeting the needs of our partners.",
    //         },
    //     ],
    //     ARM: [
    //         {
    //             purposeHeadText1: "Նպատակները և սկզբունքները",
    //             purposeHeadText2: "Մեր առաքելությունը, տեսլականը և արժեքները",
    //             purposeMiniTitle1: "Նորար, Հուսալի, Ազգային",
    //             purposeTitle1: "Տեսլական",
    //             purposeText1: "Նորարարական և հուսալի վճարային լուծումներ, որոնք աջակցում են ազգային ֆինանսական էկոհամակարգի բոլոր մասնակիցներին:",
    //             purposeMiniTitle2: "Փոխգործելիություն, Ինքնիշխանություն, Ազգային արժեք",
    //             purposeTitle2: "Առաքելություն",
    //             purposeText2: "Հայաստանի վճարային շուկայում փոխգործելիության ապահովում, Հայաստանի վճարային շուկայի ինքնիշխանության ապահովում",
    //             purposeMiniTitle3: "Նորարարություն, Հուսալիություն, Անվտանգություն",
    //             purposeTitle3: "Արժեքներ",
    //             purposeText3:
    //                 "Մենք նվիրված ենք մեր գործընկերների և հաճախորդների հետ վստահության կառուցմանը՝ առաջարկելով նորարար և անվտանգ լուծումներ, որոնց թիրախում մշտապես մեր գործընկերների կարիքներն են:",
    //         },
    //     ],
    //     RUS: [
    //         {
    //             purposeHeadText1: "Objectives and principles",
    //             purposeHeadText2: "Our mission, vision and values",
    //             purposeMiniTitle1: "Transforming Transactions",
    //             purposeTitle1: "Vision",
    //             purposeText1:
    //                 "To provide innovative, secure, and accessible financial solutions that simplify and enhance the transaction experience for businesses and individuals, driving a cashless, connected future.",
    //             purposeMiniTitle2: "Integrity, Innovation, Impact",
    //             purposeTitle2: "VISION",
    //             purposeText2:
    //                 "Integrity, innovation, and customer-centricity form the core of our operations. We prioritize transparency, adapt to evolving needs, and consistently aim to exceed expectations in quality and service.",
    //             purposeMiniTitle3: "Leading the Digital Finance Frontier",
    //             purposeTitle3: "VALUES",
    //             purposeText3:
    //                 "To be the leading provider of digital finance solutions in the region, recognized for reliability, user-centered design, and a steadfast commitment to security and trust.",
    //         },
    //     ],
    // };

    // const shareholderText = {
    //     ENG: [
    //         {
    //             shareholderTitle: "Shareholders",
    //             shareholderTitle2: "Arca shareholders",
    //             shareholder1: "CENTRAL BANK ",
    //             description1: "Bank is a shareholder of Arca, owning a part of its shares.",
    //             shareholder2: "ACBA BANK",
    //             description2: "Bank is a shareholder of Arca, owning a part of its shares.",
    //             shareholder3: "AMERIABANK",
    //             description3: "Bank is a shareholder of Arca, owning a part of its shares.",
    //             shareholder4: "AMIO BANK",
    //             description4: "Bank is a shareholder of Arca, owning a part of its shares.",
    //             shareholder5: "ARARATBANK",
    //             description5: "Bank is a shareholder of Arca, owning a part of its shares.",
    //             shareholder6: "Ardshininvestbank",
    //             description6: "Bank is a shareholder of Arca, owning a part of its shares.",
    //             shareholder7: "ARMECONOMBANK",
    //             description7: "Bank is a shareholder of Arca, owning a part of its shares.",
    //             shareholder8: "ARMSWISSBANK",
    //             description8: "Bank is a shareholder of Arca, owning a part of its shares.",
    //             shareholder9: "ARTSAKHBANK",
    //             description9: "Bank is a shareholder of Arca, owning a part of its shares.",
    //             shareholder10: "BYBLOS BANK ARMENIA",
    //             description10: "Bank is a shareholder of Arca, owning a part of its shares.",
    //             shareholder11: "CONVERSE BANK",
    //             description11: "Bank is a shareholder of Arca, owning a part of its shares.",
    //             shareholder12: "EVOCABANK",
    //             description12: "Bank is a shareholder of Arca, owning a part of its shares.",
    //             shareholder13: "FAST BANK",
    //             description13: "Bank is a shareholder of Arca, owning a part of its shares.",
    //             shareholder14: "HSBC BANK",
    //             description14: "Bank is a shareholder of Arca, owning a part of its shares.",
    //             shareholder15: "IDBANK",
    //             description15: "Bank is a shareholder of Arca, owning a part of its shares.",
    //             shareholder16: "UNIBANK",
    //             description16: "Bank is a shareholder of Arca, owning a part of its shares.",
    //             shareholder17: "INECOBANK",
    //             description17: "Bank is a shareholder of Arca, owning a part of its shares.",
    //             shareholder18: "Ardshinbank",
    //             description18: "Bank is a shareholder of Arca, owning a part of its shares.",
    //         },
    //     ],
    //     ARM: [
    //         {
    //             shareholderTitle: "Բաժնետերեր",
    //             shareholderTitle2: "Arca Բաժնետերեր",
    //             shareholder1: "ՀՀ Կենտրոնական Բանկ",
    //             description1: "Բանկը հանդիսանում է Arca-ի բաժնետեր՝ տնօրինելով նրա բաժնետոմսերի մի մասը։",
    //             shareholder2: "ԱԿԲԱ ԲԱՆԿ",
    //             description2: "Բանկը հանդիսանում է Arca-ի բաժնետեր՝ տնօրինելով նրա բաժնետոմսերի մի մասը։",
    //             shareholder3: "ԱՄԵՐԻԱԲԱՆԿ",
    //             description3: "Բանկը հանդիսանում է Arca-ի բաժնետեր՝ տնօրինելով նրա բաժնետոմսերի մի մասը։",
    //             shareholder4: "ԱՄԻՕ ԲԱՆԿ",
    //             description4: "Բանկը հանդիսանում է Arca-ի բաժնետեր՝ տնօրինելով նրա բաժնետոմսերի մի մասը։",
    //             shareholder5: "ԱՐԱՐԱՏԲԱՆԿ",
    //             description5: "Բանկը հանդիսանում է Arca-ի բաժնետեր՝ տնօրինելով նրա բաժնետոմսերի մի մասը։",
    //             shareholder6: "Արդշինինվեստբանկ",
    //             description6: "Բանկը հանդիսանում է Arca-ի բաժնետեր՝ տնօրինելով նրա բաժնետոմսերի մի մասը։",
    //             shareholder7: "ՀԱՅԷԿՈՆՈՄԲԱՆԿ",
    //             description7: "Բանկը հանդիսանում է Arca-ի բաժնետեր՝ տնօրինելով նրա բաժնետոմսերի մի մասը։",
    //             shareholder8: "Արմսվիսբանկ",
    //             description8: "Բանկը հանդիսանում է Arca-ի բաժնետեր՝ տնօրինելով նրա բաժնետոմսերի մի մասը։",
    //             shareholder9: "Արցախբանկ",
    //             description9: "Բանկը հանդիսանում է Arca-ի բաժնետեր՝ տնօրինելով նրա բաժնետոմսերի մի մասը։",
    //             shareholder10: "ԲԻԲԼՈՍ Բանկ Արմենիա",
    //             description10: "Բանկը հանդիսանում է Arca-ի բաժնետեր՝ տնօրինելով նրա բաժնետոմսերի մի մասը։",
    //             shareholder11: "Կոնվերս Բանկ",
    //             description11: "Բանկը հանդիսանում է Arca-ի բաժնետեր՝ տնօրինելով նրա բաժնետոմսերի մի մասը։",
    //             shareholder12: "ԷՎՈԿԱԲԱՆԿ",
    //             description12: "Բանկը հանդիսանում է Arca-ի բաժնետեր՝ տնօրինելով նրա բաժնետոմսերի մի մասը։",
    //             shareholder13: "Ֆասթ Բանկ",
    //             description13: "Բանկը հանդիսանում է Arca-ի բաժնետեր՝ տնօրինելով նրա բաժնետոմսերի մի մասը։",
    //             shareholder14: "Արդշինիվեստբանկ",
    //             description14: "Բանկը հանդիսանում է Arca-ի բաժնետեր՝ տնօրինելով նրա բաժնետոմսերի մի մասը։",
    //             shareholder15: "ԱյԴի Բանկ",
    //             description15: "Բանկը հանդիսանում է Arca-ի բաժնետեր՝ տնօրինելով նրա բաժնետոմսերի մի մասը։",
    //             shareholder16: "Յունիբանկ",
    //             description16: "Բանկը հանդիսանում է Arca-ի բաժնետեր՝ տնօրինելով նրա բաժնետոմսերի մի մասը։",
    //             shareholder17: "Ինեկոբանկ",
    //             description17: "Բանկը հանդիսանում է Arca-ի բաժնետեր՝ տնօրինելով նրա բաժնետոմսերի մի մասը։",
    //             shareholder18: "Արդշինբանկ",
    //             description18: "Բանկը հանդիսանում է Arca-ի բաժնետեր՝ տնօրինելով նրա բաժնետոմսերի մի մասը։",
    //         },
    //     ],
    //     RUS: [
    //         {
    //             shareholderTitle: "Բաժնետերեր",
    //             shareholderTitle2: "Arca shareholders",
    //             shareholder1: (
    //                 <>
    //                     Հայաստանի Հանրապետության <br /> Կենտրոնական Բանկ
    //                 </>
    //             ),
    //             description1: "Բանկը հանդիսանում է Arca-ի բաժնետեր՝ տնօրինելով նրա բաժնետոմսերի մի մասը։",
    //             shareholder2: "ԱԿԲԱ ԲԱՆԿ, ԱՄԵՐԻԱԲԱՆԿ",
    //             description2: "Բանկը հանդիսանում է Arca-ի բաժնետեր՝ տնօրինելով նրա բաժնետոմսերի մի մասը։",
    //             shareholder3: "ԱՄԻՕ ԲԱՆԿ",
    //             description3: "Բանկը հանդիսանում է Arca-ի բաժնետեր՝ տնօրինելով նրա բաժնետոմսերի մի մասը։",
    //             shareholder4: "ԱյԴի Բանկ",
    //             description4: "Բանկը հանդիսանում է Arca-ի բաժնետեր՝ տնօրինելով նրա բաժնետոմսերի մի մասը։",
    //             shareholder5: "ԱՐԱՐԱՏԲԱՆԿ",
    //             description5: "Բանկը հանդիսանում է Arca-ի բաժնետեր՝ տնօրինելով նրա բաժնետոմսերի մի մասը։",
    //             shareholder6: "Արդշինբանկ",
    //             description6: "Բանկը հանդիսանում է Arca-ի բաժնետեր՝ տնօրինելով նրա բաժնետոմսերի մի մասը։",
    //             shareholder7: "Արմսվիսբանկ",
    //             description7: "Բանկը հանդիսանում է Arca-ի բաժնետեր՝ տնօրինելով նրա բաժնետոմսերի մի մասը։",
    //             shareholder8: "Արցախբանկ",
    //             description8: "Բանկը հանդիսանում է Arca-ի բաժնետեր՝ տնօրինելով նրա բաժնետոմսերի մի մասը։",
    //             shareholder9: "ԲԻԲԼՈՍ Բանկ Արմենիա",
    //             description9: "Բանկը հանդիսանում է Arca-ի բաժնետեր՝ տնօրինելով նրա բաժնետոմսերի մի մասը։",
    //             shareholder10: "Էյչ-Էս-Բի-Սի Բանկ",
    //             description10: "Բանկը հանդիսանում է Arca-ի բաժնետեր՝ տնօրինելով նրա բաժնետոմսերի մի մասը։",
    //             shareholder11: "ԷՎՈԿԱԲԱՆԿ",
    //             description11: "Բանկը հանդիսանում է Arca-ի բաժնետեր՝ տնօրինելով նրա բաժնետոմսերի մի մասը։",
    //             shareholder12: "Ինեկոբանկ",
    //             description12: "Բանկը հանդիսանում է Arca-ի բաժնետեր՝ տնօրինելով նրա բաժնետոմսերի մի մասը։",
    //             shareholder13: "Կոնվերս Բանկ",
    //             description13: "Բանկը հանդիսանում է Arca-ի բաժնետեր՝ տնօրինելով նրա բաժնետոմսերի մի մասը։",
    //             shareholder14: "ՀԱՅԷԿՈՆՈՄԲԱՆԿ",
    //             description14: "Բանկը հանդիսանում է Arca-ի բաժնետեր՝ տնօրինելով նրա բաժնետոմսերի մի մասը։",
    //             shareholder15: "Յունիբանկ",
    //             description15: "Բանկը հանդիսանում է Arca-ի բաժնետեր՝ տնօրինելով նրա բաժնետոմսերի մի մասը։",
    //             shareholder16: "Ֆասթ Բանկ",
    //             description16: "Բանկը հանդիսանում է Arca-ի բաժնետեր՝ տնօրինելով նրա բաժնետոմսերի մի մասը։",
    //             shareholder17: "Ineco Bank",
    //             description17: "Bank is a shareholder of Arca, owning a part of its shares.",
    //             shareholder18: "Ardshininvestbank",
    //             description18: "Bank is a shareholder of Arca, owning a part of its shares.",
    //         },
    //     ],
    // };

    // const teamDescription = {
    //     ENG: {
    //         headerTitle: "Board of directors and executive body",
    //         teamTitle: "Board of directors and executive body",
    //         teamPrev: "Directors",
    //         teamNext: "Executive body",
    //         licenseText1: "License №1",
    //         licenseText2: "«Payment Tools and Payment and Settlement Documentation Processing and Clearing»",
    //         downloadText: "Download document",
    //         openText: "Open organization structure",

    //         name1: "Hovhannes Khachatryan",
    //         title1: "Chairman of Supervisory Board",
    //         description1: "Description",
    //         name2: "Ara Abrahamyan",
    //         title2: "Member of Supervisory Board",
    //         description2: "Description",
    //         name3: "Alistair Brown",
    //         title3: "Member of Supervisory Board",
    //         description3: "Description",
    //         name4: "Ishkhan Mkhitaryan",
    //         title4: "Member of Supervisory Board",
    //         description4: "Description",
    //         name5: "Ruben Melikyan",
    //         title5: "Member of Supervisory Board",
    //         description5: "Description",
    //         name6: "Lilit Grigoryan",
    //         title6: "Member of Supervisory Board",
    //         description6: "Description",
    //         name7: "Vardan Khachatryan",
    //         title7: "Chief Executive Officer",
    //         description7: "Description",
    //         name8: "Ruben Saghoyan",
    //         title8: "Chief Development Officer",
    //         description8: "Description",
    //         name9: "Haykanush Hakobyan",
    //         title9: "Chief Operations Officer",
    //         description9: "Description",
    //         name10: "Aghasi Vardanyan",
    //         title10: "Chief Technology Officer",
    //         description10: "Description",
    //         name11: "Ashot Vardanyan",
    //         title11: "Chief Risk Officer",
    //         description11: "Description",
    //         name12: "Arman Baresghyan",
    //         title12: "Member of Supervisory Board",
    //         description12: "Description",
    //     },
    //     ARM: {
    //         headerTitle: "Տնօրենների խորհուրդ և գործադիր մարմին",
    //         teamTitle: "Տնօրենների խորհուրդ և գործադիր մարմին",
    //         teamPrev: "Ղեկավարություն",
    //         teamNext: "Խորհուրդ",
    //         licenseText1: "Թիվ 1 լիցենզիա",
    //         licenseText2: "Վճարային գործիքների և վճարաշավարկային փաստաթղթերի պրոցեսինգ և քլիրինգ իրականացնելու համար",
    //         downloadText: "Ներբեռնել Փաստաթուղթը",
    //         openText: "Բացել կազմակերպության կառուցվածքը",
    //         name1: "Հովհաննես Խաչատրյան",
    //         title1: "Խորհրդի նախագահ",
    //         description1: "Description",
    //         name2: "Արա Աբրահամյան",
    //         title2: "Խորհրդի անդամ",
    //         description2: "Description",
    //         name3: "Ալիստեր Բրաուն",
    //         title3: "Խորհրդի անդամ",
    //         description3: "Description",
    //         name4: "Իշխան Մխիթարյան",
    //         title4: "Խորհրդի անդամ",
    //         description4: "Description",
    //         name5: "Ռուբեն Մելիքյան",
    //         title5: "Խորհրդի անդամ",
    //         description5: "Description",
    //         name6: "Լիլիթ Գրիգորյան",
    //         title6: "Խորհրդի անդամ",
    //         description6: "Description",
    //         name7: "Վարդան Խաչատրյան",
    //         title7: "Գործադիր տնօրեն",
    //         description7: "Description",
    //         name8: "Ռուբեն Սաղոյան",
    //         title8: "Զարգացման տնօրեն",
    //         description8: "Description",
    //         name9: "Հայկանուշ Հակոբյան",
    //         title9: "Գործառնական տնօրեն",
    //         description9: "Description",
    //         name10: "Աղասի Վարդանյան",
    //         title10: "Տեխնոլոգիական տնօրեն",
    //         description10: "Description",
    //         name11: "Աշոտ Վարդանյան",
    //         title11: "Ռիսկերի կառավարման տնօրեն",
    //         description11: "Description",
    //         name12: "Արման Բարսեղյան",
    //         title12: "Խորհրդի անդամ",
    //         description12: "Description",
    //     },
    //     RUS: {
    //         headerTitle: "Board of directors and executive body",
    //         teamTitle: "Board of directors and executive body",
    //         teamPrev: "Directors",
    //         teamNext: "Executive body",
    //         licenseText1: "License №1",
    //         licenseText2: "«Payment Tools and Payment and Settlement Documentation Processing and Clearing»",
    //         downloadText: "Download document",
    //         openText: "Open organization structure",

    //         name1: "Hovhannes Khachatryan",
    //         title1: "Chairman of Supervisory Board",
    //         description1: "Description",
    //         name2: "Ara Abrahamyan",
    //         title2: "Member of Supervisory Board",
    //         description2: "Description",
    //         name3: "Alistair Brown",
    //         title3: "Member of Supervisory Board",
    //         description3: "Description",
    //         name4: "Ishkhan Mkhitaryan",
    //         title4: "Member of Supervisory Board",
    //         description4: "Description",
    //         name5: "Ruben Melikyan",
    //         title5: "Member of Supervisory Board",
    //         description5: "Description",
    //         name6: "Lilit Grigoryan",
    //         title6: "Member of Supervisory Board",
    //         description6: "Description",
    //         name7: "Vardan Khachatryan",
    //         title7: "Chief Executive Officer",
    //         description7: "Description",
    //         name8: "Ruben Saghoyan",
    //         title8: "Chief Development Officer",
    //         description8: "Description",
    //         name9: "Haykanush Hakobyan",
    //         title9: "Chief Operations Officer",
    //         description9: "Description",
    //         name10: "Aghasi Vardanyan",
    //         title10: "Chief Technology Officer",
    //         description10: "Description",
    //         name11: "Ashot Vardanyan",
    //         title11: "Chief Risk Officer",
    //         description11: "Description",
    //         name12: "Arman Baresghyan",
    //         title12: "Member of Supervisory Board",
    //         description12: "Description",
    //     },
    // };

    // // ABOUT PAGE'S TEXT

    // // SERVICE'S TEXT
    // const servicesPaymentText = {
    //     ENG: [
    //         {
    //             paymentTitle: "Detailed",
    //             paymentBigTitle: "Fast payment solutions for everyone",
    //             paymentText: (
    //                 <p>
    //                     <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    //                         <circle cx="11.5" cy="11.5" r="11.5" fill="black" />
    //                     </svg>
    //                     Arca provides fast, reliable and secure transactions, offering convenient financial solutions for businesses
    //                     <span> and users, supporting Armenia's independence in cashless payments.</span>
    //                 </p>
    //             ),
    //         },
    //     ],

    //     ARM: [
    //         {
    //             paymentTitle: "Մանրամասն",
    //             paymentBigTitle: "Նորարարական լուծումներով վճարային էկոհամակարգ",
    //             paymentText: (
    //                 <p style={dynamicStyles}>
    //                     <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    //                         <circle cx="11.5" cy="11.5" r="11.5" fill="black" />
    //                     </svg>
    //                     Arca-ն առաջարկում է նորարարական, հուսալի և անվտանգ վճարային լուծումներ
    //                     <span style={dynamicStyles}> ֆինանսական կազմակերպությունների, անհատների և ձեռնարկությունների համար:</span>
    //                 </p>
    //             ),
    //         },
    //     ],

    //     RUS: [
    //         {
    //             paymentTitle: "Detailed",
    //             paymentBigTitle: "Fast payment solutions for everyone",
    //             paymentText: (
    //                 <p>
    //                     <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    //                         <circle cx="11.5" cy="11.5" r="11.5" fill="black" />
    //                     </svg>
    //                     Arca provides fast, reliable, and secure transactions, offering convenient financial solutions for businesses
    //                     <span>and users, supporting Armenia's independence in cashless payments.</span>
    //                 </p>
    //             ),
    //         },
    //     ],
    // };

    // const specializedText = {
    //     ENG: [
    //         {
    //             specialTitle: "Explore Our Services",
    //             specialSubTitle: "Arca: Transaction Processing, Secure Integration, Instant Transfers",
    //             title1P1: "Arca",
    //             title1P2: "Pay",
    //             text1: "Send money quickly and securely using just a phone number.",
    //             title2P1: "Arca Pay",
    //             title2P2: "FAST PAYMENTS",
    //             text2: "Instant transfers with no extra steps or hassle",
    //             title3P1: "Arca Pay",
    //             title3P2: "TRANSFERS",
    //             text3: "Instant money transfers, just a few taps away.",
    //             specialText:
    //                 "Arca Pay is a simple, fast, and secure system for instant transfers using just a phone number. Designed for customers of Armenian banks and payment companies, the system supports seamless transfers to both cards and current accounts.",
    //         },

    //         {
    //             title1P1: "PROCESSING",
    //             title1P2: "PAYMENT",
    //             text1: "We provide a complete suite of innovative solutions for secure and efficient transaction processing",
    //             title2P1: "SECURE",
    //             title2P2: "DATA SOLUTIONS ",
    //             text2: "Our system provides reliable and secure payment processing, ensuring accurate settlement of all transactions.",
    //             title3P1: "ADVANCED",
    //             title3P2: "PAYMENT SOLUTIONS",
    //             text3: "We offer the latest solutions for secure, fast and reliable payment processing.",
    //             specialText:
    //                 "We provide a complete suite of innovative solutions for secure and efficient transaction processing, including transaction authorization, processing and settlement.",
    //             category: "TRANSACTION PROCESSING",
    //         },

    //         {
    //             title1P1: "NATIONAL",
    //             title1P2: "SWITCH",
    //             text1: "Fostering interoperability and seamless collaboration across the financial ecosystem.",
    //             title2P1: "FINANCIAL",
    //             title2P2: "INTEGRATION",
    //             text2: "Our solution connects financial institutions outside the Armenian Card system. It ensures unified access and collaboration across the entire financial ecosystem.",
    //             title3P1: "UNBORDERED",
    //             title3P2: "INTEGRATION",
    //             text3: "Our solution connects financial institutions with the Armenian Card system. It creates unified and convenient access for all participants.",
    //             specialText:
    //                 "We enable host-to-host connections for financial organizations outside of Armenian Card system. This solution ensures a unified integration point for all non-member organizations, streamlining access and connectivity within the system. ",
    //             category: "INTEGRATION WITHOUT BORDERS",
    //         },
    //     ],

    //     ARM: [
    //         {
    //             specialTitle: "Բացահայտեք մեր ծառայությունները",
    //             specialSubTitle: "Arca - Գործարքների մշակում, Անվտանգ ինտեգրում, Ակնթարթային փոխանցումներ",
    //             title1P1: "Arca",
    //             title1P2: "Pay",
    //             text1: "Փոխանցեք գումար արագ և անվտանգ, օգտագործելով միայն հեռախոսահամարը:",
    //             title2P1: "Arca Pay",
    //             title2P2: "ՎՃԱՐՈՒՄՆԵՐ",
    //             text2: "փոխանցումներ առանց ավելորդ քայլերի և դժվարությունների",
    //             title3P1: "Arca Pay",
    //             title3P2: "ՓՈԽԱՆՑՈՒՄՆԵՐ",
    //             text3: "Ակնթարթային փոխանցումներ մի քանի հպումով:",
    //             specialText:
    //                 "Arca Pay-ը հայկական բանկերի և վճարահաշվարկային ընկերությունների հաճախորդների միջև հեռախոսահամարով ակնթարթային փոխանցումներ կատարելու պարզ, արագ ու անվտանգ համակարգ է։ Arca Pay համակարգով փոխանցում կարելի է կատարել և ստանալ ինչպես քարտային, այնպես էլ ընթացիկ հաշվին։",
    //         },

    //         {
    //             title1P1: "ԳՈՐԾԱՐՔՆԵՐԻ",
    //             title1P2: "ԼՈՒԾՈՒՄՆԵՐ",
    //             text1: "Առաջարկում ենք գործարքների արագ և անվտանգ մշակման նորարար լուծումների ամբողջական շրջանակ:",
    //             title2P1: "ԱՆՎՏԱՆԳ",
    //             title2P2: "ՄՇԱԿՈՒՄ",
    //             text2: "Մեր համակարգը ապահովում է վճարումների հուսալի և անվտանգ մշակում՝ ապահովելով բոլոր գործարքների ճշգրիտ հաշվարկ",
    //             title3P1: "ԸՆԴԼԱՅՆՎԱԾ",
    //             title3P2: "ԼՈՒԾՈՒՄՆԵՐ",
    //             text3: "Մենք առաջարկում ենք նորարար լուծումներ արագ և հուսալի վճարումների մշակման համար:",
    //             specialText:
    //                 "Առաջարկում ենք գործարքների արագ և անվտանգ մշակման նորարար լուծումների ամբողջական շրջանակ, որը ներառում է գործարքների հավաստագրում, պրոցեսինգ և փոխհաշվարկ։",
    //             category: "ԳՈՐԾԱՐՔՆԵՐԻ ՄՇԱԿՈՒՄ",
    //         },

    //         {
    //             title1P1: "ԱԶԳԱՅԻՆ",
    //             title1P2: "SWITCH",
    //             text1: " Նպաստելով ֆունկցիոնալ համատեղելիությանը և ընդլայնելով համագործակցությունը ֆինանսական էկոհամակարգում:",
    //             title2P1: "ՖԻՆԱՆՍԱԿԱՆ",
    //             title2P2: "ՊԱՐԶԵՑՈՒՄ",
    //             text2: "Մենք կապում ենք Arca համակարգից դուրս ֆինանսական հաստատություններին: Այն ապահովում է միասնական մուտք ամբողջ ֆինանսական էկոհամակարգում:",
    //             title3P1: "ԱՆՍԱՀՄԱՆ",
    //             title3P2: "ԻՆՏԵԳՐՈՒՄ",
    //             text3: "Մեր լուծումը կապում է ֆինանսական հաստատություններին Arca համակարգի հետ։ Այն ստեղծում է միասնական համակարգ բոլոր մասնակիցների համար:",
    //             specialText:
    //                 "Առաջարկում ենք նաև host-to-host կապակցում Արմենիան Քարդ համակարգի անդամ չհանդիսացող ֆինանսական կազմակերպությունների համար, որը մեկ ինտեգրումով հասանելիություն է ապահովում ինչպես համակարգի անդամ բոլոր ֆինանսական կազմակերպություններին, այնպես էլ host-to-host կապակցմամբ միացած մյուս գործընկերներին։",
    //             category: "ԻՆՏԵԳՐՈՒՄ ԱՌԱՆՑ ՍԱՀՄԱՆՆԵՐԻ",
    //         },
    //     ],
    //     RUS: [
    //         {
    //             specialTitle: "Explore Our Services",
    //             specialSubTitle: "Our Specialized Services",
    //             title1: "Arca Pay: The Fast Track of Your Transfers",
    //             text1: "Instant money transfers, just a few taps away.",
    //             title2: "Wide integration",
    //             text2: "Works with different banks, enabling fast and flexible money movement.",
    //             title3: "Real-time updates",
    //             text3: "Provides immediate notifications and updates, ensuring transparency.",
    //             specialText:
    //                 "Arca Pay is a simple, fast, and secure system for instant transfers using just a phone number. Designed for customers of Armenian banks and payment companies, the system supports seamless transfers to both cards and current accounts.",
    //         },
    //     ],
    // };

    // // SERVICE'S TEXT

    // const footerText = {
    //     ENG: [
    //         {
    //             description:
    //                 "Armenian Card CJSC was established in March 2000 by Central Bank of Republic of Armenia and Armenian commercial banks. Our goal is to develop and implement in the banking market of Armenia all benefits that modern payment and development technologies can offer.",
    //         },
    //         {
    //             address: "Addresses",
    //             address1: 'Movses Khorenatsi 15 Str, "Elite Plaza" Business Center, Yerevan 0010, Republic Armenia',
    //             address2: "Garegin Nzhdeh 32/1, Yerevan 0026, Republic Armenia",
    //         },
    //         { contact: "Contact", phoneNumber: "010 59 22 11", mail: "info@arca.am" },
    //         { social: "Social" },
    //         {
    //             test: (
    //                 <>
    //                     © Armenian Card (Arca) All rights reserved. {/*<a href="https://garoon.design/" target="_blank" rel="noopener noreferrer">*/}
    //                     {/*    GSCA*/}
    //                     {/*</a>*/}
    //                 </>
    //             ),
    //         },
    //         { privacy: "Privacy Policy", terms: "Terms & Conditions" },
    //     ],
    //     ARM: [
    //         {
    //             description:
    //                 "«Արմենիան Քարդ» ՓԲԸ-ն հիմնադրվել է ՀՀ ԿԲ-ի և տասը առևտրային բանկերի կողմից 2000թ-ի մարտին՝ ՀՀ բանկային ոլորտում ժամանակակից վճարահաշվարկային համակարգի ներդրման և զարգացման նպատակով:",
    //         },
    //         {
    //             address: "Հասցեներ",
    //             address1: "Մովսես Խորենացի 15, «Էլիտ Պլազա» բիզնես-կենտրոն, Երևան 0010, Հայաստանի Հանրապետություն",
    //             address2: "Գարեգին Նժդեհ 32/1, Երևան 0026, Հայաստանի Հանրապետություն",
    //         },
    //         { contact: "Կոնտակտներ", phoneNumber: "010 59 22 11", mail: "info@arca.am" },
    //         { social: "Սոց կայքեր" },
    //         {
    //             test: (
    //                 <>
    //                     © Arca Բոլոր իրավունքները պաշտպանված են: {/*<a href="https://garoon.design/" target="_blank" rel="noopener noreferrer">*/}
    //                     {/*    GSCA-ի*/}
    //                     {/*</a> կողմից։*/}
    //                 </>
    //             ),
    //         },
    //         { privacy: " Գաղտնիության քաղաքականություն", terms: "Պայմաններ և դրույթներ" },
    //     ],
    //     RUS: [
    //         {
    //             description:
    //                 "Armenian Card CJSC was established in March 2000 by Central Bank of Republic of Armenia and Armenian commercial banks. Our goal is to develop and implement in the banking market of Armenia all benefits that modern payment and development technologies can offer.",
    //         },
    //         {
    //             address: "Addresses",
    //             address1: 'Movses Khorenatsi 15 Str, "Elite Plaza" Business Center, Yerevan 0010, Republic Armenia',
    //             address2: "Garegin Nzhdeh 32/1, Yerevan 0026, Republic Armenia",
    //         },
    //         { contact: "Contact", phoneNumber: "010 59 22 11", mail: "info@arca.am" },
    //         { social: "Social" },
    //         {
    //             test: (
    //                 <>
    //                     © 2024 Armenian Card (ArCa) is made by{" "}
    //                     <a href="https://garoon.design/" target="_blank" rel="noopener noreferrer">
    //                         GSCA
    //                     </a>
    //                 </>
    //             ),
    //         },
    //         { privacy: "Privacy Policy", terms: "Terms & Conditions" },
    //     ],
    // };

    // const linkCardsContent = {
    //     ENG: {
    //         services: [
    //             { name: "Service" },
    //             { title: "Learn more about Arca Pay, transaction processing, fraud monitoring, and detection." },
    //             { desc: "Read about Arca’s Service." },
    //         ],
    //         career: [{ name: "Career" }, { title: "Join our dynamic team." }, { desc: "You will find details about open vacancies." }],
    //         news: [{ name: "News" }, { title: "Stay updated." }, { desc: "Read first about Arca’s announcements." }],
    //         aboutus: [{ name: "About Us" }, { title: "Learn more about our company." }, { desc: "Read about Arca - Armenian Card." }],
    //     },
    //     ARM: {
    //         services: [
    //             { name: "Ծառայություն" },
    //             { title: "Իմացեք ավելին Arca Pay-ի, գործարքների մշակման և պրոցեսինգային կենտրոն մասին:" },
    //             { desc: "Կարդացեք Arca-ի ծառայության մասին:" },
    //         ],
    //         career: [{ name: "Կարիերա" }, { title: "Միացե՛ք մեր թիմին" }, { desc: "Դուք կգտնեք մանրամասներ թափուր աշխատատեղերի մասին։" }],
    //         news: [
    //             { name: "Նորություններ" },
    //             { title: "Հետևե՛ք վերջին նորություններին" },
    //             { desc: "Կարդացե՛ք առաջինը Arca-ի հայտարարությունների մասին:" },
    //         ],
    //         aboutus: [{ name: "Մեր մասին" }, { title: "Իմացեք ավելին մեր ընկերության մասին" }, { desc: "Կարդացեք Arca - Արմենիան քարդի մասին։" }],
    //     },
    //     RUS: {
    //         services: [
    //             { name: "Service" },
    //             { title: "Learn more about Arca Pay, transaction processing, fraud monitoring, and detection." },
    //             { desc: "Read about Arca’s Service." },
    //         ],
    //         career: [{ name: "Career" }, { title: "Join our dynamic team." }, { desc: "You will find details about open vacancies." }],
    //         news: [{ name: "News" }, { title: "Stay updated." }, { desc: "Read first about Arca’s announcements." }],
    //         aboutus: [{ name: "About Us" }, { title: "Learn more about our company." }, { desc: "Read about Arca - Armenian Card." }],
    //     },
    // };

    // const missionVissionNumbers = {
    //     ENG: {
    //         aboutus: "Was founded",
    //         aboutusNumber: "2000",

    //         mission: "Transactions",
    //         missionNumber: "4T+",

    //         vission: "Years working for you",
    //         vissionNumber: "20+",

    //         values: "Businesses trust Arca",
    //         valuesNumber: "100K",
    //     },
    //     ARM: {
    //         aboutus: "Հիմնադրվել է",
    //         aboutusNumber: "2000",

    //         mission: "Գործարքներ",
    //         missionNumber: "4T+",

    //         vission: "Տարի շուկայում",
    //         vissionNumber: "20+",

    //         values: "Գործարարները վստահում են մեզ",
    //         valuesNumber: "100Հազ․",
    //     },
    //     RUS: {
    //         aboutus: "Was founded",
    //         aboutusNumber: "2000",

    //         mission: "Transactions",
    //         missionNumber: "4T+",

    //         vission: "Years working for you",
    //         vissionNumber: "20+",

    //         values: "Businesses trust Arca",
    //         valuesNumber: "100K",
    //     },
    // };

    // const contactsText = {
    //     ENG: {
    //         title: "Contacts",
    //         question: "Contact Us",
    //         office1: "Operating Address",
    //         address1: `Movses Khorenatsi 15 Str, "Elite Plaza" Business Center, Yerevan 0010, Republic Armenia`,
    //         office2: "Legal Address",
    //         address2: `Garegin Nzhdeh 32/1, Yerevan 0026, Republic Armenia`,
    //         phone: "Phone Number",
    //         number: "+374 10 592211",
    //         mailText: "Email",
    //         mail: "info@arca.am",
    //         workingHours: "Mon - Fri 09:00-18:00",
    //         workingText: "Working hours",
    //     },
    //     ARM: {
    //         title: "Կապ մեզ հետ",
    //         question: "Կապ",
    //         office1: "Գործունեության հասցե",
    //         address1: `Մովսես Խորենացի 15, «Էլիտ Պլազա» բիզնես-կենտրոն, Երևան 0010, Հայաստանի Հանրապետություն`,
    //         office2: "Իրավաբանական հասցե",
    //         address2: `Գարեգին Նժդեհ 32/1,  Երևան 0026, Հայաստանի Հանրապետություն`,
    //         phone: "Հեռ․`",
    //         number: "+374 10 592211",
    //         mailText: "Էլ.փոստ՝",
    //         mail: "info@arca.am",
    //         workingHours: "Երկ-Ուրբ 09։00-18։00",
    //         workingText: "Աշխատանքային ժամեր՝",
    //     },
    //     RUS: {
    //         title: "Contacts",
    //         question: "Contact Us",
    //         office1: "Operating Address",
    //         address1: `Movses Khorenatsi 15 Str, "Elite Plaza" Business Center, Yerevan 0010, Republic Armenia`,
    //         office2: "Legal Address",
    //         address2: `Garegin Nzhdeh 32/1, Yerevan 0026, Republic Armenia`,
    //         phone: "Phone Number",
    //         number: "+374 10 592211",
    //         mailText: "Email",
    //         mail: "info@arca.am",
    //         workingHours: "Mon - Fri 09:00-18:00",
    //         workingText: "Working hours",
    //     },
    // };

    // const reportsText = {
    //     ENG: {
    //         header: "All reports",
    //         title: "Reports",
    //         download: "Download documents in PDF format",
    //     },
    //     ARM: {
    //         header: "Բոլոր հաշվետվությունները",
    //         title: "Հաշվետվություններ",
    //         download: "Ներբեռնել փաստաթուղթը",
    //     },
    //     RUS: {
    //         header: "All reports",
    //         title: "Reports",
    //         download: "Download documents in PDF format",
    //     },
    // };

    // const financialReports = {
    //     ARM: [
    //         {
    //             date: "2023",
    //             title: "Ֆինանսական հաշվետվություն",
    //             pdf: require("./documents/reportsDocs/am/Ֆինանսական հաշվետվություն_2023.pdf"),
    //             image: reportsImage,
    //         },
    //         {
    //             date: "2022",
    //             title: "Ֆինանսական հաշվետվություն",
    //             pdf: require("./documents/reportsDocs/am/Ֆինանսական հաշվետվություն_2022.pdf"),
    //             image: reportsImage,
    //         },
    //         {
    //             date: "2021",
    //             title: "Ֆինանսական հաշվետվություն",
    //             pdf: require("./documents/reportsDocs/am/Ֆինանսական հաշվետվություն_2021.pdf"),
    //             image: reportsImage,
    //         },
    //         {
    //             date: "2020",
    //             title: "Ֆինանսական հաշվետվություն",
    //             pdf: require("./documents/reportsDocs/am/Ֆինանսական հաշվետվություն_2020.pdf"),
    //             image: reportsImage,
    //         },
    //         {
    //             date: "2019",
    //             title: "Ֆինանսական հաշվետվություն",
    //             pdf: require("./documents/reportsDocs/am/Ֆինանսական հաշվետվություն_2019.pdf"),
    //             image: reportsImage,
    //         },
    //         {
    //             date: "2018",
    //             title: "Ֆինանսական հաշվետվություն",
    //             pdf: require("./documents/reportsDocs/am/Ֆինանսական հաշվետվություն_2018.pdf"),
    //             image: reportsImage,
    //         },
    //     ],
    //     ENG: [
    //         {
    //             date: "2023",
    //             title: "Financial statement",
    //             pdf: require("./documents/reportsDocs/en/Financial statement_2023.pdf"),
    //             image: reportsImage,
    //         },
    //         {
    //             date: "2022",
    //             title: "Financial statement",
    //             pdf: require("./documents/reportsDocs/en/Financial statement_2022.pdf"),
    //             image: reportsImage,
    //         },
    //         {
    //             date: "2021",
    //             title: "Financial statement",
    //             pdf: require("./documents/reportsDocs/en/Financial statement_2021.pdf"),
    //             image: reportsImage,
    //         },
    //         {
    //             date: "2020",
    //             title: "Financial statement",
    //             pdf: require("./documents/reportsDocs/en/Financial statement_2020.pdf"),
    //             image: reportsImage,
    //         },
    //         {
    //             date: "2019",
    //             title: "Financial statement",
    //             pdf: require("./documents/reportsDocs/en/Financial statement_2019.pdf"),
    //             image: reportsImage,
    //         },
    //         {
    //             date: "2018",
    //             title: "Financial statement",
    //             pdf: require("./documents/reportsDocs/en/Financial statement_2018.pdf"),
    //             image: reportsImage,
    //         },
    //     ],
    // };

    // const legalText = {
    //     ENG: {
    //         header: "Regulatory Documents and Legal Acts",
    //         title: "Documents",
    //         description: `This page features the key documents governing Arca's activities, including the Constitution, the Code of Business Conduct and Ethics, Operational Guidelines, Amendments and Additions, as well as a list of Laws and Regulations. All materials are available for download.`,
    //         download: "Download document",
    //     },
    //     ARM: {
    //         header: "Կարգավորող փաստաթղթեր և նորմատիվ իրավական ակտեր",
    //         title: "Փաստաթղթեր",
    //         description: `Այս էջում ներկայացված են Arca-ի գործունեությունը կարգավորող հիմնական փաստաթղթերը, ներառյալ Սահմանադրությունը, գործարար էթիկայի կանոնագիրը, օպերացիոն ուղեցույցները, փոփոխություններն ու լրացումները, ինչպես նաև օրենքների և կարգավորումների ցանկը։ Բոլոր նյութերը հասանելի են ներբեռնման համար։`,
    //         download: "Ներբեռնել փաստաթուղթը",
    //     },
    //     RUS: {
    //         header: "Regulatory Documents and Legal Acts",
    //         title: "Documents",
    //         description: `This page features the key documents governing Arca's activities, including the Constitution, the Code of Business Conduct and Ethics, Operational Guidelines, Amendments and Additions, as well as a list of Laws and Regulations. All materials are available for download.`,
    //         download: "Download document",
    //     },
    // };

    // const legalDocs = {
    //     ENG: [
    //         {
    //             name: "License 1",
    //             openLink: "#",
    //             downloadLink: require("./documents/License 1.pdf"),
    //             downloadName: "License 1",
    //         },
    //         {
    //             name: "Charter",
    //             openLink: "#",
    //             downloadLink: require("./documents/ArCa Charter - Eng.pdf"),
    //             downloadName: "Charter",
    //         },
    //     ],
    //     ARM: [
    //         {
    //             name: "Լիցենզիա 1",
    //             openLink: "#",
    //             downloadLink: require("./documents/Լիցենզիա 1.pdf"),
    //             downloadName: "Լիցենզիա 1",
    //         },
    //         {
    //             name: "Կանոնադրություն",
    //             openLink: "#",
    //             downloadLink: require("./documents/ArCa Charter.pdf"),
    //             downloadName: "Կանոնադրություն",
    //         },
    //     ],
    //     RUS: [
    //         {
    //             name: "License 1",
    //             openLink: "#",
    //             downloadLink: require("./documents/License 1.pdf"),
    //             downloadName: "License 1",
    //         },
    //         {
    //             name: "Charter",
    //             openLink: "#",
    //             downloadLink: require("./documents/ArCa Charter - Eng.pdf"),
    //             downloadName: "Charter",
    //         },
    //     ],
    // };

    // const briefCareersText = {
    //     ENG: [
    //         {
    //             overviewTitle: "Benefits",
    //             overviewText: "Bonuses offered to employees",
    //             card1Title: "Benefits package ",
    //             card1Number: "10%",
    //             card1Text:
    //                 "We offer a competitive benefits package, including flexible schedules, health insurance, paid time off, and professional development opportunities.",
    //             card2Title: "Wellbeing program",
    //             card2Number: "",
    //             card2Text:
    //                 "Our Wellbeing Program prioritizes employee health and happiness through comprehensive health insurance, fitness benefits, and mental health support. ",
    //             card3Title: "Health insurance ",
    //             card3Number: "",
    //             card3Text:
    //                 "Our Health Insurance program provides comprehensive medical, dental, and vision coverage to support employees and their families. ",
    //             card4Title: "Holiday days",
    //             card4Number: "22",
    //             card4Text: "Our holiday policy includes 22 paid vacation days annually, ensuring time for rest and rejuvenation. ",
    //         },
    //     ],
    //     ARM: [
    //         {
    //             overviewTitle: "Համառոտ նկարագրություն",
    //             overviewText: "Arca-ն հաջողության մասին խոսուն թվերով",
    //             card1Title: "Քարտապաններ",
    //             card1Number: "3 մլն․",
    //             card1Text: "Ապահովելով ավելի քան 3 միլիոն քարտապանների անվտանգ և հուսալի վճարային ծառայություններով",
    //             card2Title: "100 հազար տնտեսվարող",
    //             card2Text: "Վստահված ավելի քան 100,000 բիզնեսների կողմից՝ անխափան ֆինանսական գործողությունների համար",
    //             card3Title: "2.2 միլիարդ մշակված հարցում",
    //             card3Text: "Մշակելով 2.2 միլիարդ վճարային հարցումներ՝ ճշգրտությամբ և արդյունավետությամբ",
    //             card4Title: "Գործարքներ",
    //             card4Number: "4 տրլն.",
    //             card4Text: "Նպաստելով աննախադեպ մասշտաբների գործարքների՝ գերազանցելով տարեկան 4 տրիլիոն ՀՀ դրամը",
    //         },
    //     ],
    //     RUS: [
    //         {
    //             overviewTitle: "Brief overview",
    //             overviewText: "Arca: Key facts about the success",
    //             card1Title: "Clients",
    //             card1Number: "1 mln",
    //             card1Text:
    //                 "Arca has built a strong reputation and earned the trust of over 1 mln clients, ranging from small businesses to large enterprises.",
    //             card2Title: "Empowering Innovation",
    //             card2Number: "",
    //             card2Text: "Arca's team has grown to over employees in Armenia. We continue to push the boundaries in every project we undertake.",
    //             card3Title: "Top-tier security standards",
    //             card3Number: "",
    //             card3Text:
    //                 "Arca prioritizes security at every level. We are certified with the highest security standards, PCI DSS and other regulations.",
    //             card4Title: "Payments processed",
    //             card4Number: "500 mln",
    //             card4Text: "Arca has become a trusted choice for seamless transactions, processing over 500 million payments every year.",
    //         },
    //     ],
    // };

    // //new

    // const vacanciesText = {
    //     ENG: {
    //         header: "Current Openings",
    //         subheader: "Arca's Vacancies",
    //     },
    //     ARM: {
    //         header: "Ընթացիկ թափուր աշխատատեղեր",
    //         subheader: "Arca-ի աշխատատեղերը",
    //     },
    //     RUS: {
    //         header: "Текущие вакансии",
    //         subheader: "Вакансии Arca",
    //     },
    // };

    // const newsText = {
    //     ENG: {
    //         header: "All News",
    //         subheader: "News",
    //         more: "More",
    //         less: "Less",
    //     },
    //     ARM: {
    //         header: "Բոլոր նորությունները",
    //         subheader: "Նորություններ",
    //         more: "Ավելին",
    //         less: "Քիչ",
    //     },
    //     RUS: {
    //         header: "Все новости",
    //         subheader: "Новости",
    //         more: "Больше",
    //         less: "Меньше",
    //     },
    // };

    // const tendersText = {
    //     ENG: {
    //         header: "Tender Information",
    //         subheader: "Arca's Tenders",
    //         description: "Description",
    //         open: "Open tender",
    //         close: "Close tender",
    //     },
    //     ARM: {
    //         header: "Մրցույթի տեղեկատվություն",
    //         subheader: "Arca-ի մրցույթները",
    //         description: "Նկարագրություն",
    //         open: "Բաց մրցույթներ",
    //         close: "Ավարտված մրցույթներ",
    //     },
    //     RUS: {
    //         header: "Информация о тендерах",
    //         subheader: "Тендеры Arca",
    //         description: "Описание",
    //         open: "Открытый тендер",
    //         close: "Закрытый тендер",
    //     },
    // };

    // const newsDetailsText = {
    //     ENG: {
    //         header: "Latest News",
    //         subheader: "More News",
    //         goBack: "Go Back",
    //         readMore: "Read More",
    //     },
    //     ARM: {
    //         header: "Վերջին նորությունները",
    //         subheader: "Ավելին նորություններից",
    //         goBack: "Վերադառնալ",
    //         readMore: "Կարդալ ավելին",
    //     },
    //     RUS: {
    //         header: "Последние новости",
    //         subheader: "Больше новостей",
    //         goBack: "Назад",
    //         readMore: "Читать далее",
    //     },
    // };

    // const vacancyCardText = {
    //     ENG: {
    //         description: "Description",
    //         link: "Interested candidates can send their resumes by email:",
    //     },
    //     ARM: {
    //         description: "Նկարագրություն",
    //         link: "Հետաքրքրված թեկնածուները կարող են ուղարկել իրենց ինքնակենսագրականները էլ. փոստով:",
    //     },
    //     RUS: {
    //         description: "Описание",
    //         link: "Заинтересованные кандидаты могут отправить свое резюме по электронной почте:",
    //     },
    // };

    // const homeNewsText = {
    //     ENG: {
    //         header: "News of Arca",
    //         subheader: "News",
    //     },
    //     ARM: {
    //         header: "Arca-յի նորությունները",
    //         subheader: "Նորություններ",
    //     },
    //     RUS: {
    //         header: "Новости Arca",
    //         subheader: "Новости",
    //     },
    // };

    // const structureText = {
    //     ENG: {
    //         header: "Arca's structure",
    //         subheader: "Company structure",
    //         download: "Download file",
    //     },
    //     ARM: {
    //         header: "Arca-յի կառուցվածքը",
    //         subheader: "Ընկերության կառուցվածքը",
    //         download: "Ներբեռնել ֆայլը",
    //     },
    //     RUS: {
    //         header: "Структура Arca",
    //         subheader: "Структура компании",
    //         download: "Скачать файл",
    //     },
    // };

    // Function to set a specific language
    // const changeLanguage = (lang) => {
    //     if (textContent[lang]) {
    //         setLanguage(lang);
    //     } else {
    //         console.warn(`Language ${lang} is not supported`);
    //     }
    // };

    // Function to set a specific language
    const changeLanguage = (lang) => {
        if (textContent[lang]) {
            setLanguage(lang);
            localStorage.setItem("selectedLanguage", lang);
        } else {
            console.warn(`Language ${lang} is not supported`);
        }
    };

    console.log(data)
    return (
        <LanguageContext.Provider
            value={{
                language,
                setLanguage: changeLanguage,
                textContent: textContent,
                numberText : numberText,
                numberHeader : numberHeader,
                footerText : footerText,
                paymentText : paymentText,
                comprehensiveText : comprehensiveText,
                heroText : heroText,
                overviewText : overviewText,
                purposeText : purposeText,
                shareholderText : shareholderText,
                teamDescription : teamDescription,
                servicesPaymentText : servicesPaymentText,
                specializedText: specializedText,
                timelineText: timelineText,
                linkCardsContent: linkCardsContent,
                missionVissionNumbers: missionVissionNumbers,
                contactsText: contactsText,
                reportsText: reportsText,
                financialReports: financialReports,
                legalDocs: legalDocs,
                legalText: legalText,
                briefCareersText: briefCareersText,
                vacanciesText: vacanciesText,
                newsText: newsText,
                tendersText: tendersText,
                newsDetailsText: newsDetailsText,
                vacancyCardText: vacancyCardText,
                homeNewsText: homeNewsText,
                structureText: structureText,
                tipsData: tipsData,
                faqData: faqData,
                images: images
            }}
        >
            {children}
        </LanguageContext.Provider>
    );
};
