import React from "react";
import "./VacancyCard.css";
import { useLanguage } from "../../LanguageContext";

function VacancyCard({ id, title, description, experience, type, location, onClick }) {
    const { language, vacancyCardText } = useLanguage();
    const dynamicStyles = {
        fontFamily: language === "ARM" && "Mardoto",
        fontWeight: language === "ARM" && 500,
    };

    return (
        <div className="vacancy-card" onClick={onClick} data-id={id}>
            <h3 style={dynamicStyles}>{title}</h3>
            <div className="vacancy-card__description">
                <span style={dynamicStyles}>{vacancyCardText[language].description}</span>
                <p style={dynamicStyles}>{description}</p>
            </div>
            <div className="vacancy-card_footer">
                <div className="vacancy-card__details">
                    {experience && <span style={dynamicStyles}>{experience}</span>}
                    {type && <span style={dynamicStyles}>{type}</span>}
                    {location && <span style={dynamicStyles}>{location}</span>}
                </div>

                <button className="vacancy-card__apply">
                    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="21" cy="21" r="21" fill="white" />
                        <rect x="20.5" y="13" width="1" height="16" fill="black" />
                        <rect x="29" y="20.5" width="1" height="16" transform="rotate(90 29 20.5)" fill="black" />
                    </svg>
                </button>
            </div>
        </div>
    );
}

export default VacancyCard;
