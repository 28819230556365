import React, { useEffect, useState } from "react";
import "./Team.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { axiosInstance } from "../../helper/axiosInstance";
import { useLanguage } from "../../LanguageContext";
import { useAppConfig } from "../../hooks/useAppConfig";

function Team() {
    const { language, teamDescription } = useLanguage();
    const isArmenian = language === "ARM";

    const [teamData, setTeamData] = useState([]);

    const config = useAppConfig();

    useEffect(() => {
        const lang = language === "ARM" ? "hy" : language === "ENG" ? "en" : "ru";

        axiosInstance.get("/team/lang?lang=" + lang).then((response) => setTeamData(response.data));
    }, [language]);

    const [expandedCard, setExpandedCard] = useState(null);

    const handleCardIconClick = (index) => {
        setExpandedCard(expandedCard === index ? null : index);
    };

    const dynamicStyles = {
        fontFamily: language === "ARM" && "Mardoto",
        fontWeight: language === "ARM" && 500,
    };

    return (
        <div className={`team ${isArmenian && "arm-language"}`}>
            <div className="team-header">
                <span style={dynamicStyles}>{teamDescription[language].headerTitle}</span>
                <div className="team-header_icons">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M0.0164407 3.82196L0 12L3.99046 12L3.99046 3.99715C4.00629 3.99715 4.9794 4.00325 4.9794 4.0002L12 4.0002L12 -5.24537e-07L3.83761 -1.67747e-07C1.73064 -7.56487e-08 0.0219223 1.70984 0.0176598 3.82196"
                            fill="#243BE2"
                        />
                    </svg>
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M8.17804 11.9836L0 12L0 8.00954L8.00285 8.00954C8.00285 7.99371 7.99675 7.0206 7.9998 7.0206L7.9998 0H12L12 8.16239C12 10.2694 10.2902 11.9781 8.17804 11.9823"
                            fill="#ADF233"
                        />
                    </svg>
                </div>
            </div>
            <div className="team-title">
                <h1 style={dynamicStyles}>{teamDescription[language].teamTitle}</h1>
            </div>

            {/* team Navigation Buttons */}
            <div className="team-navigation">
                <button className="team-prev" style={dynamicStyles}>
                    {teamDescription[language].teamNext}
                </button>
                <button className="team-next" style={dynamicStyles}>
                    {teamDescription[language].teamPrev}
                </button>
            </div>

            <Swiper
                navigation={{
                    prevEl: ".team-prev",
                    nextEl: ".team-next",
                }}
                modules={[Navigation]}
                className="teamSwiper"
            >
                <SwiperSlide>
                    <div id="team-pagination_container"></div>
                    {/* Custom Navigation Buttons */}
                    {/* <div className="team-first-navigation">
                        <div className="team-first-control">
                            <button className="team-first-prev">
                                <div className="svg-wrapper">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9 17L1 9M1 9L9 1M1 9L17 9" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                                <div className="svg-wrapper">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9 17L1 9M1 9L9 1M1 9L17 9" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                            </button>
                            <button className="team-first-next">
                                <div className="svg-wrapper">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9 17L17 9M17 9L9 1M17 9L1 9" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                                <div className="svg-wrapper">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9 17L17 9M17 9L9 1M17 9L1 9" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                            </button>
                        </div>
                    </div> */}
                    <div className="team-slider">
                        <Swiper
                            breakpoints={{
                                0: {
                                    slidesPerView: 1.06,
                                    slidesPerGroup: 1,
                                    spaceBetween: 20,
                                },
                                576: {
                                    slidesPerView: 4.2,
                                    slidesPerGroup: 4.2,
                                    spaceBetween: 10,
                                },
                                1000: {
                                    slidesPerView: 4.2,
                                    slidesPerGroup: 4.2,
                                    spaceBetween: 15,
                                },
                                1500: {
                                    slidesPerView: 4.2,
                                    slidesPerGroup: 4.2,
                                    spaceBetween: 25,
                                },
                            }}
                            spaceBetween={25}
                            slidesPerView={4.2}
                            slidesPerGroup={4.2}
                            pagination={{
                                clickable: true,
                                el: "#team-pagination_container",
                            }}
                            navigation={{
                                prevEl: ".team-first-prev",
                                nextEl: ".team-first-next",
                            }}
                            modules={[Pagination, Navigation]}
                            className="sliderTeam"
                        >
                            {teamData
                                .filter((member) => member.type == 1)
                                .map((member, index) => (
                                    <SwiperSlide key={index}>
                                        <div className="team-card">
                                            <img src={config.data.API_STATIC_URL + member.image} alt={member.name} />
                                            <div className="team-card_content">
                                                <span style={dynamicStyles}>{member.name}</span>
                                                <p style={dynamicStyles} className="team-card_role">
                                                    {member.position}
                                                </p>
                                                {/* <div className="team-card_description">
                                                <p style={dynamicStyles} className={expandedCard === index ? " team-description-expanded" : "team-description"}>
                                                    {member.description}
                                                </p>
                                                <span
                                                    className={expandedCard === index ? "team-rotate-icon" : ""}
                                                    onClick={() => handleCardIconClick(index)}
                                                >
                                                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <rect x="19" y="12" width="1" height="13" transform="rotate(90 19 12)" fill="#000" />
                                                        <rect className="rotatable-rect" x="12" y="6" width="1" height="13" fill="#000" />
                                                        <circle cx="12.5" cy="12.5" r="12" stroke="#595959" />
                                                    </svg>
                                                </span>
                                            </div> */}
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))}
                        </Swiper>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div id="team-pagination_container_second"></div>
                    {/* Custom Navigation Buttons */}
                    {/* <div className="team-second-navigation">
                        <div className="team-second-control">
                            <button className="team-second-prev">
                                <div className="svg-wrapper">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9 17L1 9M1 9L9 1M1 9L17 9" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                                <div className="svg-wrapper">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9 17L1 9M1 9L9 1M1 9L17 9" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                            </button>
                            <button className="team-second-next">
                                <div className="svg-wrapper">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9 17L17 9M17 9L9 1M17 9L1 9" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                                <div className="svg-wrapper">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9 17L17 9M17 9L9 1M17 9L1 9" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                            </button>
                        </div>
                    </div> */}
                    <div className="team-slider">
                        <Swiper
                            breakpoints={{
                                0: {
                                    slidesPerView: 1.06,
                                    slidesPerGroup: 1,
                                    spaceBetween: 20,
                                },
                                576: {
                                    slidesPerView: 4.2,
                                    slidesPerGroup: 4.2,
                                    spaceBetween: 10,
                                },
                                1000: {
                                    slidesPerView: 4.2,
                                    slidesPerGroup: 4.2,
                                    spaceBetween: 15,
                                },
                                1500: {
                                    slidesPerView: 4.2,
                                    slidesPerGroup: 4.2,
                                    spaceBetween: 25,
                                },
                            }}
                            spaceBetween={25}
                            slidesPerView={4.2}
                            slidesPerGroup={4.2}
                            pagination={{
                                clickable: true,
                                el: "#team-pagination_container_second",
                            }}
                            navigation={{
                                prevEl: ".team-second-prev",
                                nextEl: ".team-second-next",
                            }}
                            modules={[Pagination, Navigation]}
                            className="sliderTeam"
                        >
                            {teamData
                                .filter((member) => member.type == 2)
                                .map((member, index) => (
                                    <SwiperSlide key={index}>
                                        <div className="team-card">
                                            <img src={config.data.API_STATIC_URL + member.image} alt={member.name} />
                                            <div className="team-card_content">
                                                <span style={dynamicStyles}>{member.name}</span>
                                                <p style={dynamicStyles} className="team-card_role">
                                                    {member.position}
                                                </p>
                                                {/* <div className="team-card_description">
                                                <p className={expandedCard === index ? " team-description-expanded" : "team-description"}>
                                                    {member.description}
                                                </p>
                                                <span
                                                    className={expandedCard === index ? "team-rotate-icon" : ""}
                                                    onClick={() => handleCardIconClick(index)}
                                                >
                                                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <rect x="19" y="12" width="1" height="13" transform="rotate(90 19 12)" fill="#000" />
                                                        <rect className="rotatable-rect" x="12" y="6" width="1" height="13" fill="#000" />
                                                        <circle cx="12.5" cy="12.5" r="12" stroke="#595959" />
                                                    </svg>
                                                </span>
                                            </div> */}
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))}
                        </Swiper>
                    </div>
                </SwiperSlide>
            </Swiper>

            <div className="team_license-wrapper">
                <div className="team_license">
                    {/* <p style={dynamicStyles}>
                        {teamDescription[language].licenseText1}
                        <br/>
                        {teamDescription[language].licenseText2}
                    </p> */}
                    <div className="team_license-links">
                        {/* <a style={dynamicStyles}>
                            {teamDescription[language].downloadText}{" "}
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_1072_32760)">
                                    <path d="M5 9L9 13M9 13L13 9M9 13L9 5" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                                </g>
                                <rect x="17.5" y="0.5" width="17" height="17" rx="8.5" transform="rotate(90 17.5 0.5)" stroke="black" />
                                <defs>
                                    <clipPath id="clip0_1072_32760">
                                        <rect x="18" width="18" height="18" rx="9" transform="rotate(90 18 0)" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </a> */}
                        <a style={dynamicStyles} href="/structure">
                            {teamDescription[language].openText}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Team;
